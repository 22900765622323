import { defineStore } from "pinia"

type State = {
  isOpen: boolean
  openFrom: string
}

export const useWorkTimeSelectModal = defineStore("workTimeSelectModal", {
  state: (): State => {
    return {
      isOpen: false,
      openFrom: "",
    }
  },
  actions: {
    open(from: string) {
      this.isOpen = true
      this.openFrom = from
    },
    close() {
      this.isOpen = false
      this.openFrom = ""
    },
  },
})
