import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "sideMenu" }
const _hoisted_2 = { id: "sortWrap" }
const _hoisted_3 = { class: "first" }
const _hoisted_4 = { class: "second" }
const _hoisted_5 = { class: "third" }
const _hoisted_6 = { id: "tagWrap" }
const _hoisted_7 = { class: "edit" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "subHead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleItem = _resolveComponent("SingleItem")!
  const _component_TagEdit = _resolveComponent("TagEdit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('all'),
          injectionClass: "all",
          occupancyRate: _ctx.calcOccupancyRate('AFTER_TOADY', 'd'),
          isSelected: _ctx.isSelected(0),
          minutes: _ctx.calcTotalRemainingWorkingTime('AFTER_TOADY'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickRange(0, 'AFTER_TOADY')))
        }, null, 8, ["label", "occupancyRate", "isSelected", "minutes"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('completed'),
          injectionClass: "completed",
          occupancyRate: _ctx.calcOccupancyRate('COMPLETED', 'd'),
          isSelected: _ctx.isSelected(8),
          minutes: _ctx.calcTotalRemainingWorkingTime('COMPLETED'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickRange(8, 'COMPLETED')))
        }, null, 8, ["label", "occupancyRate", "isSelected", "minutes"])
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('today'),
          injectionClass: "today",
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('TODAY'),
          displayUnit: 'h',
          occupancyRate: _ctx.calcOccupancyRate('TODAY', 'h'),
          isSelected: _ctx.isSelected(1),
          count: _ctx.countUntreatedTasks('TODAY'),
          minutes: _ctx.calcTotalRemainingWorkingTime('TODAY'),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickRange(1, 'TODAY'))),
          onDrop: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDrop($event, 'toToday'))),
          onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "remainingAllotedTime", "occupancyRate", "isSelected", "count", "minutes"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('tomorrow'),
          injectionClass: "tomorrow",
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('TOMORROW'),
          displayUnit: 'h',
          occupancyRate: _ctx.calcOccupancyRate('TOMORROW', 'h'),
          isSelected: _ctx.isSelected(2),
          count: _ctx.countUntreatedTasks('TOMORROW'),
          minutes: _ctx.calcTotalRemainingWorkingTime('TOMORROW'),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onClickRange(2, 'TOMORROW'))),
          onDrop: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onDrop($event, 'toTommorow'))),
          onDragover: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "remainingAllotedTime", "occupancyRate", "isSelected", "count", "minutes"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('this_week'),
          injectionClass: "thisWeek",
          isSelected: _ctx.isSelected(3),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('THIS_WEEK'),
          displayUnit: 'd',
          occupancyRate: _ctx.calcOccupancyRate('THIS_WEEK', 'd'),
          count: _ctx.countUntreatedTasks('THIS_WEEK'),
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onClickRange(3, 'THIS_WEEK'))),
          onDrop: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onDrop($event, 'toThisWeek'))),
          onDragover: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "isSelected", "remainingAllotedTime", "occupancyRate", "count"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('next_week'),
          injectionClass: "nextWeek",
          isSelected: _ctx.isSelected(4),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('NEXT_WEEK'),
          displayUnit: 'd',
          occupancyRate: _ctx.calcOccupancyRate('NEXT_WEEK', 'd'),
          count: _ctx.countUntreatedTasks('NEXT_WEEK'),
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onClickRange(4, 'NEXT_WEEK'))),
          onDrop: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onDrop($event, 'toNextWeek'))),
          onDragover: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "isSelected", "remainingAllotedTime", "occupancyRate", "count"])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('this_month'),
          injectionClass: "thisMonth",
          isSelected: _ctx.isSelected(5),
          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.onClickRange(5, 'THIS_MONTH'))),
          onDrop: _cache[19] || (_cache[19] = ($event: any) => (_ctx.onDrop($event, 'toThisMonth'))),
          onDragover: _cache[20] || (_cache[20] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "isSelected"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('next_month'),
          injectionClass: "nextMonth",
          isSelected: _ctx.isSelected(6),
          onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.onClickRange(6, 'NEXT_MONTH'))),
          onDrop: _cache[23] || (_cache[23] = ($event: any) => (_ctx.onDrop($event, 'toNextMonth'))),
          onDragover: _cache[24] || (_cache[24] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[25] || (_cache[25] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "isSelected"]),
        _createVNode(_component_SingleItem, {
          label: _ctx.localizationText('someday'),
          injectionClass: "someday",
          isSelected: _ctx.isSelected(7),
          onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.onClickRange(7, 'SOMEDAY'))),
          onDrop: _cache[27] || (_cache[27] = ($event: any) => (_ctx.onDrop($event, 'toSomeday'))),
          onDragover: _cache[28] || (_cache[28] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: _cache[29] || (_cache[29] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["label", "isSelected"])
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("h2", {
        class: _normalizeClass(["subHead", { close: !_ctx.tagGroupOpen, open: _ctx.tagGroupOpen }]),
        onClick: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.onTagGroupToggle && _ctx.onTagGroupToggle(...args)))
      }, _toDisplayString(_ctx.localizationText("tag")), 3),
      _createElementVNode("button", {
        class: "btn_addTag",
        onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => (_ctx.addNewTag && _ctx.addNewTag(...args)))
      }, "+"),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: tag.id
        }, [
          (_ctx.tagGroupOpen)
            ? (_openBlock(), _createBlock(_component_SingleItem, {
                key: 0,
                label: tag.name,
                minutes: _ctx.tagTasksWorkingMinutes(tag.id),
                isSelected: _ctx.isSelectedTag(tag),
                injectionColor: tag.color,
                onDrop: ($event: any) => (_ctx.onDropTag($event, tag.id)),
                onDragover: _cache[32] || (_cache[32] = _withModifiers(() => {}, ["prevent"])),
                onDragenter: _cache[33] || (_cache[33] = _withModifiers(() => {}, ["prevent"])),
                onClick: ($event: any) => (_ctx.onTagSelected(tag))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("dd", _hoisted_7, [
                    _createElementVNode("button", {
                      class: "btn_editOpen",
                      onClick: ($event: any) => (_ctx.onTagModalOpen(tag))
                    }, "︙", 8, _hoisted_8)
                  ])
                ]),
                _: 2
              }, 1032, ["label", "minutes", "isSelected", "injectionColor", "onDrop", "onClick"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_ctx.tagModalOpen)
        ? (_openBlock(), _createBlock(_component_TagEdit, {
            key: 0,
            tag: _ctx.selectedTag,
            onCancel: _ctx.onTagModalCancel,
            onSubmit: _ctx.onTagModalCancel,
            onDelete: _ctx.onTagDelete
          }, null, 8, ["tag", "onCancel", "onSubmit", "onDelete"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", {
      id: "trashWrap",
      onDrop: _cache[34] || (_cache[34] = ($event: any) => (_ctx.onDropToTrash($event))),
      onDragover: _cache[35] || (_cache[35] = _withModifiers(() => {}, ["prevent"])),
      onDragenter: _cache[36] || (_cache[36] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.localizationText("trashbox")), 1)
    ], 32)
  ]))
}