import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "dashboard" }
const _hoisted_2 = { class: "subHead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskStatusDisplayNow = _resolveComponent("TaskStatusDisplayNow")!
  const _component_TaskStatusDisplayNow2 = _resolveComponent("TaskStatusDisplayNow2")!
  const _component_TaskStatusDisplayPast = _resolveComponent("TaskStatusDisplayPast")!
  const _component_TaskStatusDisplayTagGroup = _resolveComponent("TaskStatusDisplayTagGroup")!
  const _component_TaskEditLight = _resolveComponent("TaskEditLight")!
  const _component_TaskList = _resolveComponent("TaskList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.localizationText(_ctx.headerLabel)), 1),
    (_ctx.isRangeDisplay && _ctx.isTodayRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow, {
          key: 0,
          taskCount: _ctx.countUntreatedTasks('TODAY'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('TODAY'),
          unit: 'h',
          isDoneTasksCount: _ctx.countDoneTasks('TODAY'),
          occupancyRate: _ctx.calcOccupancyRate('TODAY', 'h')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount", "occupancyRate"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isTomorrowRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 1,
          taskCount: _ctx.countUntreatedTasks('TOMORROW'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('TOMORROW'),
          unit: 'h',
          isDoneTasksCount: _ctx.countDoneTasks('TOMORROW'),
          occupancyRate: _ctx.calcOccupancyRate('TOMORROW', 'h')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount", "occupancyRate"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isThisWeekRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow, {
          key: 2,
          taskCount: _ctx.countUntreatedTasks('THIS_WEEK'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('THIS_WEEK'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('THIS_WEEK'),
          occupancyRate: _ctx.calcOccupancyRate('THIS_WEEK', 'd')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount", "occupancyRate"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isNextWeekRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 3,
          taskCount: _ctx.countUntreatedTasks('NEXT_WEEK'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('NEXT_WEEK'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('NEXT_WEEK'),
          occupancyRate: _ctx.calcOccupancyRate('NEXT_WEEK', 'd')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount", "occupancyRate"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isThisMonthRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 4,
          taskCount: _ctx.countUntreatedTasks('THIS_MONTH'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('THIS_MONTH'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('THIS_MONTH')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isNextMonthRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 5,
          taskCount: _ctx.countUntreatedTasks('NEXT_MONTH'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('NEXT_MONTH'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('NEXT_MONTH')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isSomedayRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 6,
          taskCount: _ctx.countUntreatedTasks('SOMEDAY'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('SOMEDAY'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('SOMEDAY')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isAfterTodayRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayNow2, {
          key: 7,
          taskCount: _ctx.countUntreatedTasks('AFTER_TOADY'),
          remainingAllotedTime: _ctx.calcRemainingAllotedTimeHours('AFTER_TOADY'),
          unit: 'd',
          isDoneTasksCount: _ctx.countDoneTasks('AFTER_TOADY'),
          occupancyRate: _ctx.calcOccupancyRate('AFTER_TOADY', 'd')
        }, null, 8, ["taskCount", "remainingAllotedTime", "isDoneTasksCount", "occupancyRate"]))
      : _createCommentVNode("", true),
    (_ctx.isRangeDisplay && _ctx.isCompletedRange)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayPast, {
          key: 8,
          isDoneTasksCount: _ctx.countDoneTasks('COMPLETED'),
          workedHours: _ctx.aggregateWorkedDays('COMPLETED'),
          unit: 'd'
        }, null, 8, ["isDoneTasksCount", "workedHours"]))
      : _createCommentVNode("", true),
    (_ctx.isTagDisplay)
      ? (_openBlock(), _createBlock(_component_TaskStatusDisplayTagGroup, {
          key: 9,
          taskCount: _ctx.activeTagTaskCount,
          isDoneTasksCount: _ctx.inactiveTagTaskCount,
          remainingAllotedTime: _ctx.inactiveTagTaskDays,
          totalWorkedTime: _ctx.activeTagTaskDays
        }, null, 8, ["taskCount", "isDoneTasksCount", "remainingAllotedTime", "totalWorkedTime"]))
      : _createCommentVNode("", true),
    (!_ctx.isCompletedRange || _ctx.isTagDisplay)
      ? (_openBlock(), _createBlock(_component_TaskEditLight, {
          onTaskAdded: _ctx.onTaskAdded,
          key: _ctx.addTaskCount
        }, null, 8, ["onTaskAdded"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TaskList, { isDoneTaskShow: _ctx.isDoneTaskShow }, null, 8, ["isDoneTaskShow"])
  ]))
}