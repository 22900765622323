import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["draggable"]
const _hoisted_2 = {
  key: 0,
  class: "taskCheck"
}
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "taskTitle" }
const _hoisted_5 = { class: "taskEx" }
const _hoisted_6 = { class: "taskDue" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([{ done: _ctx.task.isDone, active: _ctx.isSelected }, _ctx.deadCountClass()]),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    draggable: _ctx.draggable,
    onDragstart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDragStart($event))),
    onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
  }, [
    (!_ctx.isCompletedRange)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSwitchStatus && _ctx.onSwitchStatus(...args))),
            checked: _ctx.task.isDone,
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, null, 40, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.task.name), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskTags, (tag) => {
        return (_openBlock(), _createElementBlock("i", {
          key: tag,
          class: _normalizeClass(["tag", tag.color])
        }, _toDisplayString(tag.name), 3))
      }), 128))
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("i", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.task.deadlineDate?.toText()) + " ", 1),
        (_ctx.hasTimeRange)
          ? (_openBlock(), _createElementBlock("i", _hoisted_7, _toDisplayString(_ctx.startTime) + "-" + _toDisplayString(_ctx.endTime), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.task.hasWorkingTime)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["taskTime", _ctx.appleClass])
          }, _toDisplayString((_ctx.task.workingMinutes / 60).toFixed(1)), 3))
        : _createCommentVNode("", true)
    ])
  ], 42, _hoisted_1))
}