
import { defineComponent, ref } from "vue"

export default defineComponent({
  name: "DDSample",
  props: {
    msg: String,
  },
  setup() {
    const elements = ref<string[]>([])
    const onChange = (name: string) => {
      if (!elements.value.includes(name)) {
        elements.value.push(name)
      } else {
        elements.value = elements.value.filter((val: string) => val !== name)
      }
    }
    const onDragStart = (e: any, name: string) => {
      e.dataTransfer.effectAllowed = "move"
      e.dataTransfer.dropEffect = "move"
      e.dataTransfer.setData("list-id", name)
    }
    const xxxx = () => window.alert(`${elements.value}がdropされました`)
    return {
      elements,
      onChange,
      onDragStart,
      xxxx,
    }
  },
})
