
import { defineComponent, ref, PropType } from "vue"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"
import TagEntity from "@/domain/entities/tag.entity"

export default defineComponent({
  name: "TagSelect",
  emits: ["close", "selectEnd"],
  props: {
    allTags: {
      required: true,
      type: Array as PropType<TagEntity[]>,
    },
    selectedTagIds: {
      required: true,
      type: Array as PropType<string[]>,
    },
  },
  setup(props, { emit }) {
    let selectedNewTagIds = ref<string[]>([])
    selectedNewTagIds.value = props.selectedTagIds.map((id) => id)

    const toggleSelectedTagIds = (tag: TagEntity) => {
      selectedNewTagIds.value = selectedNewTagIds.value.includes(tag.id.value)
        ? selectedNewTagIds.value.filter((tagId) => tagId != tag.id.value)
        : [...selectedNewTagIds.value, tag.id.value]
    }

    const isSelectedTag = (tag: TagEntity) => {
      return selectedNewTagIds.value.includes(tag.id.value)
    }
    const onClose = () => {
      emit("close")
    }
    const onTagSelectEnd = () => {
      emit("selectEnd", selectedNewTagIds.value)
    }
    const userStore = useUserStore()
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      toggleSelectedTagIds,
      isSelectedTag,
      onTagSelectEnd,
      onClose,
      localizationText,
    }
  },
})
