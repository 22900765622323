import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "taskStatus past" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("dl", null, [
      _createElementVNode("dt", null, _toDisplayString(_ctx.localizationText("i_did")), 1),
      _createElementVNode("dd", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.isDoneTasksCount), 1),
        _createElementVNode("i", null, _toDisplayString(_ctx.localizationText("completed")), 1)
      ])
    ]),
    _createElementVNode("dl", null, [
      _createElementVNode("dt", null, _toDisplayString(_ctx.localizationText("total_time")), 1),
      _createElementVNode("dd", null, [
        _createElementVNode("b", null, [
          _createTextVNode(_toDisplayString(_ctx.workedHours), 1),
          _createElementVNode("i", null, _toDisplayString(_ctx.unit), 1)
        ]),
        _createElementVNode("i", null, _toDisplayString(_ctx.localizationText("completed")), 1)
      ])
    ])
  ]))
}