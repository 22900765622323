import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "headerList" }
const _hoisted_5 = { class: "search" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "setting" }
const _hoisted_8 = { class: "logout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_figurecaption = _resolveComponent("figurecaption")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.hasUser)
      ? (_openBlock(), _createElementBlock("figure", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.user.imageUrl
          }, null, 8, _hoisted_3),
          _createVNode(_component_figurecaption, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.name), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWord) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchTask && _ctx.searchTask(...args))),
          placeholder: _ctx.localizationText('task_search')
        }, null, 40, _hoisted_6), [
          [_vModelText, _ctx.searchWord]
        ])
      ]),
      _createElementVNode("li", _hoisted_7, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goProfilePage && _ctx.goProfilePage(...args)))
        }, _toDisplayString(_ctx.localizationText("user_settings")), 1)
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.signout && _ctx.signout(...args)))
        }, _toDisplayString(_ctx.localizationText("logout")), 1)
      ])
    ])
  ]))
}