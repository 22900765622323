
import { useProfileModalStore } from "@/stores/profileModal"
import { useUserStore } from "@/stores/user"
import { defineComponent, ref } from "vue"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "PasswordChange",
  emits: ["click"],
  setup() {
    const modalStore = useProfileModalStore()
    const closeModal = () => modalStore.close()
    const userStore = useUserStore()
    const password1 = ref<string>("")
    const password2 = ref<string>("")
    const inputType = ref<string>("password")
    const showToggle = () => {
      if (inputType.value === "text") {
        inputType.value = "password"
        return
      }
      if (inputType.value === "password") {
        inputType.value = "text"
      }
    }
    const changePassword = async () => {
      if (password1.value !== password2.value) {
        alert(localizationText("password_not_match"))
        return
      }
      if (password1.value.length < 6) {
        alert(localizationText("password_under_6"))
        return
      }
      try {
        await userStore.updatePassword({ password: password1.value })
        alert(localizationText("password_changed"))
      } catch (error) {
        alert(localizationText("failed_password_changed"))
        return
      }
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      closeModal,
      password1,
      password2,
      inputType,
      showToggle,
      changePassword,
      localizationText,
    }
  },
})
