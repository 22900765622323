import DayOfWeek from "../valueObjects/dayOfWeek"
import DateTime from "../valueObjects/dateTime"

type Props = {
  name: string
  workingDayOfWeek: DayOfWeek[]
  workingMinutesPerDay: number
  createdAt: Date
  updatedAt: Date
  imageUrl: string
  startedAt: DateTime | null
  endDate: DateTime
  language: string
}

export default class UserEntity {
  readonly name!: string

  readonly workingDayOfWeek!: DayOfWeek[]

  readonly workingMinutesPerDay!: number

  readonly createdAt!: Date

  readonly updatedAt!: Date

  readonly imageUrl!: string

  // 開始日
  readonly startedAt!: DateTime | null

  readonly endDate!: DateTime

  readonly language!: string

  static create(): UserEntity {
    const props: Props = {
      name: "わたし",
      workingDayOfWeek: [
        DayOfWeek.monday,
        DayOfWeek.tuesday,
        DayOfWeek.wednesday,
        DayOfWeek.thursday,
        DayOfWeek.friday,
      ],
      workingMinutesPerDay: 480,
      createdAt: new Date(),
      updatedAt: new Date(),
      imageUrl: "",
      startedAt: null,
      endDate: DateTime.today().addTime(3.154e11),
      language: "en",
    }
    return new UserEntity(props)
  }

  constructor(private readonly props: Props) {
    Object.assign(this, props)
  }

  get isWorking(): boolean {
    return this.startedAt !== null && this.startedAt.isToday
  }

  get isNotWorking(): boolean {
    if (this.startedAt === null) {
      return true
    }

    return this.startedAt !== null && this.startedAt.isNotToday
  }

  // ユーザー名の更新
  updateName(name: string): UserEntity {
    if (name.length < 2) {
      throw new Error("名前が短すぎる")
    }

    if (name.length > 24) {
      throw new Error("名前が長すぎる")
    }

    return new UserEntity({ ...this.props, name })
  }

  // 稼働曜日の更新
  updateWorkingDayOfWeek(workingDayOfWeek: DayOfWeek[]): UserEntity {
    return new UserEntity({ ...this.props, workingDayOfWeek })
  }

  // 1日あたりの稼働時間（分）の更新
  updateWorkingMinutesPerDay(workingMinutesPerDay: number): UserEntity {
    return new UserEntity({ ...this.props, workingMinutesPerDay })
  }

  // ユーザープロフィール画像の更新
  updateImageUrl(imageUrl: string): UserEntity {
    return new UserEntity({ ...this.props, imageUrl })
  }

  // 作業の開始時刻を書き込む
  startWork(): UserEntity {
    return new UserEntity({ ...this.props, startedAt: new DateTime(new Date()) })
  }

  updateEndDate(endDate: DateTime): UserEntity {
    return new UserEntity({ ...this.props, endDate })
  }

  updateLanguage(language: string): UserEntity {
    return new UserEntity({ ...this.props, language })
  }

  get workingWeekDayIndexes(): number[] {
    return this.workingDayOfWeek.map((day) => day.value)
  }

  get workingDayCount(): number {
    return this.workingDayOfWeek.length
  }
}
