
import { defineComponent, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { z } from "zod"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "PasswordReset",
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const email = ref("")
    const password = ref("")
    const onSendEmail = async () => {
      try {
        const zEmail = z.string().email({ message: localizationText("email_invalid") })
        const result = zEmail.safeParse(email.value)
        if (!result.success) {
          alert(localizationText("email_invalid"))
          return
        }
        await userStore.sendPasswordResetEmail({ email: email.value })
        alert(localizationText("reset_email_sended"))
        router.push({ path: "/" })
      } catch (error) {
        alert(localizationText("failed_email_sended"))
        return
      }
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      var language =
        (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language
      if (language !== "ja") {
        language = "en"
      }
      return getLocalization(language, key)
    }
    return {
      email,
      password,
      onSendEmail,
      localizationText,
    }
  },
})
