import { defineStore } from "pinia"

type State = {
  isOpen: boolean
}

export const useTagEditModalStore = defineStore("tagEditModal", {
  state: (): State => {
    return {
      isOpen: false,
    }
  },
  actions: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
  },
})
