import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "taskContents" }
const _hoisted_2 = {
  key: 1,
  class: "editWindow",
  id: "setting"
}
const _hoisted_3 = { class: "settingTab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TaskEdit = _resolveComponent("TaskEdit")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_TaskCalendar = _resolveComponent("TaskCalendar")!
  const _component_EmailChange = _resolveComponent("EmailChange")!
  const _component_PasswordChange = _resolveComponent("PasswordChange")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader),
    _createElementVNode("main", {
      id: "main",
      class: _normalizeClass({ taskEditOPen: _ctx.isTaskSelected })
    }, [
      _createVNode(_component_SideMenu),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ]),
      (_ctx.isTaskSelected)
        ? (_openBlock(), _createBlock(_component_TaskEdit, {
            onTaskAdded: _ctx.onTaskAdded,
            key: _ctx.refreshKey
          }, null, 8, ["onTaskAdded"]))
        : _createCommentVNode("", true),
      (_ctx.isModalOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("ul", _hoisted_3, [
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onMenuClick('profile'))),
                    class: _normalizeClass({ active: _ctx.isSelectedModalMenu('profile') })
                  }, _toDisplayString(_ctx.localizationText("profile")), 3)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMenuClick('calender'))),
                    class: _normalizeClass({ active: _ctx.isSelectedModalMenu('calender') })
                  }, _toDisplayString(_ctx.localizationText("special_holidays")), 3)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMenuClick('changeEmail'))),
                    class: _normalizeClass({ active: _ctx.isSelectedModalMenu('changeEmail') })
                  }, _toDisplayString(_ctx.localizationText("change_mail_address")), 3)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onMenuClick('changePassword'))),
                    class: _normalizeClass({ active: _ctx.isSelectedModalMenu('changePassword') })
                  }, _toDisplayString(_ctx.localizationText("change_password")), 3)
                ])
              ]),
              _createElementVNode("button", {
                class: "close",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, _toDisplayString(_ctx.localizationText("close")), 1),
              (_ctx.isSelectedModalMenu('profile'))
                ? (_openBlock(), _createBlock(_component_UserProfile, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.isSelectedModalMenu('calender'))
                ? (_openBlock(), _createBlock(_component_TaskCalendar, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.isSelectedModalMenu('changeEmail'))
                ? (_openBlock(), _createBlock(_component_EmailChange, { key: 2 }))
                : _createCommentVNode("", true),
              (_ctx.isSelectedModalMenu('changePassword'))
                ? (_openBlock(), _createBlock(_component_PasswordChange, { key: 3 }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}