
import { defineComponent, ref, computed, onUnmounted } from "vue"
import { useRouter } from "vue-router"
// https://vcalendar.io/colors-dark-mode.html
import { Calendar } from "v-calendar"
import { collection, getFirestore, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import TaskConverter from "@/infrastructure/converters/task.converter"
import EventEntity from "@/domain/entities/event.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"
import { useLocalizationStore } from "@/stores/localization"

type Page = {
  month: number
  year: number
}

export default defineComponent({
  name: "TaskCalendar",
  components: {
    Calendar,
  },
  setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const eventStore = useEventStore()
    const selectedDate = ref(DateTime.now().toTextJoinHyphen())
    const events = ref<EventEntity[]>()
    const uid = computed(() => userStore.uid!)
    const unsub = ref<Unsubscribe>()
    const loadTasks = async () => {
      events.value = []
      if (typeof unsub.value !== "undefined") {
        unsub.value()
      }
      const dateTime = DateTime.fromText(selectedDate.value)
      const firstDate = dateTime.toMonthStart().value
      const lastDate = dateTime.toMonthEnd().value
      const q = query(
        collection(getFirestore(), "users", uid.value, "tasks")!.withConverter(TaskConverter),
        where("date", ">=", firstDate),
        where("date", "<=", lastDate),
      )
      unsub.value = onSnapshot(q, (querySnap) => {
        events.value = querySnap.docs.map((docSnap) => docSnap.data())
      })
    }
    onUnmounted(() => {
      if (typeof unsub.value !== "undefined") {
        unsub.value()
      }
      return false
    })
    loadTasks()
    const dayClick = (day: any) => {
      selectedDate.value = day.id
    }
    const userEntity = computed(() => userStore.entity!)
    const monthUp = (page: Page) => {
      selectedDate.value = DateTime.fromNumbers(page.year, page.month, 1).toTextJoinHyphen()
      loadTasks()
    }
    const addHoliday = async () => {
      const holidy: EventEntity = EventEntity.createHoliday({
        date: DateTime.fromText(selectedDate.value),
        userId: uid.value,
      })
      await eventStore.writeEvent(holidy)
    }
    const removeHoliday = async () => {
      const removeTarget = events.value?.find((event) => {
        return event.type.isHoliday && event.date?.toTextJoinHyphen() === selectedDate.value
      })
      if (typeof removeTarget === "undefined") {
        throw new Error()
      }
      await eventStore.removeEvent(removeTarget)
      return false
    }

    const holidays = computed(() => {
      const [yearText, monthText] = selectedDate.value.split("-")
      const allDays = DateTime.fromTexts(yearText, monthText, "1").toMonthDays()
      const highlightedDays = allDays.filter((_, i) => {
        const day = DateTime.fromTexts(yearText, monthText, `${i + 1}`)
        // 曜日
        const dayOfWeek = day.dayOfWeek()

        const isWorkingDay = userEntity.value.workingDayOfWeek
          .map((d) => d.value)
          .includes(dayOfWeek)

        const isHoliday = !isWorkingDay

        return isHoliday
      })

      const holidayEvents = events.value?.filter((event) => event.type.isHoliday)

      const aHolidays = allDays.filter((_, i) => {
        const day = DateTime.fromTexts(yearText, monthText, `${i + 1}`)
        // 曜日
        const date = day.value.getDate()

        const isHoliday = holidayEvents?.map((event) => event.date?.value?.getDate()).includes(date)

        return isHoliday
      })

      return [
        {
          key: "holiday",
          highlight: {
            color: "purple",
            fillMode: "light",
            contentClass: "italic",
          },
          dates: highlightedDays.map(
            (day) => DateTime.fromTexts(yearText, monthText, day.toString()).value,
          ),
        },
        {
          key: "holiday2",
          highlight: {
            color: "red",
            fillMode: "light",
            contentClass: "italic",
          },
          dates: aHolidays.map(
            (day) => DateTime.fromTexts(yearText, monthText, day.toString()).value,
          ),
        },
      ]
    })
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    // const days = computed(() => {
    // });
    return {
      events,
      selectedDate,
      dayClick,
      monthUp,
      holidays,
      addHoliday,
      removeHoliday,
      localizationText,
    }
  },
})
