import { getWeek, format, getDaysInMonth, lastDayOfMonth } from "date-fns"

export default class DateTime {
  constructor(readonly value: Date) {
    this.value = value
  }

  /**
   * 時間を切り捨てた現在の日付を返す
   */
  static now(): DateTime {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    return new DateTime(date)
  }

  static today(): DateTime {
    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return new DateTime(date)
  }

  static tomorrow(): DateTime {
    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    return new DateTime(date)
  }

  static nextWeek(): DateTime {
    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return new DateTime(date).toNextWeek()
  }

  /**
   * テキストから日付を作成する
   */
  static fromText(dateText: string, timeText?: string): DateTime {
    if (typeof timeText !== "undefined" && timeText !== "") {
      return new DateTime(new Date(`${dateText} ${timeText}`))
    }

    const [year, month, day] = dateText.split("-")

    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))

    return new DateTime(date)
  }

  /**
   * テキストから日付を作成する
   */
  static fromTexts(year: string, month: string, day: string): DateTime {
    return this.fromNumbers(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10))
  }

  /**
   * 数字から日付を作成する
   */
  static fromNumbers(year: number, month: number, day: number): DateTime {
    const date = new Date(year, month - 1, day)

    return new DateTime(date)
  }

  /**
   * 最初の月を返す
   */
  static firstDate(year: number, month: number): DateTime {
    const date = new Date(year, month - 1, 1)

    return new DateTime(date)
  }

  /**
   * 平日である
   */
  get isWeekday(): boolean {
    return this.value.getDay() !== 0 && this.value.getDay() !== 6
  }

  /**
   * その日付の7日後（来週）の日付を返す
   */
  toNextWeek(): DateTime {
    const n = this.value.getDate() + 7

    const date = new Date(this.value.getTime())

    date.setDate(n)

    return new DateTime(date)
  }

  /**
   * その日付の週の最後の日（土曜日）の日付を返す
   */
  toWeekEnd(): DateTime {
    // 日付 - 曜日数 = 月曜日戻す
    const n = this.value.getDate() - this.value.getDay() + 6
    // const n = this.value.getDate() - this.value.getDay();

    const date = new Date(this.value.getTime())

    date.setDate(n)

    return new DateTime(date)
  }

  /**
   * 曜日を変更する
   */
  updateWeek(week: number): DateTime {
    const value = this.value

    value.setDate(value.getDate() + (week - value.getDay()))

    return new DateTime(value)
  }

  /**
   * 日にちを変更する
   */
  updateDate(date: number): DateTime {
    const value = this.value

    value.setDate(date)

    return new DateTime(value)
  }

  /**
   * 今週の
   */
  toWeekFriday(): DateTime {
    const value = this.value

    value.setDate(value.getDate() + (5 - value.getDay()))

    return new DateTime(value)
  }

  /**
   * その日付の週の最初の日（月曜日）の日付を返す
   */
  toWeekStart(): DateTime {
    const n = this.value.getDate() - this.value.getDay()
    // const n = this.value.getDate() - this.value.getDay() + 1 - 7;

    const date = new Date(this.value.getTime())

    date.setDate(n)

    return new DateTime(date)
  }

  /**
   * 次の月の日付を返す
   * TODO: 31日だったら変になる
   */
  toNextMonth(): DateTime {
    const date = new Date(
      this.value.getFullYear(),
      this.value.getMonth() + 1,
      this.value.getDate(),
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * 曜日を返す
   */
  dayOfWeek(): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
    return this.value.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6
  }

  /**
   * 日付を今日にする
   */
  toToday(): DateTime {
    const now = new Date()

    const date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * 次の日を返す
   */
  toNextDay(): DateTime {
    const date = new Date(
      this.value.getFullYear(),
      this.value.getMonth(),
      this.value.getDate() + 1,
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * 明後日を返す
   */
  toNextNextDay(): DateTime {
    const date = new Date(
      this.value.getFullYear(),
      this.value.getMonth(),
      this.value.getDate() + 2,
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * 月初日を返す
   */
  toMonthStart(): DateTime {
    const date = new Date(
      this.value.getFullYear(),
      this.value.getMonth(),
      1,
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * 月末日を返す
   */
  toMonthEnd(): DateTime {
    const date = new Date(
      this.value.getFullYear(),
      this.value.getMonth() + 1,
      0,
      this.value.getHours(),
      this.value.getMinutes(),
      0,
    )

    return new DateTime(date)
  }

  /**
   * その月の日数を返す
   */
  toMonthDays(): number[] {
    const lastDay = this.toMonthEnd()

    return new Array(lastDay.value.getDate()).fill(null).map((_, i) => i + 1)
  }

  /**
   * スラッシュ繋ぎの日付を返す
   */
  toText(): string {
    return format(this.value, "yyyy/MM/dd")
  }

  toTextObject(): { date: string; week: string } {
    return {
      date: format(this.value, "MM/dd"),
      week: format(this.value, "EEE"),
    }
  }

  toTextJoinHyphen(): string {
    return format(this.value, "yyyy-MM-dd")
  }

  /**
   * 型式「00:00」のテキストを返す
   */
  toTextStartTime(): string {
    const hour = this.value.getHours().toString().padStart(2, "0")
    const minutes = this.value.getMinutes().toString().padStart(2, "0")
    // console.log('HHH', hour)
    // console.log('MMMM', minutes)
    return `${hour}:${minutes}`
  }

  /**
   * その月の稼働日数を返す
   */
  toMonthWorkingDaysCount(workingWeekIndexes = [1, 2, 3, 4, 5]): number {
    // 月の日数
    const lastDay = this.toMonthEnd().value.getDate()
    const year = this.value.getFullYear()
    const month = this.value.getMonth()
    // 月の日付の配列
    const days = [...Array(lastDay)].map((_, i) => {
      const date = new Date(year, month, i + 1)
      return date.getDay()
    })
    // 土曜日と日曜日を除く
    const workingDays = days.filter((index) => {
      return workingWeekIndexes.includes(index)
    })
    return workingDays.length
  }

  /**
   * ある日からその月の稼働日数を返す
   */
  toMonthWorkingDaysCountFrom(startDay: number, workingWeekIndexes = [1, 2, 3, 4, 5]): number {
    // 月の日数
    const lastDay = this.toMonthEnd().value.getDate()
    const year = this.value.getFullYear()
    const month = this.value.getMonth()
    // 月の日付の配列
    const days = [...Array(lastDay + 1 - startDay)].map((_, i) => {
      const date = new Date(year, month, i + startDay)
      return date.getDay()
    })
    // 月曜日と日曜日を除く
    const workingDays = days.filter((index) => {
      return workingWeekIndexes.includes(index)
    })
    return workingDays.length
  }

  toDate(): Date {
    return this.value
  }

  /**
   * テキストを返す
   * 例: 2022-02-20
   */
  toDateText(): string {
    return [this.value.getFullYear(), this.value.getMonth() + 1, this.value.getDate()].join("-")
  }

  addTime(milliseconds: number): DateTime {
    return new DateTime(new Date(this.value.getTime() + milliseconds))
  }

  isSameDay(date: DateTime): boolean {
    return (
      this.value.getFullYear() === date.value.getFullYear() &&
      this.value.getMonth() === date.value.getMonth() &&
      this.value.getDate() === date.value.getDate()
    )
  }

  get lastWeekDate(): DateTime {
    // 0:日曜日, 1:月曜日, 2:火曜日, 3:水曜日, 4:木曜日, 5:金曜日, 6:土曜日
    const index = this.value.getDay()
    // 木曜日以下の場合
    if (4 < index) {
      return this.toWeekEnd()
    }
    return this.toNextNextDay()
  }

  /**
   * その月の最後の同じ曜日を返す
   */
  get lastSameWeekDateInMonth(): DateTime {
    const n = getDaysInMonth(this.value)
    const dates = [...Array(n)].map((_, i) => {
      return new Date(this.value.getFullYear(), this.value.getMonth(), i + 1)
    })
    const sameWeekDates = dates.filter((date) => {
      return date.getDay() === this.value.getDay()
    })
    const sortedDates = sameWeekDates.sort((a, b) => {
      return a.getTime() - b.getTime()
    })
    // 月の最後の同曜日の日付
    const lastWeekDate = sortedDates[sortedDates.length - 1]
    if (lastWeekDate.getDate() === this.value.getDate()) {
      return new DateTime(lastDayOfMonth(this.value))
    }
    return new DateTime(lastWeekDate)
  }

  get lastSameDateInNextMonth(): DateTime {
    const nextMonth = this.value.getMonth() + 1
    const nextYear = this.value.getFullYear()
    const nextDate = this.value.getDate()
    const nextDateTime = new Date(nextYear, nextMonth, nextDate)
    // 来月じゃなかったら来月の最初の日を返す
    if (nextDateTime.getMonth() !== nextMonth) {
      return new DateTime(lastDayOfMonth(new Date(nextYear, nextMonth, 1)))
    }
    return new DateTime(nextDateTime)
  }

  /**
   * 週番号
   * https://date-fns.org/v2.28.0/docs/getWeek
   */
  get weekIndex(): number {
    return getWeek(this.value)
  }

  get isNotToday(): boolean {
    return this.value.getDate() !== new Date().getDate()
  }

  /**
   * 開始日が今日
   */
  get isToday(): boolean {
    return (
      this.value.getMonth() === new Date().getMonth() &&
      this.value.getDate() === new Date().getDate()
    )
  }

  /**
   * 今日からみて明日である
   */
  get isTomorrow(): boolean {
    return this.value.getDate() === new Date().getDate() + 1
  }

  /**
   * 今日からみて今週である
   */
  get isThisWeek(): boolean {
    return this.weekIndex === DateTime.now().weekIndex
    // return this.value.getDate() >= new Date().getDate() - new Date().getDay();
  }

  /**
   * 今日からみて来週である
   */
  get isNextWeek(): boolean {
    return this.weekIndex === DateTime.now().weekIndex + 1
    // return this.value.getDate() >= new Date().getDate() - new Date().getDay() + 7;
  }

  /**
   * 今日からみて今月である
   */
  get isThisMonth(): boolean {
    return this.value.getMonth() === new Date().getMonth()
  }

  /**
   * 今日からみて来月である
   */
  get isNextMonth(): boolean {
    return this.value.getMonth() === new Date().getMonth() + 1
  }

  get hasTime(): boolean {
    return this.value.getHours() !== 0 || this.value.getMinutes() !== 0
  }

  get hasNoTime(): boolean {
    return this.value.getHours() === 0 && this.value.getMinutes() === 0
  }

  get isFuture(): boolean {
    return this.value.getTime() > new Date().getTime()
  }
}
