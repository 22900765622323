import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Dashboard from "../views/Dashboard.vue"
import Signup from "../views/Signup.vue"
import Signin from "../views/Signin.vue"
import SideMenuLayout from "../components/layouts/SideMenuLayout.vue"
import TaskEdit from "../views/TaskEdit.vue"
import TagEdit from "../views/TagEdit.vue"
import DDSample from "../views/DDSample.vue"
import TaskCalendar from "../components/TaskCalendar.vue"
import SetupPassword from "../views/SetupPassword.vue"
import PasswordReset from "../views/PasswordReset.vue"
import Report from "../views/Report.vue"
import Error from "../views/Error.vue"
import Tester from "../views/Tester.vue"
import { EmailAuthProvider } from "firebase/auth"
import { useUserStore } from "@/stores/user"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { title: "Sign Up｜わたしの持ちじかん" },
  },
  {
    path: "/ddsample",
    name: "DDSample",
    component: DDSample,
    meta: { title: "わたしの持ちじかん" },
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { title: "Log In｜わたしの持ちじかん" },
  },
  {
    path: "/",
    name: "SideMenuLayout",
    component: SideMenuLayout,
    children: [
      {
        path: "",
        name: "DashBoard",
        component: Dashboard,
      },
    ],
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/taskEdit",
    name: "TaskCreate",
    component: TaskEdit,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/taskEdit/:id",
    name: "TaskEdit",
    component: TaskEdit,
    props: true,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/tagEdit",
    name: "TagCreate",
    component: TagEdit,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/tagEdit/:id",
    name: "TagEdit",
    component: TagEdit,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/taskCalendar",
    name: "TaskCalendar",
    component: TaskCalendar,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/setuppassword",
    name: "SetupPassword",
    component: SetupPassword,
    meta: { title: "わたしの持ちじかん", requiresAuth: true },
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { title: "Reset Passwird｜わたしの持ちじかん" },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: { title: "わたしの持ちじかん" },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    props: true,
    meta: { title: "わたしの持ちじかん" },
  },
  {
    path: "/tester",
    name: "Tester",
    component: Tester,
    props: true,
    meta: { title: "わたしの持ちじかん" },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()

  if (to.name === "Signup") {
    if (userStore.isLoggedIn) {
      next({ name: "DashBoard" })
      return
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // ログインしてなければログインページへ
    if (userStore.isNotLoggedIn) {
      next({ name: "Signin" })
      return
    }
    if (to.name === "SetupPassword") {
      next()
      return
    }
    const email = userStore.email!
    // console.log("メールアドレス持ってる？", email)
    const loginMethods: string[] = await userStore.getLoginMethods({ email })
    // パスワードを持っていないならパスワード設定ページへ
    if (!loginMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
      next({ name: "SetupPassword" })
      return
    }
    // console.log("loginMethods", loginMethods)
    // パスワードを持ってなければ設定ページへ
    // if (1 + 1 === 2) {
    //   console.log('パスワード持ってないよ');
    //   next({ name: "SetupPassword" });
    //   return;
    // }
  }
  next()
})

export default router
