
import { defineComponent, ref, computed } from "vue"
import EventEntity from "@/domain/entities/event.entity"
import WorkingHourSelect from "@/components/WorkingHourSelect.vue"
import TagEntity from "@/domain/entities/tag.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import TagItem from "@/components/TagItem.vue"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"
import { useLocalizationStore } from "@/stores/localization"
import { useTaskDisplayStore } from "@/stores/taskDisplay"
import { useWorkTimeSelectModal } from "@/stores/workTimeSelectModal"

export default defineComponent({
  name: "TaskEditLight",
  components: {
    WorkingHourSelect,
    TagItem,
  },
  emits: ["taskAdded"],
  setup(props, { emit }) {
    const userStore = useUserStore()

    const eventStore = useEventStore()

    // ユーザID
    const uid = computed(() => userStore.uid)

    const selectedTag = computed<TagEntity | null>(() => eventStore.selectedTag)

    // タスクの名前
    const taskName = ref("")

    // 期限日
    const selectedDeadLineDate = ref<string>("")

    // タスクの稼働時間
    const workingMinutes = ref<string>("")

    // const modalOpen = ref<boolean>(false)

    const selectedRange = computed(() => eventStore.selectedTaskRange)

    const taskDisplayStore = useTaskDisplayStore()

    const getDefaultDate = () => {
      if (taskDisplayStore.isTagDisplayMode) return null

      if (selectedRange.value === "TODAY") {
        return DateTime.today()
      }
      if (selectedRange.value === "TOMORROW") {
        return DateTime.tomorrow()
      }
      if (selectedRange.value === "THIS_WEEK") {
        // 明後日が今週に存在する場合には、明後日
        // 明後日が今週に存在しない場合、土曜日（今週のおしり）
        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        // ここだけ仕様でてない！！！！！
        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        return DateTime.today().lastWeekDate
      }
      if (selectedRange.value === "NEXT_WEEK") {
        return DateTime.nextWeek()
      }
      if (selectedRange.value === "THIS_MONTH") {
        return DateTime.today().lastSameWeekDateInMonth
      }
      if (selectedRange.value === "NEXT_MONTH") {
        return DateTime.today().lastSameDateInNextMonth
      }
      return null
    }

    // タスクを追加する
    const onAddTask = async (event: any) => {
      if (event.keyCode !== 13) return
      if (workingMinutes.value === "") {
        workingMinutes.value = "60"
      }
      const numTime = Number(workingMinutes.value)
      if (isNaN(numTime)) {
        alert("稼働時間は数値で入力してください。")
        return
      }
      const eventEntity = EventEntity.createTask({
        name: taskName.value,
        tagIds: selectedTag.value ? [selectedTag.value.id] : [],
        userId: uid.value!,
        date:
          selectedDeadLineDate.value === ""
            ? getDefaultDate()
            : DateTime.fromText(selectedDeadLineDate.value),
        deadlineDate:
          selectedDeadLineDate.value === ""
            ? getDefaultDate()
            : DateTime.fromText(selectedDeadLineDate.value),
        memo: "",
        workingMinutes: parseInt(workingMinutes.value),
      })
      await eventStore.writeEvent(eventEntity)
      emit("taskAdded")
    }

    const workTimeSelectModal = useWorkTimeSelectModal()
    const isWorkTimeSelectModalOpen = computed(
      () => workTimeSelectModal.isOpen && workTimeSelectModal.openFrom === "TaskEditLight",
    )

    const appleClass = computed(() => {
      const appleCount = Math.ceil(Number(workingMinutes.value) / 30)
      // 変更保存前の稼働時間の設定からリンゴを更新できるよう変更
      if (appleCount === 1) return "t05"
      if (appleCount === 2) return "t10"
      if (appleCount === 3) return "t15"
      if (appleCount === 4) return "t20"
      if (appleCount === 5) return "t25"
      if (appleCount === 6) return "t30"
      if (appleCount > 6) return "t30"
    })

    const onOpenModal = (from: string) => {
      workTimeSelectModal.open(from)
    }

    const onModalChancel = () => {
      workTimeSelectModal.close()
    }

    const onModalSubmit = (minutes: string) => {
      workTimeSelectModal.close()
      workingMinutes.value = minutes
    }

    const hasInputFocus = ref<boolean>(false)

    const onInputFocus = () => {
      hasInputFocus.value = true
    }

    const onInputOutFocus = () => {
      hasInputFocus.value = false
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      taskName,
      selectedDeadLineDate,
      workingMinutes,
      isWorkTimeSelectModalOpen,
      onAddTask,
      appleClass,
      onOpenModal,
      onModalChancel,
      onModalSubmit,
      hasInputFocus,
      onInputFocus,
      onInputOutFocus,
      localizationText,
    }
  },
})
