import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "editpassword" }
const _hoisted_2 = { class: "editProfile" }
const _hoisted_3 = { class: "registerList" }
const _hoisted_4 = ["type", "placeholder"]
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = { class: "submitList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createElementVNode("input", {
            class: "passwordDisplay",
            type: _ctx.inputType,
            placeholder: _ctx.localizationText('new_password')
          }, null, 8, _hoisted_4),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showToggle && _ctx.showToggle(...args)))
          }, "表示/非表示")
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("input", {
            class: "passwordDisplay",
            type: _ctx.inputType,
            placeholder: _ctx.localizationText('new_password_confirm')
          }, null, 8, _hoisted_5),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showToggle && _ctx.showToggle(...args)))
          }, "表示/非表示")
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_6, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "reset",
          class: "cancel",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.localizationText("cancel")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "submit",
          class: "submit",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)))
        }, _toDisplayString(_ctx.localizationText("change")), 1)
      ])
    ])
  ]))
}