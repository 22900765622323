
import { defineComponent, computed } from "vue"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TasksStatusDisplay",
  props: {
    taskCount: {
      type: Number,
    },
    remainingAllotedTime: {
      type: Number,
    },
    unit: {
      type: String,
    },
    isDoneTasksCount: {
      type: Number,
    },
    remainingTime: {
      type: String,
    },
    // 稼働率
    occupancyRate: {
      type: Number,
    },
  },
  setup(props) {
    const addClass = computed(() => {
      if (typeof props.occupancyRate !== "undefined") return `p${props.occupancyRate}`
      return ""
    })
    // const displayRemainingAllotedTime = computed(() => {
    //   if (props.remainingAllotedTime === undefined) return 0
    //   if (props.remainingAllotedTime < 0) return 0
    //   return props.remainingAllotedTime
    // })
    const displayTime = computed(() => {
      if (props.remainingAllotedTime === undefined) return ""
      if (props.remainingAllotedTime <= 0) return "0"
      // 小数点切り捨て
      if (isTwoDaysOverWorking.value) {
        // return Math.ceil(props.remainingAllotedTime / (userEntity.value.workingMinutesPerDay / 60))
        return Math.floor(props.remainingAllotedTime / (userEntity.value.workingMinutesPerDay / 60))
      }
      return Math.floor(props.remainingAllotedTime).toString()
    })

    const userStore = useUserStore()
    const userEntity = computed(() => userStore.entity!)
    const isTwoDaysOverWorking = computed(() => {
      if (userEntity === null) return false
      if (typeof props.remainingAllotedTime === "undefined") return false
      return (
        2 < Math.ceil(props.remainingAllotedTime / (userEntity.value.workingMinutesPerDay / 60))
      )
    })
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      addClass,
      displayTime,
      isTwoDaysOverWorking,
      localizationText,
    }
  },
})
