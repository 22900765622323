
import { defineComponent, ref, computed } from "vue"
import { useEventStore } from "@/stores/event"
import { useUserStore } from "@/stores/user"
import { useProfileModalStore } from "@/stores/profileModal"
import { useRouter } from "vue-router"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TheHeader",
  components: {},
  setup() {
    const router = useRouter()
    const eventStore = useEventStore()
    const userStore = useUserStore()
    const modalStore = useProfileModalStore()
    // 入力中の検索ワード
    const searchWord = ref("")
    // 検索用の全てのタスク
    const allTasks = computed(() => eventStore.tasks)
    const user = computed(() => userStore.entity!)
    const hasUser = computed<boolean>(() => user.value !== null)
    const searchTask = () => {
      const searchTasks =
        allTasks.value?.filter((task) => task.name.includes(searchWord.value)) ?? []
      if (searchWord.value !== "") {
        eventStore.setSearchTasks(searchTasks)
        return
      }
      eventStore.setSearchTasks([])
    }
    const goProfilePage = () => {
      modalStore.open()
      // router.push({ name: "UserProfile" })
    }
    const test = () => console.log("test")
    const signout = async () => {
      await userStore.signOut()
      window.location.reload()
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      searchWord,
      user,
      hasUser,
      goProfilePage,
      searchTask,
      test,
      signout,
      allTasks,
      localizationText,
    }
  },
})
