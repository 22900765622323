type Value = "IN_PROGRESS" | "UNTREATED" | "DONE"

/**
 * タスクの状態
 */
export default class EventStatus {
  constructor(readonly value: Value) {
    this.value = value
  }

  /**
   * 進行中
   */
  static get inProgress(): EventStatus {
    return new EventStatus("IN_PROGRESS")
  }

  static get untreated(): EventStatus {
    return new EventStatus("UNTREATED")
  }

  static get done(): EventStatus {
    return new EventStatus("DONE")
  }

  get isNotDone(): boolean {
    return this.value !== "DONE"
  }

  get name(): string {
    if (this.value === "IN_PROGRESS") {
      return "処理中"
    }

    if (this.value === "UNTREATED") {
      return "未処理"
    }

    return "完了"
  }

  get scene(): string {
    if (this.value === "IN_PROGRESS") {
      return ""
    }

    if (this.value === "UNTREATED") {
      return ""
    }

    return ""
  }
}
