import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.addClass)
  }, [
    (_ctx.isLoggingIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "読み込み中..."))
      : _createCommentVNode("", true),
    (!_ctx.isLoggingIn)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      : _createCommentVNode("", true)
  ], 2))
}