import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "register" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "head" }
const _hoisted_4 = { class: "registerList" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["type", "placeholder"]
const _hoisted_7 = { class: "commitList" }
const _hoisted_8 = { class: "exList" }
const _hoisted_9 = { href: "/passwordreset" }
const _hoisted_10 = { href: "/signup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.localizationText("app_name")), 1)
    ]),
    _createElementVNode("main", null, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.localizationText("login")), 1),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              placeholder: _ctx.localizationText('email_placeholder')
            }, null, 8, _hoisted_5), [
              [_vModelText, _ctx.email]
            ])
          ]),
          _createElementVNode("li", null, [
            _withDirectives(_createElementVNode("input", {
              class: "passwordDisplay",
              type: _ctx.inputType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              placeholder: _ctx.localizationText('password_placheholder')
            }, null, 8, _hoisted_6), [
              [_vModelDynamic, _ctx.password]
            ]),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showToggle && _ctx.showToggle(...args)))
            }, "表示/非表示")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.signin && _ctx.signin(...args)))
          }, _toDisplayString(_ctx.localizationText("login")), 1)
        ]),
        _createElementVNode("ul", _hoisted_8, [
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.localizationText("forgot_password")), 1)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.localizationText("link_signup")), 1)
          ])
        ])
      ])
    ])
  ]))
}