
import { defineComponent, ref, computed, ComputedRef, watch } from "vue"
import EventEntity from "@/domain/entities/event.entity"
import EventStatus from "@/domain/valueObjects/eventStatus"
import WorkingHourSelect from "@/components/WorkingHourSelect.vue"
import TagSelect from "@/components/TagSelect.vue"
import DateTime from "@/domain/valueObjects/dateTime"
import TagItem from "@/components/TagItem.vue"
import TagEntity from "@/domain/entities/tag.entity"
import Id from "@/domain/valueObjects/id"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"
import { useTagStore } from "@/stores/tag"
import { useWorkTimeSelectModal } from "@/stores/workTimeSelectModal"
import { useTagSelectModalStore } from "@/stores/tagSelectModal"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TaskCreate",
  components: {
    WorkingHourSelect,
    TagItem,
    TagSelect,
  },
  emits: ["taskAdded"],
  setup(_, { emit }) {
    const userStore = useUserStore()

    const eventStore = useEventStore()

    const tagStore = useTagStore()

    const addTimeMode = ref<boolean>(false)
    const addDateMode = ref<boolean>(false)
    const showStartDate = ref<boolean>(false)
    // const modalOpen = ref<boolean>(false)

    const workTimeSelectModalStore = useWorkTimeSelectModal()
    const workTimeSelectModalIsOpen = computed(
      () => workTimeSelectModalStore.isOpen && workTimeSelectModalStore.openFrom === "TaskEdit",
    )

    // taskIdに該当するタスクを検索する
    const task: ComputedRef = computed<EventEntity | null>(() => {
      return eventStore.selectedTaskReactive
    })

    // 選択しているタグのID（複数）
    const selectedTagIds = ref<string[]>(task?.value?.tagIds?.map((id: any) => id.value) ?? [])

    // タグの削除（保存はしない）
    const onTagDelete = (tag: TagEntity) => {
      selectedTagIds.value = selectedTagIds.value.filter((id: string) => id !== tag.id.value)
    }

    // 全タグのバリエーション
    const tags = computed(() => tagStore.tags)

    const mouseOverTag = ref<TagEntity | null>(null)

    const taskTags = computed(() => {
      return selectedTagIds.value
        .map((tagId) => {
          return tags.value.find((tag: any) => tag.id.value == tagId)
        })
        .filter((tag): tag is TagEntity => {
          return tag !== undefined
        })
    })

    // 選択している開始日
    const selectedStartDate = ref<string>(task.value?.date?.toTextJoinHyphen() ?? "")
    if (selectedStartDate.value !== "") {
      addDateMode.value = true
    }

    // 選択している期限日
    const selectedDeadLineDate = ref<string>(task.value?.deadlineDate?.toTextJoinHyphen() ?? "")

    // 開始日と期限日が同じで、期限日が削除された場合、開始日も削除
    watch(selectedDeadLineDate, (_, oldValue) => {
      if (oldValue === selectedStartDate.value) {
        selectedStartDate.value = ""
      }
    })

    // 選択している終了時間
    const selectedDeadLineTime = ref<string>(task.value?.deadlineDate?.toTextStartTime() ?? "")
    if (selectedDeadLineTime.value === "00:00") {
      selectedDeadLineTime.value = ""
    }

    // 選択している開始時間
    const selectedStartTime = ref<string>(task.value?.date?.toTextStartTime() ?? "")
    if (selectedStartTime.value === "00:00" && selectedDeadLineTime.value === "00:00") {
      selectedStartTime.value = ""
    }

    const selectedCompletedAt = ref<string>(task.value?.completedAt?.toTextJoinHyphen() ?? "")

    const isCompleted = computed(() => {
      return task.value?.isDone
    })

    const isSameStartEndDate = computed(() => {
      console.log("called comp!!!!")
      console.log(selectedStartDate.value)
      console.log(selectedDeadLineDate.value)
      return selectedStartDate.value === selectedDeadLineDate.value
    })

    const isDateSelected = computed(() => {
      return selectedStartDate.value !== "" && selectedDeadLineDate.value !== ""
    })

    if (isDateSelected && selectedStartDate.value === selectedDeadLineDate.value) {
      addDateMode.value = false
    }

    if (selectedStartTime.value !== "" && selectedDeadLineTime.value !== "") {
      addTimeMode.value = true
    }

    const onChangeTimeRange = () => {
      if (selectedStartTime.value !== "" && selectedDeadLineTime.value !== "") {
        const startTime = new Date()
        startTime.setHours(Number(selectedStartTime.value.split(":")[0]))
        startTime.setMinutes(Number(selectedStartTime.value.split(":")[1]))
        startTime.setSeconds(0)
        const endTime = new Date()
        endTime.setHours(Number(selectedDeadLineTime.value.split(":")[0]))
        endTime.setMinutes(Number(selectedDeadLineTime.value.split(":")[1]))
        endTime.setSeconds(0)
        const diff = endTime.getTime() - startTime.getTime()
        workingMinutes.value = Math.floor(diff / (60 * 1000)).toString() // 48
      }
    }

    const onRemoveEvent = () => {
      const response = confirm(localizationText("delete_confirm"))
      if (!response) {
        return
      }
      eventStore.removeEvent(task.value)
      eventStore.setSelectedTask(null)
    }

    // 選択しているステータス
    const selectedStatus = ref<EventStatus>(task.value?.status ?? EventStatus.untreated)

    // タスクの稼働時間
    const workingMinutes = ref<string>(task.value?.workingMinutes?.toString() ?? "")

    const hasInputWorkingMinutes = computed(() => {
      return workingMinutes.value !== ""
    })

    // タスクの名前
    const taskName = ref(task.value?.name ?? "")

    // タスクのメモ
    const memo = ref(task.value?.memo ?? "")

    const editing = ref<boolean>(true)

    const taskEditEnd = ref<boolean>(false)

    const startEdit = () => {
      editing.value = true
    }

    const copyTask = () => {
      if (task.value === null) return
      const newEvent = task.value.duplicate()
      eventStore.writeEvent(newEvent)
    }

    // タスクを追加する
    const addTask = async () => {
      if (workingMinutes.value === "") {
        workingMinutes.value = "0"
      }
      const numTime = Number(workingMinutes.value)

      const isTimeSelected = selectedStartTime.value !== "" && selectedDeadLineTime.value !== ""

      if (isNaN(numTime)) {
        window.alert(localizationText("task_edit_error1"))
        return
      }

      if (selectedStartTime.value === "" && selectedDeadLineTime.value !== "") {
        window.alert(localizationText("task_edit_error2"))
        return
      }

      if (selectedStartTime.value !== "00:00" && selectedDeadLineTime.value === "") {
        window.alert(localizationText("task_edit_error3"))
        return
      }

      if (selectedStartTime.value !== "" && selectedStartDate.value === "") {
        window.alert(localizationText("task_edit_error4"))
        return
      }

      if (selectedDeadLineTime.value !== "" && selectedDeadLineDate.value === "") {
        window.alert(localizationText("task_edit_error5"))
      }

      if (selectedStartDate.value !== "" && isTimeSelected) {
        if (selectedStartTime.value === selectedDeadLineTime.value) {
          window.alert(localizationText("task_edit_error6"))
          return
        }
        const [startTimeHours, startTimeMinutes] = selectedStartTime.value
          .split(":")
          .map((t) => parseInt(t))
        const startTimeValue = startTimeHours * 60 + startTimeMinutes
        const [endTimeHours, endTimeMinutes] = selectedDeadLineTime.value
          .split(":")
          .map((t) => parseInt(t))
        const endTimeValue = endTimeHours * 60 + endTimeMinutes
        if (startTimeValue >= endTimeValue) {
          window.alert(localizationText("task_edit_error6"))
          return
        }
      }

      // 開始・終了時間が入力されている場合、開始日は期限日に合わせる
      if (isTimeSelected) {
        selectedStartDate.value = selectedDeadLineDate.value
      }

      // 開始・終了時間が入力されている場合、稼働時間は開始・終了時間からの算出値とする
      if (isTimeSelected) {
        const [startTimeHours, startTimeMinutes] = selectedStartTime.value
          .split(":")
          .map((t) => parseInt(t))
        const startTimeValue = startTimeHours * 60 + startTimeMinutes
        const [endTimeHours, endTimeMinutes] = selectedDeadLineTime.value
          .split(":")
          .map((t) => parseInt(t))
        const endTimeValue = endTimeHours * 60 + endTimeMinutes
        workingMinutes.value = (endTimeValue - startTimeValue).toString()
      }

      if (selectedStartTime.value !== "" && isTimeSelected) {
        const startValue = parseInt(selectedStartTime.value.split(":").join(""))
        const endValue = parseInt(selectedDeadLineTime.value.split(":").join(""))
        if (startValue > endValue) {
          window.alert(localizationText("task_edit_error6"))
          return
        }
      }

      const tagIds = selectedTagIds.value.map((tagId) => new Id(tagId))
      const date =
        selectedStartDate.value === ""
          ? null
          : DateTime.fromText(selectedStartDate.value, selectedStartTime.value)
      const deadlineDate =
        selectedDeadLineDate.value === ""
          ? selectedStartDate.value === ""
            ? null
            : DateTime.fromText(selectedStartDate.value, selectedStartTime.value)
          : DateTime.fromText(selectedDeadLineDate.value, selectedDeadLineTime.value)
      const completedAt =
        selectedCompletedAt.value === "" ? null : DateTime.fromText(selectedCompletedAt.value)
      if (task.value === null) {
        return
      }

      // 開始時間・終了時間が設定されていない場合の、タスクの最大稼働時間は3時間とする
      if (!isTimeSelected && parseInt(workingMinutes.value) > 180) {
        workingMinutes.value = "180"
      }
      const eventEntity = task.value
        .updateName(taskName.value)
        .updateTagIds(tagIds)
        .updateDate(date)
        .updateDeadlineDate(deadlineDate)
        .updateMemo(memo.value)
        .updateWorkingMinutes(parseInt(workingMinutes.value))
        .updateComptedAt(completedAt)
        .updateDeathCount(0) // 更新時はdeathCountを0にする
      await eventStore.writeEvent(eventEntity)
      editing.value = false
      emit("taskAdded")
    }

    const appleClass = computed(() => {
      const appleCount = Math.ceil(Number(workingMinutes.value) / 30)
      // 変更保存前の稼働時間の設定からリンゴを更新できるよう変更
      if (appleCount === 1) return "t05"
      if (appleCount === 2) return "t10"
      if (appleCount === 3) return "t15"
      if (appleCount === 4) return "t20"
      if (appleCount === 5) return "t25"
      if (appleCount === 6) return "t30"
      if (appleCount > 6) return "t30"
    })

    const onOpenModal = (from: string) => {
      workTimeSelectModalStore.open(from)
      // modalOpen.value = true
    }

    const onModalChancel = () => {
      workTimeSelectModalStore.close()
      // modalOpen.value = false
    }

    const onModalSubmit = (minutes: string) => {
      // modalOpen.value = false
      workTimeSelectModalStore.close()
      workingMinutes.value = minutes
    }

    const isTagMouseOver = (tag: TagEntity) => {
      return mouseOverTag.value?.id === tag.id
    }

    const onTagMouseOver = (tag: TagEntity) => {
      mouseOverTag.value = tag
      // isTagMouseOver.value = true
    }

    const onTagMouseLeave = () => {
      mouseOverTag.value = null
      // isTagMouseOver.value = false
    }

    const toggleAddTimeMode = () => {
      addTimeMode.value = !addTimeMode.value
      if (addTimeMode.value && selectedStartTime.value === "00:00") {
        selectedStartTime.value = "12:00"
      }
    }

    const setAllDay = () => {
      selectedStartTime.value = "00:00"
      selectedDeadLineTime.value = ""
      addTimeMode.value = false
    }

    const toggleAddDateMode = () => {
      addDateMode.value = !addDateMode.value
    }

    const changeDeadlineDate = () => {
      console.log("soon", selectedDeadLineDate.value)
      if (!isSameStartEndDate) {
        console.log(selectedStartDate.value)
        console.log(selectedDeadLineDate.value)
        selectedStartDate.value = selectedDeadLineDate.value
        return
      }
      // 期限日を選択したタイミングで、開始日が設定されていなければ、開始日=期限日に
      if (selectedStartDate.value === "") {
        selectedStartDate.value = selectedDeadLineDate.value
      }
    }

    // 開始時間の変更
    const changeStartTime = () => {
      if (selectedDeadLineTime.value !== "") {
        return
      }
      let endTime = selectedStartTime.value.split(":")
      endTime[0] = String(parseInt(endTime[0]) + 1).padStart(2, "0")
      if (parseInt(endTime[0]) < 24) {
        selectedDeadLineTime.value = endTime.join(":")
      }
      onChangeTimeRange()
    }

    const tagSelectModalStore = useTagSelectModalStore()
    const tagSelectModalIsOpen = computed(() => tagSelectModalStore.isOpen)

    // const tagSelectModalOpen = ref<boolean>(false)
    const onTagSelectOpen = () => {
      // tagSelectModalOpen.value = true
      tagSelectModalStore.open()
    }
    const onTagSelectClose = () => {
      // tagSelectModalOpen.value = false
      tagSelectModalStore.close()
    }

    const onTagSelectEnd = (tagIds: string[]) => {
      selectedTagIds.value = tagIds
      // tagSelectModalOpen.value = false
      tagSelectModalStore.close()
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      isDateSelected,
      // modalOpen,
      workTimeSelectModalIsOpen,
      editing,
      selectedStartDate,
      selectedStartTime,
      selectedDeadLineDate,
      selectedCompletedAt,
      isCompleted,
      selectedDeadLineTime,
      onChangeTimeRange,
      selectedStatus,
      tags,
      mouseOverTag,
      task,
      workingMinutes,
      hasInputWorkingMinutes,
      taskTags,
      taskName,
      memo,
      copyTask,
      addTask,
      startEdit,
      selectedTagIds,
      onRemoveEvent,
      appleClass,
      onOpenModal,
      onModalChancel,
      onModalSubmit,
      isTagMouseOver,
      onTagMouseOver,
      onTagMouseLeave,
      onTagDelete,
      addTimeMode,
      toggleAddTimeMode,
      setAllDay,
      addDateMode,
      toggleAddDateMode,
      showStartDate,
      changeDeadlineDate,
      changeStartTime,
      // tagSelectModalOpen,
      tagSelectModalIsOpen,
      onTagSelectOpen,
      onTagSelectClose,
      onTagSelectEnd,
      taskEditEnd,
      isSameStartEndDate,
      localizationText,
    }
  },
})
