
import { defineComponent } from "vue"

export default defineComponent({
  name: "Tester",
  props: {
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const onDragStart = () => {
      console.log("dragstart")
    }
    const onDrag = () => {
      console.log("onDrag")
    }
    const onDrop = () => {
      console.log("onDrop")
      window.alert("ドロップされました")
    }
    const onDragLeave = () => {
      console.log("onDragLeave")
    }
    return {
      onDragStart,
      onDrag,
      onDrop,
      onDragLeave,
    }
  },
})
