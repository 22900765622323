
import { defineComponent, ref, computed } from "vue"
import { useUserStore } from "@/stores/user"
import { useProfileModalStore } from "@/stores/profileModal"
import { useRouter } from "vue-router"
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage"
import DayOfWeek, {
  dayOfWeekNum,
  dayOfWeekStr,
  dayOfWeekStrEn,
} from "@/domain/valueObjects/dayOfWeek"
import DateTime from "@/domain/valueObjects/dateTime"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "UserProfile",
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const preview = ref()
    const uid = computed(() => userStore.uid)
    const userEntity = computed(() => userStore.entity!)
    const newImageUrl = ref(JSON.parse(JSON.stringify(userEntity.value.imageUrl)))
    const hasImage = computed(() => newImageUrl.value !== "")
    const newUserName = ref(JSON.parse(JSON.stringify(userEntity.value.name)))
    const newWorkingDayOfWeek = ref(
      JSON.parse(JSON.stringify(userEntity.value.workingDayOfWeek)).map(
        (dayOfWeek: any) => dayOfWeek.value,
      ),
    )
    const newWorkingHoursPerDay = ref(
      Number(JSON.parse(JSON.stringify(userEntity.value.workingMinutesPerDay))) / 60,
    )
    const newEndDateYear = ref(Number(userEntity.value.endDate.toDate().getFullYear()))
    const newEndDateMonth = ref((userEntity.value.endDate.toDate().getMonth() + 1).toString())
    const test = (dayNum: number) => {
      if (newWorkingDayOfWeek.value.includes(dayNum)) {
        newWorkingDayOfWeek.value = newWorkingDayOfWeek.value.filter((d: number) => d !== dayNum)
      } else {
        newWorkingDayOfWeek.value.push(dayNum)
      }
      if (newWorkingDayOfWeek.value.length) {
        newWorkingDayOfWeek.value.sort()
      }
    }
    const uploadFile = async () => {
      const file = preview.value.files![0]
      if (!["jpg", "JPG", "png", "PNG"].includes(file.name.split(".").pop())) {
        alert("画像ファイルを選択してください。")
        return null
      }
      if (1024 * 1024 * 5 < file.size) {
        alert("ファイルサイズが5MBを超えています。")
        return null
      }
      newImageUrl.value = URL.createObjectURL(file)
      const fileRef = storageRef(getStorage(), `users/${uid.value}`)
      await uploadBytes(fileRef, file)
      newImageUrl.value = await getDownloadURL(fileRef)
    }
    // TODO:ここで画像のアップロードもするようにするよ
    const updateUser = async () => {
      if (newUserName.value === "") {
        alert("ユーザー名を入力してください。")
        return null
      }
      if (newUserName.value.length > 15) {
        alert("ユーザー名は15文字までで設定してください。")
        return null
      }
      if (newWorkingDayOfWeek.value.length === 0) {
        alert("稼働日を設定してください。")
        return null
      }
      try {
        const workingDayOfWeek = newWorkingDayOfWeek.value.map((dayNum: number) =>
          DayOfWeek.fromNumber(dayNum),
        )
        const endDate = DateTime.fromNumbers(
          newEndDateYear.value,
          parseInt(newEndDateMonth.value),
          1,
        )
        const newUser = userEntity.value
          .updateName(newUserName.value)
          .updateLanguage(newLanguage.value)
          .updateWorkingDayOfWeek(workingDayOfWeek)
          .updateWorkingMinutesPerDay(newWorkingHoursPerDay.value * 60)
          .updateImageUrl(newImageUrl.value)
          .updateEndDate(endDate)
        await userStore.updateUser(newUser)
      } catch (error) {
        console.error("error", error)
        router.replace({ name: "Error" })
      }
    }
    const goDashboard = () => router.replace({ name: "DashBoard" })

    const getYearOptions = (addYear: number) => {
      return new Date().getFullYear() + addYear
    }

    const getYearOptionsDisplay = (addYear: number) => {
      if (addYear === 0) {
        if (userStore.entity?.language === "ja") {
          return `${new Date().getFullYear() + addYear}年（今年）`
        }
        if (userStore.entity?.language === "en") {
          return `${new Date().getFullYear() + addYear}（this year）`
        }
      }
      return `${new Date().getFullYear() + addYear}（${addYear}years later）`
    }

    const isSelectedYear = (year: number) => newEndDateYear.value === year
    const isSelectedMonth = (month: string) => newEndDateMonth.value === month

    const modalStore = useProfileModalStore()
    const closeModal = () => modalStore.close()

    const newLanguage = ref(JSON.parse(JSON.stringify(userEntity.value.language)))
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      preview,
      newImageUrl,
      hasImage,
      newUserName,
      userEntity,
      uploadFile,
      updateUser,
      newWorkingDayOfWeek,
      newWorkingHoursPerDay,
      test,
      dayOfWeekNum,
      dayOfWeekStr,
      dayOfWeekStrEn,
      goDashboard,
      getYearOptions,
      getYearOptionsDisplay,
      newEndDateYear,
      newEndDateMonth,
      isSelectedYear,
      isSelectedMonth,
      closeModal,
      newLanguage,
      localizationText,
    }
  },
})
