
import { computed, defineComponent, ref, onBeforeUnmount, watch } from "vue"
import { useRoute } from "vue-router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useUserStore } from "@/stores/user"
import { useTagStore } from "@/stores/tag"
import { useEventStore } from "@/stores/event"
import { useTagEditModalStore } from "./stores/tagEditModal"
import { useWorkTimeSelectModal } from "./stores/workTimeSelectModal"
import { useTagSelectModalStore } from "./stores/tagSelectModal"
import { useTaskStore } from "@/stores/task"

export default defineComponent({
  setup() {
    // ログイン状態の確認中
    const isLoggingIn = ref(true)
    const route = useRoute()
    const userStore = useUserStore()
    const tagStore = useTagStore()
    const eventStore = useEventStore()
    const taskStore = useTaskStore()
    watch(route, () => {
      window.document.title = route.meta.title + ""
    })

    onAuthStateChanged(getAuth(), async (user) => {
      if (user !== null) {
        userStore.startSubscribe()
        userStore.setUid(user?.uid)
        tagStore.readAllTags({ userId: user?.uid })
        eventStore.readAllTasks({ userId: user?.uid })
        eventStore.readNoDateTasks({ userId: user?.uid })
        eventStore.handleDeadTasks({ userId: user?.uid })
        // new Store
        taskStore.subscribeHasDateTasks({ userId: user?.uid })
        taskStore.subscribeHolidays({ userId: user?.uid })
      }
      isLoggingIn.value = false
    })

    onBeforeUnmount(() => {
      eventStore.unsubscribeAllTasks()
      eventStore.unsubscribeNoDateTasks()
      userStore.stopSubscribe()
    })
    const tagEditModalStore = useTagEditModalStore()
    const workTimeSelectModal = useWorkTimeSelectModal()
    const tagSelectModalStore = useTagSelectModalStore()

    const selectedRange = computed(() => eventStore.selectedTaskRange)
    const selectedTag = computed(() => eventStore.selectedTag)
    const tags = computed(() => tagStore.tags)

    const addClass = computed(() => {
      const classes = []
      const hasSelectedTag = selectedTag.value !== null
      // モーダルの開閉状態をクラスで表現
      if (tagEditModalStore.isOpen || workTimeSelectModal.isOpen || tagSelectModalStore.isOpen) {
        classes.push("editWindowOpen")
      }
      // aaa
      if (!hasSelectedTag && selectedRange.value === "TODAY") {
        classes.push("today")
      }
      if (!hasSelectedTag && selectedRange.value === "TOMORROW") {
        classes.push("tomorrow")
      }
      if (!hasSelectedTag && selectedRange.value === "THIS_WEEK") {
        classes.push("thisWeek")
      }
      if (!hasSelectedTag && selectedRange.value === "NEXT_WEEK") {
        classes.push("nextWeek")
      }
      if (!hasSelectedTag && selectedRange.value === "THIS_MONTH") {
        classes.push("thisMonth")
      }
      if (!hasSelectedTag && selectedRange.value === "NEXT_MONTH") {
        classes.push("nextMonth")
      }
      if (!hasSelectedTag && selectedRange.value === "AFTER_TOADY") {
        classes.push("all")
      }
      if (!hasSelectedTag && selectedRange.value === "COMPLETED") {
        classes.push("done")
      }
      if (!hasSelectedTag && selectedRange.value === "SOMEDAY") {
        classes.push("someday")
      }
      if (selectedTag.value !== null) {
        const index = tags.value.findIndex((tag) => tag.id === selectedTag.value!.id)
        classes.push(`tag_${index + 1}`)
      }
      return classes.join(" ")
    })

    return { isLoggingIn, addClass }
  },
})
