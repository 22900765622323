import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore"
import UserEntity from "@/domain/entities/user.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import DayOfWeek from "@/domain/valueObjects/dayOfWeek"
import { FirestoreUser } from "../types/firestoreUser"

export default class UserConverter {
  static toFirestore(user: UserEntity): DocumentData {
    return {
      name: user.name,
      workingDayOfWeek: user.workingDayOfWeek.map((a) => a.value),
      workingMinutesPerDay: user.workingMinutesPerDay,
      createdAt: user.createdAt,
      updatedAt: new Date(),
      imageUrl: user.imageUrl,
      startedAt: user.startedAt ? user.startedAt.toDate() : null,
      endDate: user.endDate ? user.endDate.toDate() : DateTime.today().addTime(3.154e11).toDate(),
      language: user.language ?? "en",
    }
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot): UserEntity {
    const data = snapshot.data() as FirestoreUser

    return new UserEntity({
      name: data.name,
      workingDayOfWeek: data.workingDayOfWeek.map((weekDayNum) => new DayOfWeek(weekDayNum)),
      workingMinutesPerDay: data.workingMinutesPerDay,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.createdAt.toDate(),
      imageUrl: data.imageUrl,
      startedAt: data.startedAt ? new DateTime(data.startedAt.toDate()) : null,
      endDate: data.endDate
        ? new DateTime(data.endDate.toDate())
        : DateTime.today().addTime(3.154e11),
      language: data.language ?? "en",
    })
  }
}
