import { defineStore } from "pinia"
import TagEntity from "@/domain/entities/tag.entity"
import TagConverter from "@/infrastructure/converters/tag.converter"
import TaskConverter from "@/infrastructure/converters/task.converter"
import Id from "@/domain/valueObjects/id"
import {
  getFirestore,
  collection,
  query,
  getDocs,
  writeBatch,
  doc,
  setDoc,
  arrayRemove,
  deleteDoc,
  where,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore"

type State = {
  _tags: TagEntity[]
  unsubscribeAllTagsFunc: Unsubscribe | null
}

export const useTagStore = defineStore("tag", {
  state: (): State => {
    return {
      _tags: [],
      unsubscribeAllTagsFunc: null,
    }
  },
  getters: {
    /**
     * ソートされたタグ
     */
    tags(state) {
      console.log("state.tags", state._tags)
      return state._tags?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
    },
    findTag(state: State) {
      return (tagId: Id) => {
        return state._tags.find((tag) => tag.id.value === tagId.value) ?? null
      }
    },
  },
  actions: {
    /**
     * タグを取得する
     */
    async readAllTags(value: { userId: string }) {
      try {
        // 既にサブスクが存在する
        this.unsubscribeAllTagsFunc
        if (this.unsubscribeAllTagsFunc !== null) {
          return
        }
        // エンティティに変換される
        const ref = collection(getFirestore(), "users", value.userId, "tags").withConverter(
          TagConverter,
        )
        const q = query(ref)
        // eslint-disable-next-line
        const self = this
        const unsubscribe = onSnapshot(q, (querySnap) => {
          const tags = querySnap.docs.map((docSnap) => docSnap.data())
          console.log("tags", tags)
          self._tags = tags
        })
        this.unsubscribeAllTagsFunc = unsubscribe
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    /**
     * 購読を解除する
     */
    async unsubscribeAllTags() {
      try {
        if (this.unsubscribeAllTags === null) {
          return
        }
        this.unsubscribeAllTags()
        this.unsubscribeAllTagsFunc = null
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async writeTag(tag: TagEntity) {
      try {
        const docRef = doc(
          getFirestore(),
          "users",
          tag.userId.value,
          "tags",
          tag.id.value,
        ).withConverter(TagConverter)
        await setDoc(docRef, tag)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async deleteTag(tagId: Id) {
      try {
        const tag = this._tags.find((tag) => {
          return tag.id.value === tagId.value
        })
        if (typeof tag === "undefined") {
          return
        }
        const docRef = doc(
          getFirestore(),
          "users",
          tag.userId.value,
          "tags",
          tag.id.value,
        ).withConverter(TagConverter)
        await deleteDoc(docRef)
        // タグを含むイベントを取得する
        const tagEventsRef = query(
          collection(getFirestore(), "users", tag.userId.value, "tasks"),
          where("tagIds", "array-contains", tag.id.value),
        ).withConverter(TaskConverter)
        // タグを含むイベントからタグを削除する
        const batch = writeBatch(getFirestore())
        const tagEventsQuerySnap = await getDocs(tagEventsRef)
        for (const tagEventSnap of tagEventsQuerySnap.docs) {
          batch.update(tagEventSnap.ref, { tagIds: arrayRemove(tag.id.value) })
        }
        await batch.commit()
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
})
