import Id from "../valueObjects/id"
import EventStatus from "../valueObjects/eventStatus"
import EventType from "../valueObjects/eventType"
import DateTime from "../valueObjects/dateTime"

type Props = {
  id: Id
  userId: Id
  type: EventType
  date: DateTime | null
  deadlineDate: DateTime | null
  completedAt: DateTime | null
  name: string // 名前
  workingMinutes: number // かかる時間
  status: EventStatus // 処理中とか
  memo: string
  tagIds: Id[]
  createdAt: Date
  deathCount: number
}

/**
 * カレンダーのイベント
 * イベントは休日とタスクを含む
 */
export default class EventEntity {
  readonly id!: Id

  readonly userId!: Id

  readonly type!: EventType

  /**
   * やる日付
   */
  readonly date!: DateTime | null

  readonly deadlineDate!: DateTime | null

  readonly completedAt!: DateTime | null

  readonly name!: string

  readonly status!: EventStatus

  readonly memo!: string

  readonly workingMinutes!: number

  /**
   * タグ
   */
  readonly tagIds!: Id[]

  readonly createdAt!: Date

  readonly deathCount!: number

  get isDone(): boolean {
    return this.status.value === EventStatus.done.value
  }

  get isNotDone(): boolean {
    return this.status.value !== EventStatus.done.value
  }

  get hasWorkingTime(): boolean {
    return this.workingMinutes !== 0
  }

  get applesCount(): number {
    return Math.ceil(this.workingMinutes / 30)
  }

  static createTask(props: {
    name: string
    tagIds: Id[]
    userId: string
    memo: string
    date: DateTime | null
    deadlineDate: DateTime | null
    workingMinutes: number
  }): EventEntity {
    return new EventEntity({
      id: Id.create(),
      userId: new Id(props.userId),
      date: props.date,
      deadlineDate: props.deadlineDate ?? props.date,
      completedAt: null,
      name: props.name,
      memo: props.memo,
      workingMinutes: props.workingMinutes,
      status: EventStatus.untreated,
      tagIds: props.tagIds,
      createdAt: new Date(),
      type: EventType.task,
      deathCount: 0,
    })
  }

  static createHoliday(props: { date: DateTime; userId: string }): EventEntity {
    return new EventEntity({
      id: Id.create(),
      userId: new Id(props.userId),
      date: props.date,
      deadlineDate: null,
      completedAt: null,
      name: "default",
      memo: "",
      workingMinutes: 0,
      status: EventStatus.untreated,
      tagIds: [],
      createdAt: new Date(),
      type: EventType.holiday,
      deathCount: 0,
    })
  }

  constructor(readonly props: Props) {
    Object.assign(this, props)
  }

  updateDate(date: DateTime | null): EventEntity {
    return new EventEntity({ ...this.props, date })
  }

  updateDeadlineDate(deadlineDate: DateTime | null): EventEntity {
    return new EventEntity({ ...this.props, deadlineDate })
  }

  updateComptedAt(completedAt: DateTime | null): EventEntity {
    return new EventEntity({ ...this.props, completedAt })
  }

  updateName(name: string): EventEntity {
    return new EventEntity({ ...this.props, name })
  }

  updateWorkingMinutes(workingMinutes: number): EventEntity {
    return new EventEntity({ ...this.props, workingMinutes })
  }

  // markStatusAsDone() {
  //   return new TaskEntity({ ...this.props, status: TaskStatus.done });
  // }

  updateStatus(status: EventStatus): EventEntity {
    return new EventEntity({ ...this.props, status })
  }

  updateTagIds(tagIds: Id[]): EventEntity {
    return new EventEntity({ ...this.props, tagIds })
  }

  addTagId(tagId: Id): EventEntity {
    const index = this.tagIds.findIndex((id) => {
      return id.value === tagId.value
    })
    if (index !== -1) {
      return this
    }
    return new EventEntity({
      ...this.props,
      tagIds: [...this.tagIds, tagId],
    })
  }

  updateMemo(memo: string): EventEntity {
    if (memo.length > 999) {
      throw new Error("メモは512文字以内です")
    }
    return new EventEntity({ ...this.props, memo })
  }

  updateDeathCount(deathCount: number): EventEntity {
    return new EventEntity({ ...this.props, deathCount })
  }

  /**
   * 完了としてマークする
   * 既に完了の場合は未着手に戻す
   */
  switchStatus(): EventEntity {
    if (this.status.value === EventStatus.untreated.value) {
      return new EventEntity({
        ...this.props,
        status: EventStatus.done,
        completedAt: DateTime.now(),
      })
    }

    return new EventEntity({
      ...this.props,
      status: EventStatus.untreated,
      completedAt: null,
    })
  }

  resetDate(): EventEntity {
    return new EventEntity({
      ...this.props,
      date: DateTime.now(),
    })
  }

  /**
   * タスクの開始日を今日に設定する
   */
  moveToToday(): EventEntity {
    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 今日/今日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = DateTime.now()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 今日/今日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = DateTime.now()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 今日/今日
     */
    if (this.date !== null && this.deadlineDate === null) {
      const date = DateTime.now()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/あり
     * VALUE:開始日/期限日 => 今日/差分
     */
    if (this.date !== null && this.deadlineDate !== null) {
      const date = DateTime.now()
      const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
      const deadlineDate = date.addTime(diff)
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    return this
  }

  /**
   * タスクの開始日を明日に設定する
   * ※今日から見ての明日
   */
  moveToTomorrow(): EventEntity {
    /**
     * CASE: 開始日/期限日 => なし/なし
     * VALUE:開始日/期限日 => 明日/明日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = DateTime.now().toNextDay()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 明日/明日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = DateTime.now().toNextDay()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 明日/明日
     */
    if (this.date !== null && this.deadlineDate === null) {
      const date = DateTime.now().toNextDay()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/あり
     * VALUE:開始日/期限日 => 明日/差分
     */
    if (this.date !== null && this.deadlineDate !== null) {
      const date = DateTime.now().toNextDay()
      // 現在の期限日 - 現在の開始日
      const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
      const deadlineDate = date.addTime(diff)
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    return this
  }

  /**
   * タスクの開始日を今週に設定する
   */
  moveToThisWeek(): EventEntity {
    const now = DateTime.now()

    /**
     * CASE: 開始日/期限日 => なし/なし
     * VALUE:開始日/期限日 => 今週の土曜日/今週の土曜日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = now.toWeekEnd()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 今週の金曜日/今週の金曜日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = now.toWeekFriday()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 特殊条件：今週への移動
     */
    if (this.date !== null && this.deadlineDate === null) {
      // 移動前の開始日と現時刻の週の差分
      const dateDiff = this.date.toDate().getTime() - now.toDate().getTime()
      // 移動先の日付が今日より未来の場合(今日含む)
      if (0 <= dateDiff) {
        // 開始日は今週で開始日の曜日
        const date = now.updateWeek(this.date.toDate().getDay())
        // 期限日は開始日と同じ
        const deadlineDate = date
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 移動先の日付が過去の場合
      if (dateDiff < 0) {
        // 開始日は今週の金曜日
        const date = now.toWeekFriday()
        // 期限日は開始日と同じ
        const deadlineDate = date
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
    }

    /**
     * CASE: 開始日/期限日 => あり/あり
     * VALUE:開始日/期限日 => 特殊条件：今週への移動
     * 開始日 あり => 特殊条件：今週への移動
     * 期限日 あり => 特殊条件：今週への移動
     */
    if (this.date !== null && this.deadlineDate !== null) {
      // 移動前の開始日と現時刻の週の差分
      const dateDiff = this.date.toDate().getTime() - now.toDate().getTime()
      // 移動先の日付が今日より未来の場合(今日含む)
      if (0 <= dateDiff) {
        // 開始日は今週で開始日の曜日
        const date = now.updateWeek(this.date.toDate().getDay())
        // 移動前の期限日と移動前の開始日の差分
        const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
        // 期限日は開始日から差分だけ経過した日付
        const deadlineDate = date.addTime(diff)
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 移動先の日付が過去の場合
      if (dateDiff < 0) {
        // 開始日は今週の金曜日
        const date = now.toWeekFriday()
        // 移動前の期限日と移動前の開始日の差分
        const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
        // 期限日は開始日から差分だけ経過した日付
        const deadlineDate = date.addTime(diff)
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
    }

    return this
  }

  /**
   * 開始時刻を来週の月曜日に変更する
   */
  moveToNextWeek(): EventEntity {
    const now = DateTime.now()

    /**
     * CASE: 開始日/期限日 => なし/なし
     * VALUE:開始日/期限日 => 来週の金曜日/来週の金曜日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = now.toNextWeek()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 来週の金曜日/来週の金曜日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = now.toNextWeek().toWeekFriday()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 来週の同曜日/来週の同曜日
     */
    if (this.date !== null && this.deadlineDate === null) {
      const date = now.toNextWeek().updateWeek(this.date.toDate().getDay())
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/あり
     * VALUE:開始日/期限日 => 来週の同曜日/差分
     */
    if (this.date !== null && this.deadlineDate !== null) {
      const date = now.toNextWeek().updateWeek(this.date.toDate().getDay())
      // this.date (移動前) - date (移動後)
      const diff = Math.abs(this.date.toDate().getTime() - date.toDate().getTime())
      // 現在の期限日に差分を足す
      const deadlineDate = this.deadlineDate.addTime(diff)
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    return this
  }

  /**
   * 今月に移動する
   */
  moveToThisMonth(): EventEntity {
    const now = DateTime.now()

    /**
     * CASE: 開始日/期限日 => なし/なし
     * VALUE:開始日/期限日 => 今月の最終日/今月の最終日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = now.toMonthEnd()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 今月の最終日/今月の最終日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = now.toMonthEnd()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 特殊条件：今月への移動
     */
    if (this.date !== null && this.deadlineDate === null) {
      // 今月の最終日（31日など）
      const monthEnd = now.toMonthEnd()
      // 移動前の開始日が今月の最終日より未来の場合
      if (monthEnd.toDate().getTime() < this.date.toDate().getTime()) {
        // 開始日は今月の最終日
        const date = monthEnd
        // 期限日は開始日と同じ
        const deadlineDate = date
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 開始日は今月の移動前の日付
      const date = now.updateDate(this.date.toDate().getDate())
      // 期限日は開始日と同じ
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/あり
     * VALUE:開始日/期限日 =>
     */
    if (this.date !== null && this.deadlineDate !== null) {
      // 今月の最終日（31日など）
      const monthEnd = now.toMonthEnd()
      // 移動前の開始日が今月の最終日より未来の場合
      if (monthEnd.toDate().getTime() < this.date.toDate().getTime()) {
        // 開始日は今月の最終日
        const date = monthEnd
        // 移動前の期限日と移動前の開始日の差分
        const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
        // 期限日は開始日から差分だけ経過した日付
        const deadlineDate = date.addTime(diff)
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 開始日は今月の移動前の日付
      const date = now.updateDate(this.date.toDate().getDate())
      // 移動前の期限日と移動前の開始日の差分
      const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
      // 期限日は開始日から差分だけ経過した日付
      const deadlineDate = date.addTime(diff)
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    return this
  }

  /**
   * 来月への移動
   */
  moveToNextMonth(): EventEntity {
    const now = DateTime.now()

    /**
     * CASE: 開始日/期限日 => なし/なし
     * VALUE:開始日/期限日 => 来月最初の日/来月最初の日
     */
    if (this.date === null && this.deadlineDate === null) {
      const date = now.toNextMonth().toMonthStart()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => なし/あり
     * VALUE:開始日/期限日 => 来月最初の日/来月最初の日
     */
    if (this.date === null && this.deadlineDate !== null) {
      const date = now.toNextMonth().toMonthStart()
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 特殊条件：来月への移動
     */
    if (this.date !== null && this.deadlineDate === null) {
      // 来月の最終日（31日など）
      const monthEnd = now.toNextMonth().toMonthEnd()
      // 移動前の開始日が来月の最終日より未来の場合
      if (monthEnd.toDate().getTime() < this.date.toDate().getTime()) {
        // 開始日は今月の最終日
        const date = monthEnd
        // 期限日は開始日と同じ
        const deadlineDate = date
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 開始日は今月の移動前の日付
      const date = now.updateDate(this.date.toDate().getDate())
      // 期限日は開始日と同じ
      const deadlineDate = date
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    /**
     * CASE: 開始日/期限日 => あり/なし
     * VALUE:開始日/期限日 => 特殊条件：来月への移動
     */
    if (this.date !== null && this.deadlineDate !== null) {
      // 来月の最終日（31日など）
      const monthEnd = now.toNextMonth().toMonthEnd()
      // 移動前の開始日が来月の最終日より未来の場合
      if (monthEnd.toDate().getTime() < this.date.toDate().getTime()) {
        // 開始日は今月の最終日
        const date = monthEnd
        // 移動前の期限日と移動前の開始日の差分
        const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
        // 期限日は開始日から差分だけ経過した日付
        const deadlineDate = date.addTime(diff)
        const deathCount = 0
        return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
      }
      // 開始日は来月の移動前の日付
      const date = monthEnd.updateDate(this.date.toDate().getDate())
      // 移動前の期限日と移動前の開始日の差分
      const diff = Math.abs(this.deadlineDate.toDate().getTime() - this.date.toDate().getTime())
      // 期限日は開始日から差分だけ経過した日付
      const deadlineDate = date.addTime(diff)
      const deathCount = 0
      return new EventEntity({ ...this.props, date, deadlineDate, deathCount })
    }

    return this
  }

  removeDate(): EventEntity {
    return new EventEntity({
      ...this.props,
      date: null,
    })
  }

  removeDeadlineDate(): EventEntity {
    return new EventEntity({
      ...this.props,
      deadlineDate: null,
      deathCount: 0,
    })
  }

  /**
   * 複製する
   */
  duplicate(): EventEntity {
    const isFuture = this.props.date?.isFuture ?? true

    return new EventEntity({
      ...this.props,
      id: Id.create(),
      status: EventStatus.untreated,
      completedAt: null,
      memo: "",
      createdAt: new Date(),
      date: isFuture ? this.props.date : DateTime.now(),
      deathCount: 0,
    })
  }

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 特定の日付に開始を移動可能かの判定群
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  /**
   * taskを今日に移動可能か
   */
  get isMovableToToday(): boolean {
    if (this.date === null) {
      return true
    }

    // if (this.date.isToday || this.date.isThisWeek || this.date.isThisMonth) {
    if (this.date.isToday) {
      return false
    }

    return true
  }

  /**
   * taskを明日に移動可能か
   */
  get isMovableToTomorrow(): boolean {
    if (this.date === null) {
      return true
    }

    // if (this.date.isTomorrow || this.date.isThisWeek || this.date.isThisMonth) {
    if (this.date.isTomorrow) {
      return false
    }

    return true
  }

  /**
   * taskを今週に移動可能か
   */
  get isMovableToThisWeek(): boolean {
    if (this.date === null) {
      return true
    }

    // 今週のタスクを今週に移動することはできない
    if (this.date.isThisWeek) {
      return false
    }

    return true
  }

  /**
   * taskを来週に移動可能か
   */
  get isMovableToNextWeek(): boolean {
    if (this.date === null) {
      return true
    }

    if (this.date.isNextWeek) {
      return false
    }

    return true
  }

  /**
   * taskを今月に移動可能か
   */
  get isMovableToThisMonth(): boolean {
    if (this.date === null) {
      return true
    }

    if (this.date.isThisMonth) {
      return false
    }

    return true
  }

  /**
   * taskを今月に移動可能か
   */
  get isMovableToNextMonth(): boolean {
    if (this.date === null) {
      return true
    }

    if (this.date.isNextMonth) {
      return false
    }

    return true
  }

  /**
   * いつかに移動可能か
   */
  get isMovableToSomeday(): boolean {
    if (this.date === null) {
      return false
    }
    return true
  }
}
