
import { defineComponent, ref } from "vue"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "WorkingHourSelect",
  props: {
    workingMinutes: Number,
  },
  emits: ["cancel", "submit"],
  setup(props, { emit }) {
    const minutes = ref<number>(props.workingMinutes ? props.workingMinutes : 0)
    const onCancel = () => emit("cancel")
    const onSubmit = () => {
      if (minutes.value === 0) {
        alert(localizationText("please_time_select"))
        return
      }
      emit("submit", minutes.value)
    }
    const userStore = useUserStore()
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      minutes,
      onCancel,
      onSubmit,
      localizationText,
    }
  },
})
