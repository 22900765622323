import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "calendar" }
const _hoisted_2 = { class: "submitList center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Calendar, {
        color: "purple",
        attributes: _ctx.holidays,
        onDayclick: _ctx.dayClick,
        "onUpdate:toPage": _ctx.monthUp
      }, null, 8, ["attributes", "onDayclick", "onUpdate:toPage"])
    ]),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          class: "cancel",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeHoliday && _ctx.removeHoliday(...args)))
        }, _toDisplayString(_ctx.localizationText("remove_holiday")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          class: "submit",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addHoliday && _ctx.addHoliday(...args)))
        }, _toDisplayString(_ctx.localizationText("add_holiday")), 1)
      ])
    ])
  ]))
}