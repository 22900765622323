
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRouter } from "vue-router"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"
import { useTagStore } from "@/stores/tag"
import { useTagEditModalStore } from "@/stores/tagEditModal"
import SingleItem from "@/components/SideMenuSingleItem.vue"
import TagEntity from "@/domain/entities/tag.entity"
import TagEdit from "@/views/TagEdit.vue"
import EventEntity from "@/domain/entities/event.entity"
import Id from "@/domain/valueObjects/id"
import { useTaskDisplayStore } from "@/stores/taskDisplay"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "SideMenu",
  components: {
    SingleItem,
    TagEdit,
  },
  setup() {
    const userStore = useUserStore()
    const eventStore = useEventStore()
    const tagStore = useTagStore()
    const router = useRouter()
    const selectedTag = ref<TagEntity | undefined>()
    const tagGroupOpen = ref<boolean>(true)
    const tagEditModalStore = useTagEditModalStore()
    const tagModalOpen = computed(() => tagEditModalStore.isOpen)
    const onTagModalOpen = (tag: TagEntity) => {
      selectedTag.value = tag
      // tagModalOpen.value = true
      tagEditModalStore.open()
    }
    const onTagModalCancel = () => {
      selectedTag.value = undefined
      // tagModalOpen.value = false
      tagEditModalStore.close()
    }
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // new start
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    const countTasks = computed(() => eventStore.countTasks)
    const countUntreatedTasks = computed(() => eventStore.countUntreatedTasks)
    const calcTotalRemainingWorkingTime = computed(() => eventStore.calcTotalRemainingWorkingTime)
    const workingMinutesPerDay = computed(() => userStore.workingMinutesPerDay)
    const calcRemainingAllotedTimeHours = computed(() => eventStore.calcRemainingAllotedTimeHours)
    const calcRemainingAllotedTimeDays = computed(() => eventStore.calcRemainingAllotedTimeDays)
    // 稼働合計時間を投げると、稼働残日数を返す関数:引数はRange

    const tags = computed(() => tagStore.tags)
    const filterTasksByDateRange = computed(() => eventStore.filterTasksByDateRange)
    const filterTaskDays = computed(() => eventStore.filterTaskDays)
    const countDoneTasks = computed(() => eventStore.countDoneTasks)
    const calcTotalWorkingTime = computed(() => eventStore.calcTotalWorkingTime)
    const calcTotalWorkedTime = computed(() => eventStore.calcTotalWorkedTime)
    const countWorkingDays = computed(() => eventStore.countWorkingDays)
    const calcTotalTime = computed(() => eventStore.calcTotalTime)
    const calcOccupancyRate = computed(() => eventStore.calcOccupancyRate)
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // new end
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    const selectedItemIndex = ref(0)
    const tagTasks = (tag: TagEntity) => eventStore.tagTasks(tag.id)
    const taskDisplayStore = useTaskDisplayStore()
    const isSelected = (index: number) => selectedItemIndex.value === index
    const tagTasksWorkingMinutes = computed(() => eventStore.tagTaskWorkingTime)
    const onClickRange = (index: number, range: string) => {
      selectedItemIndex.value = index
      eventStore.clearSelectedTask()
      eventStore.clearSelectedTag()
      eventStore.setSelectedTaskRange(range)
      taskDisplayStore.setTaskDisplayMode("RANGE")
      // eventStore.setTaskDisplayMode("RANGE")
      eventStore.setSearchTasks([])
    }
    const goTagEditPage = (tagId: Id) =>
      router.replace({ name: "TagEdit", params: { id: tagId.value } })
    const goTagCreatePage = () => router.replace({ name: "TagCreate" })

    const isSelectedTag = (tag: TagEntity) => selectedTag.value?.id.value === tag.id.value

    const onTagSelected = (tag: TagEntity) => {
      console.log("waaaaaaaaaaaaaaa")
      selectedTag.value = tag
      selectedItemIndex.value = -1
      eventStore.clearSelectedTask()
      eventStore.setSelectedTag(tag)
      taskDisplayStore.setTaskDisplayMode("TAG")
      // eventStore.setTaskDisplayMode("TAG")
      eventStore.setSearchTasks([])
    }
    const onDropToTrash = (e: any) => {
      const response = confirm(localizationText("delete_confirm"))
      if (!response) {
        return null
      }
      const taskId = e.dataTransfer.getData("task")
      const task = eventStore.findTask(new Id(taskId))
      if (task === null) {
        throw new Error("task is null")
      }
      eventStore.removeEvent(task)
    }
    const onDrop = (e: any, to: string) => {
      const taskId = e.dataTransfer.getData("task")
      console.log("taskID", taskId)
      const task = eventStore.findTask(new Id(taskId))
      if (task === null) {
        throw new Error("task is null")
      }
      if (to === "toToday") {
        if (!task.isMovableToToday) {
          alert(localizationText("can_not_move_today"))
        } else {
          alert(localizationText("task_moved_today"))
          eventStore.writeEvent(task.moveToToday())
          eventStore.clearSelectedTask()
          return
        }
      }
      if (to === "toTomorrow") {
        if (!task?.isMovableToTomorrow) {
          alert(localizationText("can_not_move_tomorrow"))
        } else {
          alert(localizationText("task_moved_tomorrow"))
          eventStore.writeEvent(task.moveToTomorrow())
          eventStore.clearSelectedTask()
          return
        }
      }
      if (to === "toThisWeek") {
        if (task.isMovableToThisWeek) {
          alert(localizationText("task_moved_thisweek"))
          eventStore.writeEvent(task.moveToThisWeek())
          eventStore.clearSelectedTask()
          return
        } else {
          alert(localizationText("can_not_moved_thisweek"))
        }
      }
      if (to === "toNextWeek") {
        if (!task.isMovableToNextWeek) {
          alert(localizationText("can_not_moved_nextweek"))
        } else {
          alert(localizationText("task_moved_nextweek"))
          eventStore.writeEvent(task.moveToNextWeek())
          eventStore.clearSelectedTask()
          return
        }
      }
      if (to === "toThisMonth") {
        if (!task.isMovableToThisMonth) {
          alert(localizationText("can_not_moved_thismonth"))
        } else {
          alert(localizationText("task_moved_thismonth"))
          eventStore.writeEvent(task.moveToThisMonth())
          eventStore.clearSelectedTask()
          return
        }
      }
      if (to === "toNextMonth") {
        if (!task.isMovableToTomorrow) {
          alert(localizationText("can_not_moved_nextmonth"))
        } else {
          alert(localizationText("task_moved_nextmonth"))
          eventStore.writeEvent(task.moveToNextMonth())
          eventStore.clearSelectedTask()
          return
        }
      }
      if (to === "toSomeday") {
        if (!task.isMovableToSomeday) {
          alert(localizationText("can_not_moved_someday"))
        } else {
          alert(localizationText("task_moved_someday"))
          eventStore.writeEvent(task.removeDate().removeDeadlineDate())
          eventStore.clearSelectedTask()
          return
        }
      }
    }

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // タグ関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    const onDropTag = (e: any, tagId: Id) => {
      const taskId = e.dataTransfer.getData("task")
      const task = eventStore.findTask(new Id(taskId))
      if (task === null) {
        throw new Error("task is null")
      }
      const newTask = task.addTagId(tagId)
      eventStore.writeEvent(newTask)
    }

    const addNewTag = () => {
      // tagModalOpen.value = true
      tagEditModalStore.open()
    }

    const onTagDelete = (tag: TagEntity) => {
      tagStore.deleteTag(tag.id)
      // tagModalOpen.value = false
      tagEditModalStore.close()
    }

    const onTagGroupToggle = () => {
      tagGroupOpen.value = !tagGroupOpen.value
    }

    onMounted(() => {
      onClickRange(0, "AFTER_TOADY")
    })

    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }

    return {
      selectedTag,
      tagModalOpen,
      onTagModalOpen,
      onTagModalCancel,
      tags,
      isSelected,
      onClickRange,
      goTagEditPage,
      goTagCreatePage,
      tagTasks,
      workingMinutesPerDay,
      onTagSelected,
      isSelectedTag,
      onDrop,
      onDropTag,
      onDropToTrash,
      tagTasksWorkingMinutes,
      onTagDelete,
      addNewTag,
      tagGroupOpen,
      onTagGroupToggle,
      filterTasksByDateRange,
      filterTaskDays,
      countTasks,
      countUntreatedTasks,
      countDoneTasks,
      calcTotalWorkingTime,
      calcTotalWorkedTime,
      calcTotalRemainingWorkingTime,
      calcRemainingAllotedTimeHours,
      calcRemainingAllotedTimeDays,
      calcTotalTime,
      calcOccupancyRate,
      countWorkingDays,
      localizationText,
    }
  },
})
