
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import { z } from "zod"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "Signin",
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const email = ref("")
    const inputType = ref<string>("password")
    const password = ref("")
    const signin = async () => {
      try {
        const zEmail = z.string().email({ message: localizationText("email_invalid") })
        const result = zEmail.safeParse(email.value)
        if (!result.success) {
          alert(localizationText("email_invalid"))
          return
        }
        await userStore.signInWithEmailAndPassword({
          email: email.value,
          password: password.value,
        })
        router.replace({ name: "DashBoard" })
      } catch (error: any) {
        router.push({ name: "Error", params: { message: error } })
      }
    }
    const goSignupPage = () => router.replace({ name: "Signup" })
    const showToggle = () => {
      if (inputType.value === "text") {
        inputType.value = "password"
        return
      }
      if (inputType.value === "password") {
        inputType.value = "text"
      }
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      var language =
        (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language
      if (language !== "ja") {
        language = "en"
      }
      return getLocalization(language, key)
    }
    return {
      email,
      password,
      signin,
      goSignupPage,
      inputType,
      showToggle,
      localizationText,
    }
  },
})
