import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "groupList" }
const _hoisted_2 = ["id", "onChange", "checked"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "submitList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allTags, (tag) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tag.id.value
        }, [
          _createElementVNode("input", {
            id: tag.id.value,
            class: _normalizeClass(tag.color),
            type: "checkbox",
            name: "checkbox",
            onChange: ($event: any) => (_ctx.toggleSelectedTagIds(tag)),
            checked: _ctx.isSelectedTag(tag)
          }, null, 42, _hoisted_2),
          _createElementVNode("label", {
            for: tag.id.value
          }, _toDisplayString(tag.name), 9, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "reset",
          class: "cancel",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, _toDisplayString(_ctx.localizationText("cancel")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "submit",
          class: "submit",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTagSelectEnd && _ctx.onTagSelectEnd(...args)))
        }, _toDisplayString(_ctx.localizationText("ok")), 1)
      ])
    ])
  ]))
}