
import { defineComponent, computed } from "vue"
import EventEntity from "@/domain/entities/event.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import { useEventStore } from "@/stores/event"
import { useTagStore } from "@/stores/tag"
import TagEntity from "@/domain/entities/tag.entity"

export default defineComponent({
  name: "TaskItem",
  props: {
    task: {
      required: true,
      type: EventEntity,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const eventStore = useEventStore()

    const tagStore = useTagStore()

    const startTime = computed(() => {
      if (props.task.date) {
        const startTimeText = new DateTime(props.task.date.value).toTextStartTime()
        const endTimeText = new DateTime(props.task.deadlineDate!.value).toTextStartTime()
        // 日付があって時間が存在しない場合
        if (startTimeText == "00:00" && endTimeText == "00:00") {
          return "nothing"
        }
        return startTimeText
      }
      return "nothing"
    })
    const endTime = computed(() => {
      if (props.task.deadlineDate) {
        const dateText = new DateTime(props.task.deadlineDate.value).toTextStartTime()
        // 日付があって時間が存在しない場合
        if (dateText == "00:00") {
          return "nothing"
        }
        return dateText
      }
      return "nothing"
    })

    const hasTimeRange = computed(
      () => startTime.value !== "nothing" && endTime.value !== "nothing",
    )

    /**
     * タスクを選択する
     */
    const onClick = () => {
      // if (props.task.isDone) return; 完了状態でも選択できる仕様
      eventStore.setSelectedTask(props.task)
    }

    const isSelected = computed(() => {
      const selectedTask = eventStore.selectedTask
      if (selectedTask === props.task) {
        return true
      }
      return false
    })

    /**
     * タスクを完了としてマークする
     */
    const onSwitchStatus = () => {
      const event = props.task.switchStatus()
      eventStore.writeEvent(event)
    }

    const dateChange = (e: any) => {
      if (e.target.value === "toToday") {
        if (!props.task.isMovableToToday) {
          alert("このタスクは今日に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToToday())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toTomorrow") {
        if (!props.task.isMovableToTomorrow) {
          alert("このタスクは明日に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToTomorrow())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toThisWeek") {
        if (!props.task.isMovableToThisWeek) {
          alert("このタスクはに今週に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToThisWeek())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toNextWeek") {
        if (!props.task.isMovableToTomorrow) {
          alert("このタスクはに来週に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToNextWeek())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toThisMonth") {
        if (!props.task.isMovableToThisMonth) {
          alert("このタスクはに今月に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToThisMonth())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toNextMonth") {
        if (!props.task.isMovableToTomorrow) {
          alert("このタスクはに来月に移動する事はできません。")
        } else {
          eventStore.writeEvent(props.task.moveToNextMonth())
          eventStore.clearSelectedTask()
        }
      }
      if (e.target.value === "toSomeday") {
        if (!props.task.isMovableToSomeday) {
          alert("このタスクはに「いつか」に移動する事はできません。")
        } else {
          // alert('このタスクは「いつか」移動可能です。');
          eventStore.writeEvent(props.task.removeDate())
          eventStore.clearSelectedTask()
        }
      }
    }
    const onDragStart = (e: any) => {
      console.log("onDragStart")
      if (!props.draggable) return
      e.dataTransfer.effectAllowed = "move"
      e.dataTransfer.dropEffect = "move"
      e.dataTransfer.setData("task", props.task.id.value)
    }

    const tags = computed(() => tagStore.tags)
    const taskTags = computed(() => {
      if (props.task.tagIds.length === 0) return []
      return props.task.tagIds
        .map((tagId) => {
          return tags.value.find((tag: any) => tag.id.value == tagId.value)
        })
        .filter((tag): tag is TagEntity => {
          return tag !== undefined
        })
    })

    const appleClass = computed(() => {
      if (props.task.applesCount === 1) return "t05"
      if (props.task.applesCount === 2) return "t10"
      if (props.task.applesCount === 3) return "t15"
      if (props.task.applesCount === 4) return "t20"
      if (props.task.applesCount === 5) return "t25"
      if (props.task.applesCount === 6) return "t30"
      if (props.task.applesCount > 6) return "t30"
    })

    const isCompletedRange = computed(() => {
      const range = eventStore.selectedTaskRange
      return range === "COMPLETED"
    })

    const deadCountClass = (): string => {
      return `c${props.task.deathCount}`
    }

    return {
      startTime,
      endTime,
      hasTimeRange,
      onClick,
      onSwitchStatus,
      dateChange,
      onDragStart,
      isSelected,
      tags,
      taskTags,
      appleClass,
      isCompletedRange,
      deadCountClass,
    }
  },
})
