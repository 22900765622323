import { defineStore } from "pinia"

type State = {
  taskDisplayMode: "RANGE" | "TAG"
}

export const useTaskDisplayStore = defineStore("taskDisplay", {
  state: (): State => {
    return {
      taskDisplayMode: "RANGE",
    }
  },
  getters: {
    displayRangeName() {
      return (rangeName: string) => {
        if (rangeName === "AFTER_TOADY") return "すべて"
        if (rangeName === "TODAY") return "今日"
        if (rangeName === "TOMORROW") return "明日"
        if (rangeName === "THIS_WEEK") return "今週"
        if (rangeName === "NEXT_WEEK") return "来週"
        if (rangeName === "THIS_MONTH") return "今月"
        if (rangeName === "NEXT_MONTH") return "来月"
        if (rangeName === "SOMEDAY") return "いつか"
        if (rangeName === "COMPLETED") return "完了"
        return ""
      }
    },
    isDoneTaskShow() {
      return (rangeName: string) => {
        if (rangeName === "TODAY") return true
        if (rangeName === "THIS_WEEK") return true
        if (rangeName === "COMPLETED") return true
        return false
      }
    },
    isRangeDisplayMode(): boolean {
      return this.taskDisplayMode === "RANGE"
    },
    isTagDisplayMode(): boolean {
      return this.taskDisplayMode === "TAG"
    },
  },
  actions: {
    setTaskDisplayMode(mode: "RANGE" | "TAG") {
      this.taskDisplayMode = mode
    },
  },
})
