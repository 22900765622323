
import { defineComponent, ref, computed } from "vue"
import TasksStatusDisplay from "./TasksStatusDisplay.vue"
import TaskItem from "@/components/TaskItem.vue"
import EventEntity from "@/domain/entities/event.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import { useEventStore } from "@/stores/event"
import { useUserStore } from "@/stores/user"
import { useTaskDisplayStore } from "@/stores/taskDisplay"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TaskList",
  components: {
    TasksStatusDisplay,
    TaskItem,
  },
  props: {
    isDoneTaskShow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const eventStore = useEventStore()
    const userStore = useUserStore()
    const user = computed(() => userStore.entity!)

    const taskDisplayStore = useTaskDisplayStore()

    const holidayEvents = computed(() => eventStore.holidayEvents)

    const filterInactiveTaskDays = computed(() => eventStore.filterInactiveTaskDays)
    const filterInactiveTaskDaysReverse = computed(() => eventStore.filterInactiveTaskDaysReverse)
    const isSelectedRangeIsCompleted = computed(() => eventStore.selectedTaskRange === "COMPLETED")
    const displayDays = computed(() => {
      if (isSelectedRangeIsCompleted.value) {
        return filterInactiveTaskDays.value
      }
      return filterInactiveTaskDaysReverse.value
    })

    const selectedTaskRange = computed(() => eventStore.selectedTaskRange)
    const taskDisplayMode = computed(() => taskDisplayStore.taskDisplayMode)
    const tasksInfo = computed(() => eventStore.totalInfo) //(selectedTaskRange.value)
    const selectedTagTaskDays = computed(() => eventStore.selectedTagTaskDays)
    const searchTaskDays = computed(() => eventStore.searchTaskDays)
    const taskDays = computed(() => {
      return eventStore
        .totalInfo(selectedTaskRange.value)
        .taskDays.map((day: any) => new DateTime(day).toText())
    })
    const tasks = computed(() => {
      return eventStore.totalInfo(selectedTaskRange.value).tasks
    })

    const isHoliday = (date: Date) => {
      const t = holidayEvents.value.find((event: EventEntity) => {
        return event.date?.isSameDay(new DateTime(date))
      })
      return typeof t !== "undefined"
    }

    const isNotWorkingDay = (date: Date): boolean => {
      const dateTime = new DateTime(date)
      return !user.value.workingWeekDayIndexes.includes(dateTime.dayOfWeek())
    }

    const selectedTagTasks = (targetDay: Date) => {
      const allTasks: EventEntity[] = eventStore.selectedTagTasks
      const dayTasks = allTasks.filter((task: EventEntity) => {
        if (task.date === null) return false
        const taskDate = new Date(task.date!.value)
        return new DateTime(targetDay).toText() === new DateTime(taskDate).toText()
      })
      const timeTasks = dayTasks.filter((task) => {
        return task.isNotDone && task.date?.hasTime
      })
      const noTimeTasks = dayTasks.filter((task) => {
        return task.isNotDone && task.date?.hasNoTime
      })
      return [...timeTasks, ...noTimeTasks]
    }
    const tagInactiveTasks = computed<EventEntity[]>(() => {
      const allTasks = eventStore.selectedTagTasks
      const tasks = allTasks.filter((task) => {
        return task.isDone
      })
      return tasks
    })
    const selectedNoDateTagTasks = computed<EventEntity[]>(() => {
      const allTasks = eventStore.selectedNoDateTagTasks
      const activeTasks = allTasks.filter((task) => {
        return task.isNotDone
      })
      const inactiveTasks = allTasks.filter((task) => {
        return task.isDone
      })
      return [
        ...activeTasks,
        // ...inactiveTasks,
      ]
    })
    const targetDayTasks = (targetDay: Date) => {
      const allTasks = tasks.value.filter((task) => {
        if (task.date === null) return false
        const taskDate = new Date(task.date!.value)
        return new DateTime(targetDay).toText() === new DateTime(taskDate).toText()
      })
      const timeTasks = allTasks.filter((task) => {
        return task.isNotDone && task.date?.hasTime
      })
      const noTimeTasks = allTasks.filter((task) => {
        return task.isNotDone && task.date?.hasNoTime
      })
      return [...timeTasks, ...noTimeTasks]
    }
    const inactiveTasks = (targetDay: Date) => {
      return tasks.value.filter((task) => {
        if (task.completedAt === null) return false
        if (task.isNotDone) return false
        const taskDate = new Date(task.completedAt!.value)
        return new DateTime(targetDay).toText() === new DateTime(taskDate).toText()
      })
    }

    const hasInactiveTasks = computed(() => {
      const taskDays = filterInactiveTaskDays.value("COMPLETED")
      let flg = false
      taskDays.forEach((day: any) => {
        const tasks = inactiveTasks(day)
        if (tasks.length > 0) {
          flg = true
        }
      })
      return flg
    })

    const somedayTasks = computed(() => {
      const allTasks = tasks.value.filter((task: EventEntity) => {
        return task.date === null
      })
      const activeTasks = allTasks.filter((task) => {
        return task.isNotDone
      })
      const inactiveTasks = allTasks.filter((task) => {
        return task.isDone
      })
      return [...activeTasks, ...inactiveTasks]
    })
    const activeSomedayTasks = computed(() => {
      return tasks.value.filter((task: EventEntity) => {
        return task.date === null && task.isNotDone
      })
    })
    const inactiveSomedayTasks = computed(() => {
      return tasks.value.filter((task: EventEntity) => {
        return task.date === null && task.isDone
      })
    })
    const hasSomedayTask = computed(() => somedayTasks.value.length > 0)
    const displayDate = (date: Date) => new DateTime(date).toTextObject()
    // 検索結果として保存されたタスク
    const searchResultTasks = computed<EventEntity[]>(() => {
      const allTasks = eventStore.searchTasks.filter((task) => {
        return task.date !== null
      })
      const activeTasks = allTasks.filter((task) => {
        return task.isNotDone
      })
      const inactiveTasks = allTasks.filter((task) => {
        return task.isDone
      })
      return [...activeTasks, ...inactiveTasks]
    })
    const searchResultNoDateTasks = computed<EventEntity[]>(() => {
      const allTasks = eventStore.searchTasks.filter((task) => {
        return task.date === null
      })
      const activeTasks = allTasks.filter((task) => {
        return task.isNotDone
      })
      const inactiveTasks = allTasks.filter((task) => {
        return task.isDone
      })
      return [...activeTasks, ...inactiveTasks]
    })
    const hasNodateTasks = computed(() => searchResultNoDateTasks.value.length !== 0)
    const hasSearchResult = computed(() => searchResultTasks.value.length !== 0)

    const displayTasks = computed<EventEntity[]>(() => {
      if (searchResultTasks.value.length !== 0) {
        return searchResultTasks.value
      }
      return tasks.value
    })
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }

    return {
      taskDisplayMode,
      selectedTaskRange,
      displayTasks,
      searchResultTasks,
      searchResultNoDateTasks,
      hasSearchResult,
      tasksInfo,
      selectedTagTaskDays,
      searchTaskDays,
      taskDays,
      tasks,
      selectedTagTasks,
      selectedNoDateTagTasks,
      hasNodateTasks,
      targetDayTasks,
      somedayTasks,
      activeSomedayTasks,
      inactiveSomedayTasks,
      hasSomedayTask,
      displayDate,
      inactiveTasks,
      tagInactiveTasks,
      isHoliday,
      isNotWorkingDay,
      filterInactiveTaskDays,
      filterInactiveTaskDaysReverse,
      hasInactiveTasks,
      localizationText,
      isSelectedRangeIsCompleted,
      displayDays,
    }
  },
})
