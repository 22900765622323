import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore"
import EventEntity from "@/domain/entities/event.entity"
import { FirestoreTask } from "../types/firestoreTask"
import EventStatus from "@/domain/valueObjects/eventStatus"
import Id from "@/domain/valueObjects/id"
import EventType from "@/domain/valueObjects/eventType"
import DateTime from "@/domain/valueObjects/dateTime"

export default class TaskConverter {
  static toFirestore(task: EventEntity): DocumentData {
    return {
      id: task.id.value,
      updatedAt: new Date(),
      userId: task.userId.value,
      date: task.date?.value ?? null,
      deadlineDate: task.deadlineDate?.value ?? null,
      completedAt: task.completedAt?.value ?? null,
      name: task.name,
      workingMinutes: task.workingMinutes,
      status: task.status.value,
      memo: task.memo ?? "",
      tagIds: task.tagIds.map((id) => id.value),
      createdAt: task.createdAt,
      type: task.type.value,
      deathCount: task.deathCount ?? 0,
    }
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot): EventEntity {
    const data = snapshot.data() as FirestoreTask

    return new EventEntity({
      id: new Id(snapshot.id),
      userId: new Id(data.userId),
      date: data.date ? new DateTime(data.date.toDate()) : null,
      deadlineDate: data.deadlineDate ? new DateTime(data.deadlineDate.toDate()) : null,
      completedAt: data.completedAt ? new DateTime(data.completedAt.toDate()) : null,
      name: data.name,
      memo: data.memo ?? "",
      // 間違えて文字列の数字をデータベースにほじんしてしまった場合に対応
      workingMinutes:
        typeof data.workingMinutes === "string"
          ? parseInt(data.workingMinutes as string, 10)
          : data.workingMinutes,
      status: new EventStatus(data.status),
      tagIds: data.tagIds.map((id) => new Id(id)),
      createdAt: data.createdAt.toDate(),
      type: new EventType(data.type),
      deathCount: data.deathCount ?? 0,
    })
  }
}
