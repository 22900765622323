type Value = 0 | 1 | 2 | 3 | 4 | 5 | 6

export const dayOfWeekNum = [0, 1, 2, 3, 4, 5, 6]
export const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"]
export const dayOfWeekStrEn = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

export default class DayOfWeek {
  constructor(readonly value: Value) {
    this.value = value
  }

  static get sunday(): DayOfWeek {
    return new DayOfWeek(0)
  }

  static get monday(): DayOfWeek {
    return new DayOfWeek(1)
  }

  static get tuesday(): DayOfWeek {
    return new DayOfWeek(2)
  }

  static get wednesday(): DayOfWeek {
    return new DayOfWeek(3)
  }

  static get thursday(): DayOfWeek {
    return new DayOfWeek(4)
  }

  static get friday(): DayOfWeek {
    return new DayOfWeek(5)
  }

  static get saturday(): DayOfWeek {
    return new DayOfWeek(6)
  }

  // toString的な名前もありかも
  getDayOfWeekStr(): string {
    return dayOfWeekStr[this.value]
  }

  getDayOfWeekStrEn(): string {
    return dayOfWeekStrEn[this.value]
  }

  static fromNumber(n: number): DayOfWeek {
    if (n !== 0 && n !== 1 && n !== 2 && n !== 3 && n !== 4 && n !== 5 && n !== 6) {
      throw new Error()
    }

    return new DayOfWeek(n)
  }
}
