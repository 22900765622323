
import { defineComponent, ref, computed, onMounted, onUnmounted } from "vue"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"
import { useTaskDisplayStore } from "@/stores/taskDisplay"
import { useLocalizationStore } from "@/stores/localization"
import TasksStatusDisplay from "@/components/TasksStatusDisplay.vue"
import TaskStatusDisplayNow from "@/components/TasksStatusDisplay_now.vue"
import TaskStatusDisplayNow2 from "@/components/TasksStatusDisplay_now2.vue"
import TaskStatusDisplayPast from "@/components/TasksStatusDisplay_past2.vue"
import TaskStatusDisplayTagGroup from "@/components/TasksStatusDisplay_tag_group.vue"
import TaskEditLight from "@/components/TaskEditLight.vue"
import TaskList from "@/components/TaskList.vue"

export default defineComponent({
  name: "Dashboard",
  components: {
    TasksStatusDisplay,
    TaskEditLight,
    TaskList,
    TaskStatusDisplayNow,
    TaskStatusDisplayNow2,
    TaskStatusDisplayPast,
    TaskStatusDisplayTagGroup,
  },
  setup() {
    const userStore = useUserStore()
    const taskDisplayStore = useTaskDisplayStore()
    const isRangeDisplay = computed(() => taskDisplayStore.isRangeDisplayMode)
    const isTagDisplay = computed(() => taskDisplayStore.isTagDisplayMode)

    let count = ref(0)
    let addTaskCount = ref(0)
    let intervalId = ref<any>()
    const eventStore = useEventStore()
    const calcOccupancyRate = computed(() => eventStore.calcOccupancyRate)
    const selectedTaskRange = computed(() => eventStore.selectedTaskRange)
    const isTodayRange = computed(() => selectedRange.value === "TODAY")
    const isTomorrowRange = computed(() => selectedRange.value === "TOMORROW")
    const isThisWeekRange = computed(() => selectedRange.value === "THIS_WEEK")
    const isNextWeekRange = computed(() => selectedRange.value === "NEXT_WEEK")
    const isThisMonthRange = computed(() => selectedRange.value === "THIS_MONTH")
    const isNextMonthRange = computed(() => selectedRange.value === "NEXT_MONTH")
    const isSomedayRange = computed(() => selectedRange.value === "SOMEDAY")
    const isAfterTodayRange = computed(() => selectedRange.value === "AFTER_TOADY")
    const isCompletedRange = computed(() => selectedRange.value === "COMPLETED")

    const displayMode = computed(() => taskDisplayStore.taskDisplayMode)
    const tasksInfo = computed(() => eventStore.totalInfo(selectedTaskRange.value))
    const user = computed(() => userStore.entity)
    const selectedRange = computed(() => eventStore.selectedTaskRange)
    const isDoneTaskShow = computed(() => taskDisplayStore.isDoneTaskShow(selectedRange.value))
    const remainingTimeText = computed(() => userStore.remainingTimeText)
    const headerLabel = computed(() => {
      if (displayMode.value === "RANGE") {
        return taskDisplayStore.displayRangeName(selectedRange.value)
      }
      if (displayMode.value === "TAG") {
        return eventStore.selectedTag?.name
      }
      return ""
    })

    const activeTagTaskCount = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.activeTagTaskCount(tag.id)
    })

    const inactiveTagTaskCount = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.inactiveTagTaskCount(tag.id)
    })

    const tagTaskActiveWorkingTime = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.tagTaskActiveWorkingTime(tag.id)
    })

    const tagTaskInactiveWorkingTime = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.tagTaskInactiveWorkingTime(tag.id)
    })

    const aggregateWorkedDays = computed(() => {
      return eventStore.aggregateWorkedDays
    })

    const inactiveTagTaskDays = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.aggregateInactiveTagTaskDays(tag.id)
    })

    const activeTagTaskDays = computed(() => {
      const tag = eventStore.selectedTag!
      return eventStore.aggregateActiveTagTaskDays(tag.id)
    })

    const startToday = () => userStore.startWork
    onMounted(() => {
      intervalId.value = setInterval(() => timerTick(), 1000 * 60)
    })
    onUnmounted(() => {
      clearInterval(intervalId.value)
    })
    const timerTick = () => {
      count.value = count.value + 1
    }
    const onTaskAdded = () => (addTaskCount.value += 1)

    const countDoneTasks = computed(() => eventStore.countDoneTasks)

    const countTasks = computed(() => eventStore.countTasks)

    const countUntreatedTasks = computed(() => eventStore.countUntreatedTasks)

    const isShowNowStatus = computed(() => {
      const displayMode = eventStore.taskDisplayMode
      if (displayMode === "RANGE" && selectedRange.value === "TODAY") return true
      if (displayMode === "RANGE" && selectedRange.value === "THIS_WEEK") return true
      return false
    })

    const calcRemainingAllotedTimeHours = computed(() => eventStore.calcRemainingAllotedTimeHours)
    const calcRemainingAllotedTimeDays = computed(() => eventStore.calcRemainingAllotedTimeDays)

    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      displayMode,
      tasksInfo,
      addTaskCount,
      user,
      count,
      intervalId,
      calcOccupancyRate,
      isTodayRange,
      isTomorrowRange,
      isThisWeekRange,
      isNextWeekRange,
      isThisMonthRange,
      isNextMonthRange,
      isSomedayRange,
      isAfterTodayRange,
      isDoneTaskShow,
      remainingTimeText,
      startToday,
      onTaskAdded,
      headerLabel,
      isRangeDisplay,
      isTagDisplay,
      activeTagTaskCount,
      inactiveTagTaskCount,
      tagTaskActiveWorkingTime,
      tagTaskInactiveWorkingTime,
      selectedRange,
      isShowNowStatus,
      isCompletedRange,
      calcRemainingAllotedTimeHours,
      calcRemainingAllotedTimeDays,
      countDoneTasks,
      countTasks,
      countUntreatedTasks,
      aggregateWorkedDays,
      inactiveTagTaskDays,
      activeTagTaskDays,
      localizationText,
    }
  },
})
