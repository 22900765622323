
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import { useUserStore } from "@/stores/user"

export default defineComponent({
  name: "SetupPassword",
  setup() {
    const userStore = useUserStore()
    const password = ref("")
    const inputType = ref<string>("password")
    const showToggle = () => {
      if (inputType.value === "text") {
        inputType.value = "password"
        return
      }
      if (inputType.value === "password") {
        inputType.value = "text"
      }
    }
    const router = useRouter()
    const addPassword = async () => {
      // return false;
      await userStore.updatePassword({ password: password.value })
      router.replace({ name: "DashBoard" })
    }
    return {
      password,
      inputType,
      showToggle,
      addPassword,
    }
  },
})
