
import { defineComponent, PropType, computed, ref } from "vue"
import { useUserStore } from "@/stores/user"

export default defineComponent({
  name: "SideMenuSingleItem",
  props: {
    // 残稼働時間 or 残稼働日
    remainingAllotedTime: {
      type: Number as PropType<number>,
    },
    displayUnit: {
      type: String as PropType<string>,
    },
    // 稼働率
    occupancyRate: {
      type: Number as PropType<number>,
      default: 0,
    },
    showTotal: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    // タスク数
    count: {
      type: Number as PropType<number>,
    },
    // ラベル（今日とか明日とか）
    label: {
      type: String as PropType<string>,
    },
    // 上位コンポーネントから注入可能なクラス情報
    injectionClass: {
      type: String as PropType<string>,
      default: "",
    },
    // 上位コンポーネントから注入可能な色情報
    injectionColor: {
      type: String as PropType<string>,
      default: "black",
    },
    // このメニューが選択されているかどうか
    isSelected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // 稼働日数
    workingDaysCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    // 稼働残日数
    remainingWorkingDaysCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    elapsedWorkingTime: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const userEntity = computed(() => userStore.entity!)
    // workingMinutesPerDay
    const onMenuClick = () => emit("click")
    const isDragging = ref(false)
    const onDrag = () => {
      console.log("onDrag")
      isDragging.value = true
    }
    const onDrop = () => {
      console.log("onDrop")
      isDragging.value = false
    }
    const onDragLeave = () => {
      console.log("onDragLeave")
      isDragging.value = false
    }
    const addClass = computed(() => {
      const className = [props.injectionClass, props.injectionColor]
      if (props.isSelected) className.push("active")
      if (typeof props.occupancyRate !== "undefined") className.push(`p${props.occupancyRate}`)
      if (isDragging.value) className.push("dragging")
      return className.join(" ")
    })
    const hasTaskCount = computed(() => props.count !== undefined)
    const hasShowTotal = computed(() => props.remainingAllotedTime !== undefined)

    const displayTime = computed(() => {
      if (props.remainingAllotedTime === undefined) return ""
      if (props.remainingAllotedTime <= 0) return "0"
      // 小数点切り捨て
      if (isTwoDaysOverWorking.value) {
        return Math.floor(props.remainingAllotedTime / (userEntity.value.workingMinutesPerDay / 60))
      }
      return Math.floor(props.remainingAllotedTime).toString()
    })
    const isTwoDaysOverWorking = computed(() => {
      if (userEntity === null) return false
      if (typeof props.remainingAllotedTime === "undefined") return false
      return 2 < Math.ceil(props.remainingAllotedTime / (userEntity.value.workingMinutesPerDay / 60))
    })
    return {
      addClass,
      hasTaskCount,
      hasShowTotal,
      onMenuClick,
      onDrag,
      onDrop,
      onDragLeave,
      displayTime,
      isTwoDaysOverWorking,
    }
  },
})
