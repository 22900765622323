import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore"
import TagEntity from "@/domain/entities/tag.entity"
import { FirestoreTag } from "../types/firestoreTag"
import Id from "@/domain/valueObjects/id"

export default class TagConverter {
  static toFirestore(tag: TagEntity): DocumentData {
    return {
      name: tag.name,
      color: tag.color,
      updatedAt: new Date(),
    }
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot): TagEntity {
    const data = snapshot.data() as FirestoreTag

    // パスからユーザーのIDを取得する
    const userId = snapshot.ref.parent.parent?.id ?? ""

    return new TagEntity({
      id: new Id(snapshot.id),
      name: data.name,
      color: data.color,
      userId: new Id(userId),
    })
  }
}
