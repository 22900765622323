
import { defineComponent, computed } from "vue"
import { useUserStore } from "@/stores/user"
import { useEventStore } from "@/stores/event"

export default defineComponent({
  name: "Error",
  setup() {
    const userStore = useUserStore()
    const eventStore = useEventStore()
    const user = computed(() => userStore.entity)
    const tasks = computed(() => eventStore.tasks)
    const holiday = computed(() => eventStore.allHolidays)
    const reportData = computed(() => {
      let report: any = {}
      report.user = user.value
      report.tasks = tasks.value
      report.tasks.forEach((task: any) => {
        task.props.name = "dummy task name"
        task.name = "dummy task name"
        task.memo = ""
      })
      report.holiday = holiday.value
      return report
    })
    // クリップボードにユーザー情報をコピー
    const copyUser = () => {
      navigator.clipboard.writeText(JSON.stringify(reportData.value.user))
      window.alert("クリップボードにコピーしました")
    }

    const copyTasks = () => {
      navigator.clipboard.writeText(JSON.stringify(reportData.value.tasks))
      window.alert("クリップボードにコピーしました")
    }

    const copyHolidays = () => {
      navigator.clipboard.writeText(JSON.stringify(reportData.value.holiday))
      window.alert("クリップボードにコピーしました")
    }

    const copyAll = () => {
      navigator.clipboard.writeText(JSON.stringify(reportData.value))
      window.alert("クリップボードにコピーしました")
    }
    // let reportData: any = {}
    // // console.log("aaaa", userStore.entity)
    // reportData.user = user.value
    // reportData.uid = userStore.uid
    return {
      reportData,
      copyUser,
      copyTasks,
      copyHolidays,
      copyAll,
    }
  },
})
