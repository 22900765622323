import { createApp } from "vue"
import { initializeApp } from "firebase/app"
import App from "./App.vue"
import router from "./router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { createPinia } from "pinia"

const firebaseConfig = {
  apiKey: "AIzaSyCMkCcqCJvYB5lwWTr8LSACDetwYrNji-k",
  authDomain: "jikankakeibo.firebaseapp.com",
  projectId: "jikankakeibo",
  storageBucket: "jikankakeibo.appspot.com",
  messagingSenderId: "873300210478",
  appId: "1:873300210478:web:ec0ade9b293b57e6c640bf",
  measurementId: "G-67JVDS734K",
}

initializeApp(firebaseConfig)

const pinia = createPinia()

// ログイン状態に関わらず一度だけ呼び出される
const unsubscribe = onAuthStateChanged(getAuth(), async () => {
  const app = createApp(App)
  app.use(pinia)
  app.use(router)
  app.mount("#app")
  unsubscribe()
})
