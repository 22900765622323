
import { defineComponent, PropType, ref, computed } from "vue"
import { useRoute } from "vue-router"
import TagEntity from "@/domain/entities/tag.entity"
import Id from "@/domain/valueObjects/id"
import { useUserStore } from "@/stores/user"
import { useTagStore } from "@/stores/tag"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TaskCreate",
  props: {
    tag: TagEntity as PropType<TagEntity>,
  },
  emits: ["cancel", "submit", "delete"],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const tagStore = useTagStore()
    const route = useRoute()
    const isNewTag = computed(() => !props.tag)
    const tagId = route.params.id ? new Id(route.params.id + "") : null
    const tagName = ref(props.tag?.name ?? "")
    const tagColor = ref(props.tag?.color ?? "color01")
    const uid = computed(() => userStore.uid!)
    const onCancel = () => emit("cancel")
    const addTag = async () => {
      if (tagName.value === "") {
        alert(localizationText("tag_edit_error1"))
        return
      }
      const tagEntity = props.tag
        ? props.tag.updateName(tagName.value).updateColor(tagColor.value)
        : TagEntity.createTag({
            userId: new Id(uid.value),
            name: tagName.value,
            color: tagColor.value,
          })
      await tagStore.writeTag(tagEntity)
      emit("submit")
    }
    const deleteTag = () => {
      const response = confirm(localizationText("delete_confirm"))
      if (!response) {
        return null
      }
      emit("delete", props.tag)
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      isNewTag,
      tagName,
      tagId,
      tagColor,
      onCancel,
      addTag,
      deleteTag,
      localizationText,
    }
  },
})
