type Value = "TASK" | "HOLIDAY"

export default class EventType {
  constructor(readonly value: Value) {
    this.value = value
  }

  get isTask(): boolean {
    return this.value === "TASK"
  }

  get isHoliday(): boolean {
    return this.value === "HOLIDAY"
  }

  static get task(): EventType {
    return new EventType("TASK")
  }

  static get holiday(): EventType {
    return new EventType("HOLIDAY")
  }
}
