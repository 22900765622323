import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = {
  key: 0,
  class: "count"
}
const _hoisted_3 = {
  key: 1,
  class: "total"
}
const _hoisted_4 = {
  key: 2,
  class: "total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("dl", {
    class: _normalizeClass(_ctx.addClass),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuClick && _ctx.onMenuClick(...args))),
    onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDrag && _ctx.onDrag(...args))),
    onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args))),
    onDrop: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)))
  }, [
    _createElementVNode("dt", _hoisted_1, _toDisplayString(_ctx.label), 1),
    (_ctx.hasTaskCount)
      ? (_openBlock(), _createElementBlock("dd", _hoisted_2, _toDisplayString(_ctx.count), 1))
      : _createCommentVNode("", true),
    (_ctx.hasShowTotal && _ctx.isTwoDaysOverWorking)
      ? (_openBlock(), _createElementBlock("dd", _hoisted_3, _toDisplayString(_ctx.displayTime) + _toDisplayString(_ctx.displayUnit), 1))
      : _createCommentVNode("", true),
    (_ctx.hasShowTotal && !_ctx.isTwoDaysOverWorking)
      ? (_openBlock(), _createElementBlock("dd", _hoisted_4, _toDisplayString(_ctx.displayTime) + "h", 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}