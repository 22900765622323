
import { defineComponent } from "vue"
import TagEntity from "@/domain/entities/tag.entity"

export default defineComponent({
  name: "TagItem",
  props: {
    tag: TagEntity,
    selected: Boolean,
  },
  emits: ["click"],
  setup(props, { emit }) {
    const onClick = () => {
      emit("click", props.tag)
    }
    return {
      onClick,
    }
  },
})
