import Id from "../valueObjects/id"

type Props = {
  id: Id
  name: string
  color: string
  userId: Id
}

export default class TagEntity {
  /**
   * ID
   */
  readonly id!: Id

  /**
   * タグの名前
   */
  readonly name!: string

  /**
   * タグの色
   */
  readonly color!: string

  readonly userId!: Id

  constructor(readonly props: Props) {
    Object.assign(this, props)
  }

  static createTag(props: { userId: Id; name: string; color: string }): TagEntity {
    return new TagEntity({
      id: Id.create(),
      name: props.name,
      color: props.color,
      userId: props.userId,
    })
  }

  updateName(name: string): TagEntity {
    return new TagEntity({ ...this.props, name })
  }

  updateColor(color: string): TagEntity {
    return new TagEntity({ ...this.props, color })
  }
}
