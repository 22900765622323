import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "taskName" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "taskDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkingHourSelect = _resolveComponent("WorkingHourSelect")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["taskAddition", { active: _ctx.hasInputFocus }])
  }, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.localizationText('add_task'),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.taskName) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onAddTask && _ctx.onAddTask(...args)), ["enter"])),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args))),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInputOutFocus && _ctx.onInputOutFocus(...args)))
        }, null, 40, _hoisted_2), [
          [_vModelText, _ctx.taskName]
        ])
      ]),
      _createElementVNode("li", {
        class: _normalizeClass(["taskTime", _ctx.appleClass])
      }, [
        _createElementVNode("button", {
          class: "btn_editOpen",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOpenModal('TaskEditLight'))),
          onFocus: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args))),
          onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onInputOutFocus && _ctx.onInputOutFocus(...args)))
        }, " ︙ ", 32),
        (_ctx.isWorkTimeSelectModalOpen)
          ? (_openBlock(), _createBlock(_component_WorkingHourSelect, {
              key: 0,
              onCancel: _ctx.onModalChancel,
              onSubmit: _ctx.onModalSubmit
            }, null, 8, ["onCancel", "onSubmit"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("li", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedDeadLineDate) = $event)),
          onFocus: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args))),
          onBlur: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onInputOutFocus && _ctx.onInputOutFocus(...args)))
        }, null, 544), [
          [_vModelText, _ctx.selectedDeadLineDate]
        ])
      ])
    ])
  ], 2))
}