
import { defineComponent, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { z } from "zod"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "Signup",
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const email = ref("")
    const password = ref("")
    const signup = async () => {
      try {
        const zEmail = z.string().email({ message: localizationText("email_invalid") })
        const result = zEmail.safeParse(email.value)
        if (!result.success) {
          alert(localizationText("email_invalid"))
          return
        }
        await userStore.createUserWithEmailAndPassword({
          email: email.value,
          password: password.value,
        })
      } catch (error: any) {
        router.replace({ name: "Error", params: { message: error } })
        return
      }
      router.replace({ name: "DashBoard" })
    }
    const onSendEmail = async () => {
      try {
        await userStore.sendLoginEmail({ email: email.value })
        window.alert(localizationText("auth_email_sended"))
      } catch (error: any) {
        router.push({ name: "Error", params: { message: error } })
      }
    }
    onMounted(async () => {
      try {
        if (window.location.href.includes("mode=signIn")) {
          await userStore.signInWithEmailLink({ href: window.location.href })
          router.replace({ name: "DashBoard" })
        }
      } catch (error) {
        return
      }
    })
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      var language =
        (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language
      if (language !== "ja") {
        language = "en"
      }
      return getLocalization(language, key)
    }
    return {
      email,
      password,
      signup,
      onSendEmail,
      localizationText,
    }
  },
})
