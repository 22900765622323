/* eslint-disable */
// @ts-nocheck
import {
  getAuth,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  signOut as _signOut,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  fetchSignInMethodsForEmail,
  updatePassword,
  updateEmail,
  sendPasswordResetEmail,
} from "firebase/auth"
import { getFirestore, doc, setDoc, onSnapshot, Unsubscribe, updateDoc } from "firebase/firestore"
import UserEntity from "@/domain/entities/user.entity"
import UserConverter from "@/infrastructure/converters/user.converter"
import { defineStore } from "pinia"

type State = {
  uid: string | null
  entity: UserEntity | null
  unsubscribe: Unsubscribe | null
}

export const useUserStore = defineStore("user", {
  state: (): State => {
    return {
      uid: null,
      entity: null,
      unsubscribe: null,
    }
  },
  getters: {
    workingMinutesPerDay(state): number {
      return state.entity?.workingMinutesPerDay ?? 0
    },
    userName(state) {
      if (state.entity === null) return ""
      return state.entity.name
    },
    isLoggedIn(state) {
      return state.uid !== null
    },
    isNotLoggedIn(state) {
      return state.uid === null
    },
    email() {
      const provider = getAuth().currentUser?.providerData.find(
        (provider) => provider.providerId === "password",
      )
      if (typeof provider === "undefined") return null
      return provider.email
    },
    /**
     * 残り時間（分）
     */
    remainingTime(state) {
      if (state.entity === null) return 0
      if (state.entity.startedAt === null) {
        return state.entity.workingMinutesPerDay
      }
      const now = new Date()
      const milliSeconds = now.getTime() - state.entity.startedAt.toDate().getTime()
      return state.entity.workingMinutesPerDay - Math.ceil(milliSeconds / 1000 / 60)
    },
    /**
     * 仕事を開始してからの経過時間（分）
     */
    elapsedWorkingTime(state) {
      if (state.entity === null) return 0
      // 仕事を開始していない場合は0時間経過
      if (state.entity.startedAt === null) {
        return 0
      }
      // return 120;
      const now = new Date()
      const milliSeconds = now.getTime() - state.entity.startedAt.toDate().getTime()
      return Math.ceil(milliSeconds / 1000 / 60)
    },
    remainingTimeText(state) {
      return () => {
        if (state.entity === null) return "-"
        if (state.entity.startedAt === null) {
          const minutes = state.entity.workingMinutesPerDay
          const h = Math.floor(minutes / 60)
          const m = minutes % 60
          return `${h}時間${m}分`
        }
        const now = new Date()
        const milliSeconds = now.getTime() - state.entity.startedAt.toDate().getTime()
        const minutes = state.entity.workingMinutesPerDay - Math.ceil(milliSeconds / 1000 / 60)
        const h = Math.floor(minutes / 60)
        const m = minutes % 60
        return `${h}時間${m}分`
      }
    },
    /**
     * 死ぬまでの働ける持ち時間（日）
     */
    allotedDayCount(): number {
      // 1:今日 - end 間で稼働日が何日あるかカウント
      // 2:start -end 間で休日(holiday)が何日あるかカウント
      // 1-2がこの関数の戻り値として正しい
      if (this.entity === null) return 0
      const nowYear = new Date().getFullYear()
      const nowMonth = new Date().getMonth()
      const nowDate = new Date().getDate()
      const minutes = new Date().getTime()
      const endTime = this.entity.endDate.value.getTime()
      const dayCount = Math.floor((endTime - minutes) / 1000 / 60 / 60 / 24)
      const allDates = [...Array(dayCount)].map((_, i) => {
        const date = new Date(nowYear, nowMonth, nowDate + i)
        return date
      })
      // 週の労働日の配列
      const workingWeekIndexes = this.entity?.workingWeekDayIndexes ?? [0, 1, 2, 3, 4]
      const allotedDates = allDates.filter((date) => {
        return workingWeekIndexes.includes(date.getDay())
      })
      return allotedDates.length
    },
  },
  actions: {
    setUid(val: any) {
      this.uid = val
    },
    setEntity(val: any) {
      this.entity = val
    },
    startSubscribe(): void {
      // TODO:すでにスタートしている状態ならサブスクを開始しないようにする
      const user = getAuth().currentUser
      if (user === null) return
      const self = this
      const userUnsub = onSnapshot(
        doc(getFirestore(), "users", user.uid).withConverter(UserConverter),
        (userProfile) => {
          self.entity = userProfile.data()
        },
      )
      this.unsubscribe = userUnsub
    },
    stopSubscribe() {
      // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
      // STOP SUBSCRIBE
      // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
      if (this.unsubscribe) {
        this.unsubscribe()
      }
    },
    async signInWithEmailAndPassword(val: any) {
      console.log("CALLED singin", val)
      await _signInWithEmailAndPassword(getAuth(), val.email, val.password)
      console.log("startSubscribe", this.startSubscribe)
      this.startSubscribe()
    },
    // https://firebase.google.com/docs/auth/web/password-auth?hl=ja#create_a_password-based_account
    async createUserWithEmailAndPassword(val: any) {
      // 2回同じメール・パスワードでアカウント作りにいったらどうする？
      await _createUserWithEmailAndPassword(getAuth(), val.email, val.password)
      await this.updateUser(UserEntity.create())
      this.startSubscribe()
    },
    async sendLoginEmail(props: { email: string }) {
      getAuth().useDeviceLanguage()
      getAuth().languageCode = "ja"
      await sendSignInLinkToEmail(getAuth(), props.email, {
        url: `${window.location.origin}/signup`,
        handleCodeInApp: true,
      })
      window.localStorage.setItem("emailForSignIn", props.email)
    },
    async sendPasswordResetEmail(props: { email: string }) {
      const auth = getAuth()
      auth.languageCode = "ja"
      await sendPasswordResetEmail(getAuth(), props.email)
    },
    async updatePassword(props: { password: string }) {
      try {
        const currentUser = getAuth().currentUser
        if (currentUser === null) return
        await updatePassword(currentUser, props.password)
        return null
      } catch (error) {
        await _signOut(getAuth())
        throw error
      }
    },
    async updateEmail(props: { email: string }) {
      try {
        const currentUser = getAuth().currentUser
        if (currentUser === null) return
        await updateEmail(currentUser, props.email)
        return null
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async signInWithEmailLink(props: { href: string }) {
      if (!isSignInWithEmailLink(getAuth(), props.href)) {
        throw new Error()
      }
      let email = window.localStorage.getItem("emailForSignIn")
      if (email === null) {
        email = window.prompt("Please provide your email for confirmation")
      }
      if (email === null) {
        throw new Error("Email is not found")
      }
      const result = await signInWithEmailLink(getAuth(), email, props.href)
      await this.updateUser(UserEntity.create())
      this.startSubscribe()
      window.localStorage.removeItem("emailForSignIn")
    },
    async signOut() {
      this.stopSubscribe()
      await _signOut(getAuth())
    },
    async getLoginMethods(props: { email: string }) {
      return fetchSignInMethodsForEmail(getAuth(), props.email)
    },
    async updateUser(entity: UserEntity) {
      const user = getAuth().currentUser
      if (user === null) return
      console.log("entity=>", entity)
      const docRef = doc(getFirestore(), "users", user.uid).withConverter(UserConverter)
      await setDoc(docRef, entity, { merge: true })
    },
    // 仕事を開始する
    async startWork() {
      const user = getAuth().currentUser
      if (user === null) return
      if (this.entity === null) return
      const newUser = this.entity.startWork()
      const docRef = doc(getFirestore(), "users", user.uid).withConverter(UserConverter)
      await setDoc(docRef, newUser, { merge: true })
    },
  },
})
