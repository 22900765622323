
import EventEntity from "@/domain/entities/event.entity"
import { useEventStore } from "@/stores/event"
import { useProfileModalStore } from "@/stores/profileModal"
import { defineComponent, computed, ComputedRef, ref } from "vue"
import TheHeader from "@/components/TheHeader.vue"
import SideMenu from "@/components/SideMenu.vue"
import TaskEdit from "@/views/TaskEdit.vue"
import UserProfile from "@/components/UserProfile.vue"
import TaskCalendar from "@/components/TaskCalendar.vue"
import EmailChange from "@/components/emailChange.vue"
import PasswordChange from "@/components/PasswordChange.vue"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "SideMenuLayout",
  components: {
    UserProfile,
    TheHeader,
    SideMenu,
    TaskEdit,
    TaskCalendar,
    EmailChange,
    PasswordChange,
  },
  setup() {
    // ユーザー設定モーダル
    const selectedModalMenu = ref<string>("profile")
    const isSelectedModalMenu = (menuName: string) => selectedModalMenu.value === menuName
    const onMenuClick = (menuName: string) => {
      console.log("menuName", menuName)
      selectedModalMenu.value = menuName
    }
    const modalStore = useProfileModalStore()
    const isModalOpen = computed(() => modalStore.isOpen)
    const closeModal = () => modalStore.close()

    //
    const eventStore = useEventStore()

    const isTaskSelected = computed(() => {
      return eventStore.isTaskSelected
    })
    const selectedTask: ComputedRef<EventEntity | null> = computed(() => {
      return eventStore.selectedTask
    })
    const taskId = computed(() => {
      if (selectedTask.value === null) {
        return ""
      }
      return selectedTask.value.id.value
    })
    let addTaskCount = ref(0)
    const onTaskAdded = () => {
      addTaskCount.value += 1
      eventStore.clearSelectedTask()
    }
    const refreshKey = computed(() => `${taskId.value}-${addTaskCount.value}`)
    const userStore = useUserStore()
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      isModalOpen,
      selectedModalMenu,
      isSelectedModalMenu,
      onMenuClick,
      closeModal,
      isTaskSelected,
      addTaskCount,
      onTaskAdded,
      refreshKey,
      localizationText,
    }
  },
})
