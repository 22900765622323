import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "taskList" }
const _hoisted_2 = { class: "subHead" }
const _hoisted_3 = {
  key: 0,
  class: "taskContainer"
}
const _hoisted_4 = {
  key: 0,
  class: "subHead"
}
const _hoisted_5 = { class: "subHead" }
const _hoisted_6 = { class: "subHead" }
const _hoisted_7 = {
  key: 0,
  class: "taskContainer"
}
const _hoisted_8 = { class: "subHead" }
const _hoisted_9 = {
  key: 1,
  class: "taskContainer"
}
const _hoisted_10 = { class: "subHead" }
const _hoisted_11 = { class: "subHead" }
const _hoisted_12 = {
  key: 0,
  class: "taskContainer"
}
const _hoisted_13 = { class: "subHead" }
const _hoisted_14 = { class: "subHead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskItem = _resolveComponent("TaskItem")!
  const _component_seciton = _resolveComponent("seciton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.taskDisplayMode === 'RANGE' && !_ctx.hasSearchResult)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasksInfo(_ctx.selectedTaskRange).taskDays, (day) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: day }, [
              (0 < _ctx.targetDayTasks(day).length)
                ? (_openBlock(), _createElementBlock("section", {
                    key: 0,
                    class: _normalizeClass(["taskContainer", { holiday: _ctx.isHoliday(day), notworkingday: _ctx.isNotWorkingDay(day) }])
                  }, [
                    _createElementVNode("h3", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.displayDate(day).date), 1),
                      _createElementVNode("i", null, _toDisplayString(_ctx.displayDate(day).week), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetDayTasks(day), (task) => {
                      return (_openBlock(), _createElementBlock("section", {
                        key: task.id.value,
                        class: "task"
                      }, [
                        _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                      ]))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_ctx.hasSomedayTask && 0 < _ctx.activeSomedayTasks.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSomedayTasks, (task) => {
                  return (_openBlock(), _createElementBlock("section", {
                    key: task.id.value,
                    class: "task"
                  }, [
                    _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.isDoneTaskShow && _ctx.hasInactiveTasks)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.isSelectedRangeIsCompleted)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.localizationText("完了")), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayDays('COMPLETED'), (day) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: day }, [
                    (0 < _ctx.inactiveTasks(day).length)
                      ? (_openBlock(), _createElementBlock("section", {
                          key: 0,
                          class: _normalizeClass(["taskContainer", { holiday: _ctx.isHoliday(day), notworkingday: _ctx.isNotWorkingDay(day) }])
                        }, [
                          _createElementVNode("h3", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.displayDate(day).date), 1),
                            _createElementVNode("i", null, _toDisplayString(_ctx.displayDate(day).week), 1)
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inactiveTasks(day), (task) => {
                            return (_openBlock(), _createElementBlock("section", {
                              key: task.id.value,
                              class: "task"
                            }, [
                              _createVNode(_component_TaskItem, {
                                task: task,
                                draggable: false
                              }, null, 8, ["task"])
                            ]))
                          }), 128))
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.taskDisplayMode === 'TAG' && !_ctx.hasSearchResult)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedTagTaskDays, (day) => {
            return (_openBlock(), _createElementBlock("section", {
              key: day.toString(),
              class: _normalizeClass(["taskContainer", { holiday: _ctx.isHoliday(day), notworkingday: _ctx.isNotWorkingDay(day) }])
            }, [
              _createElementVNode("h3", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.displayDate(day).date), 1),
                _createElementVNode("i", null, _toDisplayString(_ctx.displayDate(day).week), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedTagTasks(day), (task) => {
                return (_openBlock(), _createElementBlock("section", {
                  key: task.id.value,
                  class: "task"
                }, [
                  _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                ]))
              }), 128))
            ], 2))
          }), 128)),
          (!_ctx.hasSearchResult && 0 < _ctx.selectedNoDateTagTasks.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.localizationText("いつか")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedNoDateTagTasks, (task) => {
                  return (_openBlock(), _createElementBlock("section", {
                    key: task.id.value,
                    class: "task"
                  }, [
                    _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (0 < _ctx.tagInactiveTasks.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
                _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.localizationText("完了")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagInactiveTasks, (task) => {
                  return (_openBlock(), _createElementBlock("section", {
                    key: task.id.value,
                    class: "task"
                  }, [
                    _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.hasSearchResult)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.localizationText("search_result")), 1),
          (_ctx.hasNodateTasks)
            ? (_openBlock(), _createElementBlock("section", _hoisted_12, [
                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.localizationText("いつか")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResultNoDateTasks, (task) => {
                  return (_openBlock(), _createBlock(_component_seciton, {
                    key: task.id.value,
                    class: "task"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTaskDays, (day) => {
            return (_openBlock(), _createElementBlock("section", {
              key: day.toString(),
              class: _normalizeClass(["taskContainer", { holiday: _ctx.isHoliday(day), notworkingday: _ctx.isNotWorkingDay(day) }])
            }, [
              _createElementVNode("h3", _hoisted_14, [
                _createTextVNode(_toDisplayString(_ctx.displayDate(day).date), 1),
                _createElementVNode("i", null, _toDisplayString(_ctx.displayDate(day).week), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResultTasks, (task) => {
                return (_openBlock(), _createElementBlock("section", {
                  key: task.id.value,
                  class: "task"
                }, [
                  _createVNode(_component_TaskItem, { task: task }, null, 8, ["task"])
                ]))
              }), 128))
            ], 2))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}