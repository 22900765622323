import { defineStore } from "pinia"
import master from "@/assets/JSON/localize.json"
type State = {
  isOpen: boolean
  master: any
}

export const useLocalizationStore = defineStore("localization", {
  state: (): State => {
    return {
      isOpen: false,
      master: master,
    }
  },
  getters: {
    getLocalization() {
      return (language: string, key: string) => {
        if (this.master[key] === undefined) {
          return key
        }
        return this.master[key][language]
      }
    },
  },
  actions: {
    // open() {
    //   this.isOpen = true
    // },
    // close() {
    //   this.isOpen = false
    // },
  },
})
