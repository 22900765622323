import {
  getFirestore,
  collection,
  query,
  orderBy,
  doc,
  setDoc,
  deleteDoc,
  where,
  onSnapshot,
  Unsubscribe,
  writeBatch,
  getDocs,
  increment,
} from "firebase/firestore"
import EventStatus from "@/domain/valueObjects/eventStatus"
import EventEntity from "@/domain/entities/event.entity"
import TagEntity from "@/domain/entities/tag.entity"
import TaskConverter from "@/infrastructure/converters/task.converter"
import EventType from "@/domain/valueObjects/eventType"
import DateTime from "@/domain/valueObjects/dateTime"
import Id from "@/domain/valueObjects/id"
import { defineStore } from "pinia"
import { useUserStore } from "@/stores/user"

type FilterPattern =
  | "AFTER_TOADY"
  | "TODAY"
  | "TOMORROW"
  | "THIS_WEEK"
  | "NEXT_WEEK"
  | "THIS_MONTH"
  | "NEXT_MONTH"
  | "SOMEDAY"
  | "COMPLETED"

type State = {
  selectedTask: EventEntity | null
  selectedTag: TagEntity | null
  selectedTaskRange:
    | "AFTER_TOADY"
    | "TODAY"
    | "TOMORROW"
    | "THIS_WEEK"
    | "NEXT_WEEK"
    | "THIS_MONTH"
    | "NEXT_MONTH"
    | "SOMEDAY"
    | "COMPLETED"
  taskDisplayMode: "RANGE" | "TAG"
  events: EventType[]
  searchTasks: EventEntity[]
  allTasks: EventEntity[]
  noDateTasks: EventEntity[]
  allHolidays: EventEntity[]
  _unsubscribeAllTasks: Unsubscribe | null
  _unsubscribeNoDateTasks: Unsubscribe | null
  eventStatuses: EventStatus[]
}

export const useEventStore = defineStore("event", {
  state: (): State => {
    return {
      selectedTask: null,
      selectedTag: null,
      selectedTaskRange: "AFTER_TOADY",
      taskDisplayMode: "RANGE",
      searchTasks: [],
      events: [],
      allTasks: [],
      noDateTasks: [],
      allHolidays: [],
      _unsubscribeAllTasks: null,
      _unsubscribeNoDateTasks: null,
      eventStatuses: [EventStatus.inProgress, EventStatus.untreated, EventStatus.done],
    }
  },
  getters: {
    tasks(state) {
      return [...state.allTasks, ...state.noDateTasks]
    },
    completedTasks(state): EventEntity[] {
      return [...state.allTasks, ...state.noDateTasks].filter((task) => {
        return task.isDone
      })
    },
    holidayEvents(state): EventEntity[] {
      return state.allHolidays
    },
    findTask(state) {
      return (taskId: Id): EventEntity | null => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        const task = allTasks.find((task) => task.id.value === taskId.value) ?? null
        return task
      }
    },
    /**
     * selectedTaskと違いリアルタイムに値が変わる
     */
    selectedTaskReactive(state): EventEntity | null {
      const selectedTask = state.selectedTask
      if (selectedTask === null) {
        return null
      }
      const allTasks = [...state.allTasks, ...state.noDateTasks]
      const task = allTasks.find((task) => task.id.value === selectedTask.id.value) ?? null
      return task
    },
    /**
     * 選択しているタスクが存在するか
     */
    isTaskSelected(state): boolean {
      return state.selectedTask !== null
    },
    /**
     * 今日以降のタスク
     */
    allAfterTodayTasks(state): EventEntity[] {
      const today = DateTime.now()
      const dateFrom = today.toDate()
      const tasks = state.allTasks.filter((task) => {
        return task.date !== null && task.date?.value >= dateFrom
      })
      // 日付なしのタスクと結合する
      return [...tasks, ...state.noDateTasks]
    },
    /**
     * 全ての日付の配列
     */
    afterTodayTaskDays(state): Date[] {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      // 開始日（今週の最初の日）
      const dateFrom = today.toDate()

      const tasks = state.allTasks.filter(
        (task) => task.date !== null && task.date?.value >= dateFrom,
      )
      // 重複を含むタスクの日にちの配列
      const redundantDateTexts = tasks
        .map((task) => {
          return task.date?.toDateText()
        })
        .filter((date): date is string => {
          return typeof date !== "undefined"
        })
      // 重複を削除した配列
      const dateTexts = Array.from(new Set(redundantDateTexts))
      return dateTexts.map((dateText) => {
        return DateTime.fromText(dateText).toDate()
      })
    },
    /**
     * 今日のタスクを返す
     */
    todayTasks(state): EventEntity[] {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      const dateFrom = today.toDate()
      const dateTo = today.toNextDay().toDate()
      return state.allTasks.filter((task) => {
        return task.date !== null && task.date?.value >= dateFrom && task.date?.value < dateTo
      })
    },
    todayTaskDays(): Date[] {
      const tasks = this.todayTasks
      const today = DateTime.now()
      return tasks.length === 0
        ? []
        : [new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate())]
    },
    /**
     * 明日のタスクを返す
     */
    tomorrowTasks(state): EventEntity[] {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      const dateFrom = today.toNextDay().toDate()
      const dateTo = today.toNextDay().toNextDay().toDate()
      return state.allTasks.filter((task) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
    },
    tomorrowTaskDays(): Date[] {
      const tasks = this.tomorrowTasks
      const today = DateTime.now()
      return tasks.length === 0
        ? []
        : [new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate() + 1)]
    },
    /**
     * 今週のタスク
     */
    thisWeekTasks(state) {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      const dateFrom = today.toWeekStart().toDate()
      const dateTo = today.toWeekEnd().toNextDay().toDate()
      return state.allTasks.filter((task) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
    },
    /**
     * 今週の稼働日の日付の配列
     */
    thisWeekTaskDays(state) {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      // 開始日（今週の最初の日）
      const dateFrom = today.toWeekStart().toDate()
      // 開始日（今週の最後の日）
      const dateTo = today.toWeekEnd().toNextDay().toDate()
      const tasks = state.allTasks.filter((task) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
      // 重複を含むタスクの日にちのテキストの配列
      const redundantDateTexts = tasks
        .map((task) => {
          return task.date?.toDateText()
        })
        .filter((date): date is string => {
          return typeof date !== "undefined"
        })
      // 重複を削除したテキストの配列
      const dateTexts = Array.from(new Set(redundantDateTexts))
      return dateTexts.map((dateText) => {
        return DateTime.fromText(dateText).toDate()
      })
    },
    /**
     * 今週の稼働日数
     * 当日含む今日以降
     */
    thisWeekWorkingDaysCount(state) {
      return (workingDayCount = 5) => {
        const today = DateTime.now()
        // 開始日（今週の最初の日）
        const dateFrom = today.toWeekStart().toDate()
        // 終了日（今週の最後の日）
        const dateTo = today.toWeekEnd().toNextDay().toDate()
        // 今週の平日の休暇の配列
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value < dateTo
            )
          })
          .filter((holiday) => holiday.date !== null && holiday.date?.isWeekday)
        const holidaysCount = holidays.length
        // 今週の平日数 - 今週の平日の休暇数
        return workingDayCount - holidaysCount
      }
    },
    /**
     * 今週の稼働日数（今日含む）
     */
    thisWeekWorkingDaysCountAfterToday(state) {
      return (workingWeekIndexes = [1, 2, 3, 4, 5]) => {
        const today = DateTime.now()
        // 開始日（今日）
        const dateFrom = today.toDate()
        // 終了日（今週の最後の日）
        const dateTo = today.toWeekEnd().toNextDay().toDate()
        // 今週の平日の休暇の配列
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value < dateTo
            )
          })
          .filter((holiday) => holiday.date !== null && holiday.date?.isWeekday)
        const holidaysCount = holidays.length
        // 平日数
        // const weekdaysCount = workingDayCount - Math.min(dateFrom.getDay(), 5);
        const workingWeekIndexesAfterToday = workingWeekIndexes.filter((index) => {
          return dateFrom.getDay() <= index
        })
        const workingDaysCount = workingWeekIndexesAfterToday.length
        // 今週の平日数 - 今週の平日の休暇数
        return Math.max(0, workingDaysCount - holidaysCount)
      }
    },
    /**
     * 来週のタスクを返す
     */
    nextWeekTasks(state) {
      if (state.allTasks.length === 0) return state.allTasks
      const today = DateTime.now()
      // 開始日（来週の最初の日）
      const dateFrom = today.toNextWeek().toWeekStart().toDate()
      // 終了日（来週の最後の日）
      const dateTo = today.toNextWeek().toWeekEnd().toNextDay().toDate()
      // 今週のタスクの配列
      return state.allTasks.filter((task: EventEntity) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
    },
    /**
     * 今週のタスクのある日の配列
     */
    nextWeekTaskDays(state) {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      // 開始日（来週の最初の日）
      const dateFrom = today.toNextWeek().toWeekStart().toDate()
      // 終了日（来週の最後の日）
      const dateTo = today.toNextWeek().toWeekEnd().toNextDay().toDate()
      // 今週のタスクの配列
      const tasks = state.allTasks.filter((task) => {
        return task.date !== null && task.date?.value >= dateFrom && task.date?.value < dateTo
      })
      // 重複を含むタスクの日にちのテキストの配列
      const redundantDateTexts = tasks
        .map((task) => {
          return task.date?.toDateText()
        })
        .filter((date): date is string => {
          return typeof date !== "undefined"
        })
      // 重複を削除したテキストの配列
      const dateTexts = Array.from(new Set(redundantDateTexts))
      return dateTexts.map((dateText) => {
        return DateTime.fromText(dateText).toDate()
      })
    },
    /**
     * 来週の稼働日数
     */
    nextWeekWorkingDaysCount(state) {
      return (workingDayCount = 5) => {
        const today = DateTime.now()
        // 開始日（今週の最初の日）
        const dateFrom = today.toNextWeek().toWeekStart().toDate()
        // 終了日（来週の最後の日）
        const dateTo = today.toNextWeek().toWeekEnd().toNextDay().toDate()
        // 今週の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value < dateTo
            )
          })
          .filter((holiday) => holiday.date !== null && holiday.date?.isWeekday)
        const holidaysCount = holidays.length
        // 今週の平日数 - 今週の平日の休暇数
        return workingDayCount - holidaysCount
      }
    },
    /**
     * 来週の稼働日数（今日以降）
     * nextWeekWorkingDaysCountと変わらない
     */
    nextWeekWorkingDaysCountAfterToday(state) {
      return (workingDayCount = 5) => {
        const today = DateTime.now()
        // 開始日（今週の最初の日）
        const dateFrom = today.toNextWeek().toWeekStart().toDate()
        // 終了日（来週の最後の日）
        const dateTo = today.toNextWeek().toWeekEnd().toNextDay().toDate()
        // 今週の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value < dateTo
            )
          })
          .filter((holiday) => holiday.date !== null && holiday.date?.isWeekday)
        const holidaysCount = holidays.length
        // 来週の平日数 - 来週の平日の休暇数
        return workingDayCount - holidaysCount
      }
    },
    /**
     * 今月のタスクを返す
     */
    thisMonthTasks(state) {
      if (state.allTasks.length === 0) return state.allTasks
      const today = DateTime.now()
      // 今月の最初の日
      const dateFrom = today.toMonthStart().toDate()
      // 今月の最初の日
      const dateTo = today.toMonthEnd().toNextDay().toDate()
      return state.allTasks.filter((task: EventEntity) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
    },
    /**
     * 今月のタスクのある日の配列
     */
    thisMonthTaskDays(state) {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      // 開始日（今月の最初の日）
      const dateFrom = today.toMonthStart().toDate()
      // 終了日（今月の最後の日）
      const dateTo = today.toMonthEnd().toNextDay().toDate()
      // 該当するタスク
      const tasks = state.allTasks.filter((task) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value <= dateTo
      })
      // 重複を含むタスクの日にちの配列
      const redundantDays = tasks
        .map((task) => task.date?.value.getDate())
        .filter((date): date is number => typeof date !== "undefined")
      // 重複を削除した配列
      const days = Array.from(new Set(redundantDays))
      return days.map((date) => {
        return new Date(today.value.getFullYear(), today.value.getMonth(), date)
      })
    },
    /**
     * 今月の稼働日数
     */
    thisMonthWorkingDaysCount(state) {
      return (workingWeekIndexes = [1, 2, 3, 4, 5]) => {
        const today = DateTime.now()
        // 開始日（今月の最初の日）
        const dateFrom = today.toMonthStart().toDate()
        // 終了日（今月の最後の日）
        const dateTo = today.toMonthEnd().toDate()
        // 今月の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value <= dateTo
            )
          })
          .filter((holiday) => {
            return holiday.date !== null && holiday.date?.isWeekday
          })
        // 稼働日
        const workingDaysCount = today.toMonthWorkingDaysCount(workingWeekIndexes)
        // 今月の平日の日数 - 今月の平日の休暇数
        return workingDaysCount - holidays.length
      }
    },
    /**
     * 今月の稼働日数（今日以降）
     */
    thisMonthWorkingDaysCountAfterToday(state) {
      return (workingWeekIndexes = [1, 2, 3, 4, 5]) => {
        const today = DateTime.now()
        // 開始日（今月の最初の日 or 今日）
        const dateFrom = today.toDate()
        // 終了日（今月の最後の日）
        const dateTo = today.toMonthEnd().toDate()
        // 今月の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value <= dateTo
            )
          })
          .filter((holiday) => holiday.date !== null && holiday.date?.isWeekday)
        const allWorkingDaysCount = today.toMonthWorkingDaysCountFrom(
          dateFrom.getDate(),
          workingWeekIndexes,
        )
        const workingDaysCount = allWorkingDaysCount
        // 今月の平日数 - 今月の平日の休暇数
        return workingDaysCount - holidays.length
      }
    },
    /**
     * 来月のタスクを返す
     */
    nextMonthTasks(state) {
      if (state.allTasks.length === 0) return state.allTasks
      const today = DateTime.now()
      const dateTimeFrom = today.toMonthStart().toNextMonth()
      const dateFrom = dateTimeFrom.toDate()
      const dateTo = dateTimeFrom.toMonthEnd().toNextDay().toDate()
      return state.allTasks.filter((task: EventEntity) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value < dateTo
      })
    },
    /**
     * 来月のタスクのある日の配列
     */
    nextMonthTaskDays(state) {
      if (state.allTasks.length === 0) {
        return []
      }
      const today = DateTime.now()
      const dateTimeFrom = today.toMonthStart().toNextMonth()
      const dateFrom = dateTimeFrom.toDate()
      const dateTo = dateTimeFrom.toMonthEnd().toNextDay().toDate()
      const tasks = state.allTasks.filter((task) => {
        return task.date !== null && dateFrom <= task.date?.value && task.date?.value <= dateTo
      })
      // 重複を含むタスクの日にちの配列
      const redundantDays = tasks
        .map((task) => task.date?.value.getDate())
        .filter((date): date is number => typeof date !== "undefined")
      // 重複を削除した配列
      const days = Array.from(new Set(redundantDays))
      return days.map((date) => {
        return new Date(dateFrom.getFullYear(), dateFrom.getMonth(), date)
      })
    },
    /**
     * 来月の稼働日数
     */
    nextMonthWorkingDaysCount(state) {
      return (workingWeekIndexes = [1, 2, 3, 4, 5]) => {
        const today = DateTime.now()
        // 開始日（来月の最初の日）
        const dateTimeFrom = today.toMonthStart().toNextMonth()
        const dateFrom = dateTimeFrom.toDate()
        // 終了日（来月の最後の日）
        const dateTo = dateTimeFrom.toMonthEnd().toNextDay().toDate()
        // 来月の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value <= dateTo
            )
          })
          .filter((holiday) => {
            return holiday.date !== null && holiday.date?.isWeekday
          })
        // 平日数
        const workingDaysCount = today.toNextMonth().toMonthWorkingDaysCount(workingWeekIndexes)
        // 来月の平日の日数 - 来月の平日の休暇数
        return workingDaysCount - holidays.length
      }
    },
    /**
     * 来月の稼働日数（今日以降）
     * nextMonthWorkingDaysCountと変わらない
     */
    nextMonthWorkingDaysCountAfterToday(state) {
      return (workingWeekIndexes = [1, 2, 3, 4, 5]) => {
        const today = DateTime.now()
        // 今月の最初の日
        const monthFrom = today.toNextMonth().toMonthStart().toDate()
        // 開始日（来月の最初の日 or 今日）
        const dateFrom = monthFrom <= today.toDate() ? today.toDate() : monthFrom
        // 終了日（来月の最後の日）
        const dateTo = today.toNextMonth().toMonthEnd().toDate()
        // 来月の平日の休暇
        const holidays = state.allHolidays
          .filter((holiday) => {
            return (
              holiday.date !== null &&
              holiday.date?.value >= dateFrom &&
              holiday.date?.value <= dateTo
            )
          })
          .filter((holiday) => {
            return holiday.date !== null && holiday.date?.isWeekday
          })
        // 平日数
        const workingDaysCount = today.toNextMonth().toMonthWorkingDaysCount(workingWeekIndexes)
        // 来月の平日の日数 - 来月の平日の休暇数
        return workingDaysCount - holidays.length
      }
    },
    /**
     * 死ぬまでの稼働日数（今日以降）
     * nextMonthWorkingDaysCountと変わらない
     */
    allotedDaysCount(state) {
      const userStore = useUserStore()

      const dateFrom = DateTime.now()

      const holidays = state.allHolidays
        .filter((holiday) => {
          return holiday.date !== null && dateFrom.value <= holiday.date?.value
        })
        .filter((holiday) => {
          return holiday.date?.isWeekday
        })

      return userStore.allotedDayCount - holidays.length
    },
    /**
     * いつかのタスクを返す
     */
    somedayTasks(state): EventEntity[] {
      return state.noDateTasks
    },
    /**
     * タグのタスクの稼働時間（分）を返す
     */
    tagTaskWorkingTime() {
      return (tagId: Id): number => {
        const allTasks: EventEntity[] = [...this.allAfterTodayTasks]
        const tagTasks = allTasks
          .filter((task) => {
            return task.tagIds.map((tagId) => tagId.value).includes(tagId.value)
          })
          .filter((task) => task.isNotDone)
        const minutes = tagTasks.reduce((acc, task) => {
          return acc + task.workingMinutes
        }, 0)
        return minutes
      }
    },
    /**
     * 指定タグの未完了のタスクカウントを返す
     */
    activeTagTaskCount(state) {
      return (tagId: Id) => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        const tagTasks = allTasks.filter((task) => {
          return task.tagIds.map((tagId) => tagId.value).includes(tagId.value)
        })
        const activeTasks = tagTasks.filter((task) => {
          return task.isNotDone
        })
        return activeTasks.length
      }
    },
    /**
     * 指定タグの完了しているタスクカウントを返す
     */
    inactiveTagTaskCount(state) {
      return (tagId: Id): number => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        const tagTasks = allTasks.filter((task) => {
          return task.tagIds.map((tagId) => tagId.value).includes(tagId.value)
        })
        return tagTasks.filter((task) => task.isDone).length
      }
    },
    /**
     * 指定タグの残稼働時間を返す（分）
     */
    tagTaskActiveWorkingTime(state) {
      return (tagId: Id): number => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        const tagTasks = allTasks.filter((task) => {
          return task.tagIds.map((tagId) => tagId.value).includes(tagId.value)
        })
        const tasks = tagTasks.filter((task) => task.isNotDone)
        return tasks.reduce((acc, task) => acc + task.workingMinutes, 0)
      }
    },
    /**
     * 指定タグの消費予定の日数
     */
    aggregateInactiveTagTaskDays() {
      const userStore = useUserStore()
      return (tagId: Id): number => {
        const minutes = this.tagTaskActiveWorkingTime(tagId)
        return Math.ceil(minutes / userStore.workingMinutesPerDay)
      }
    },
    /**
     * 指定タグの完了稼働時間を返す（分）
     */
    tagTaskInactiveWorkingTime(state) {
      return (tagId: Id): number => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        const tagTasks = allTasks.filter((task) => {
          return task.tagIds.map((tagId) => tagId.value).includes(tagId.value)
        })
        const tasks = tagTasks.filter((task) => task.isDone)
        return tasks.reduce((acc, task) => acc + task.workingMinutes, 0)
      }
    },
    /**
     * 指定タグの消費した日数
     */
    aggregateActiveTagTaskDays() {
      const userStore = useUserStore()
      return (tagId: Id): number => {
        const minutes = this.tagTaskInactiveWorkingTime(tagId)
        return Math.ceil(minutes / userStore.workingMinutesPerDay)
      }
    },
    totalInfo() {
      return (range: string) => {
        // const user = rootState !== null ? (rootState as any)["user/entity"] as UserEntity : null;
        // const user = rootGetters['user/entity'] as UserEntity | null ?? null
        const user = useUserStore().entity ?? null

        // 週の労働日の配列
        const workingWeekIndexes = user?.workingWeekDayIndexes ?? [0, 1, 2, 3, 4]

        // 週の労働日数
        const workingDayCount = workingWeekIndexes.length

        let tasks = [] as EventEntity[]
        let taskDays = [] as any[]
        let isDoneTasksCount = 0
        let totalWorkingTime = 0
        let totalWorkedTime = 0
        let totalRemainingWorkingTime = 0
        let workingDaysCount = 0
        let remainingWorkingDaysCount = 0
        if (range === "AFTER_TOADY") {
          tasks = this.allAfterTodayTasks
          taskDays = this.afterTodayTaskDays
        }
        if (range === "TODAY") {
          tasks = this.todayTasks
          const today = DateTime.now()
          taskDays =
            tasks.length === 0
              ? []
              : [new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate())]
        }
        if (range === "TOMORROW") {
          tasks = this.tomorrowTasks
          const today = DateTime.now()
          taskDays =
            tasks.length === 0
              ? []
              : [
                  new Date(
                    today.value.getFullYear(),
                    today.value.getMonth(),
                    today.value.getDate() + 1,
                  ),
                ]
        }
        if (range === "THIS_WEEK") {
          tasks = this.thisWeekTasks
          taskDays = this.thisWeekTaskDays
          isDoneTasksCount = 0
          workingDaysCount = this.thisWeekWorkingDaysCount(workingDayCount)
          remainingWorkingDaysCount = this.thisWeekWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (range === "NEXT_WEEK") {
          tasks = this.nextWeekTasks
          taskDays = this.nextWeekTaskDays
          workingDaysCount = this.nextWeekWorkingDaysCount(workingDayCount)
          remainingWorkingDaysCount = this.nextWeekWorkingDaysCountAfterToday(workingDayCount)
        }
        if (range === "THIS_MONTH") {
          tasks = this.thisMonthTasks
          taskDays = this.thisMonthTaskDays
          workingDaysCount = this.thisMonthWorkingDaysCount(workingWeekIndexes)
          remainingWorkingDaysCount = this.thisMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (range === "NEXT_MONTH") {
          tasks = this.nextMonthTasks
          taskDays = this.nextMonthTaskDays
          workingDaysCount = this.nextMonthWorkingDaysCount(workingWeekIndexes)
          remainingWorkingDaysCount = this.nextMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (range === "SOMEDAY") {
          tasks = this.somedayTasks
        }
        if (range === "COMPLETED") {
          tasks = this.completedTasks
        }
        tasks.forEach((task: EventEntity) => {
          if (task.isDone) {
            isDoneTasksCount += 1
            totalWorkedTime += task.workingMinutes
          } else {
            totalRemainingWorkingTime += task.workingMinutes
          }
          totalWorkingTime += task.workingMinutes
        })
        return {
          tasks,
          taskDays,
          taskCount: tasks.length,
          untreatedTasksCount: tasks.length - isDoneTasksCount,
          isDoneTasksCount,
          totalWorkingTime,
          totalWorkedTime,
          totalRemainingWorkingTime,
          workingDaysCount,
          remainingWorkingDaysCount,
        }
      }
    },
    filterTasksByDateRange(state) {
      return (from: DateTime, to: DateTime): EventEntity[] => {
        if (state.allTasks.length === 0) {
          return []
        }
        return state.allTasks
          .filter((task) => {
            return (
              task.date !== null &&
              from.toDate() <= task.date?.value &&
              task.date?.value < to.toDate()
            )
          })
          .sort((a, b) => {
            const timeA = a.completedAt?.value.getTime() ?? 0
            const timeB = b.completedAt?.value.getTime() ?? 0
            return timeB - timeA
          })
      }
    },
    filterTasks() {
      return (pattern: FilterPattern): EventEntity[] => {
        if (pattern === "AFTER_TOADY") {
          return this.allAfterTodayTasks
        }
        if (pattern === "TODAY") {
          // console.log("----------")
          // console.log(pattern)
          return this.filterTasksByDateRange(DateTime.now(), DateTime.now().toNextDay())
        }
        if (pattern === "TOMORROW") {
          return this.filterTasksByDateRange(
            DateTime.now().toNextDay(),
            DateTime.now().toNextDay().toNextDay(),
          )
        }
        if (pattern === "THIS_WEEK") {
          return this.filterTasksByDateRange(
            DateTime.now().toWeekStart(),
            DateTime.now().toWeekEnd().toNextDay(),
          )
        }
        if (pattern === "NEXT_WEEK") {
          return this.filterTasksByDateRange(
            DateTime.now().toNextWeek().toWeekStart(),
            DateTime.now().toNextWeek().toWeekEnd().toNextDay(),
          )
        }
        if (pattern === "THIS_MONTH") {
          return this.filterTasksByDateRange(
            DateTime.now().toMonthStart(),
            DateTime.now().toMonthEnd().toNextDay(),
          )
        }
        if (pattern === "NEXT_MONTH") {
          return this.filterTasksByDateRange(
            DateTime.now().toMonthStart().toNextMonth(),
            DateTime.now().toMonthStart().toNextMonth().toMonthEnd().toNextDay(),
          )
        }
        if (pattern === "SOMEDAY") return this.somedayTasks
        if (pattern === "COMPLETED") return this.completedTasks
        return []
      }
    },
    /**
     * タスクのある日の配列を返す
     */
    filterTaskDays() {
      return (pattern: FilterPattern): Date[] => {
        if (pattern === "SOMEDAY") return []
        const tasks = this.filterTasks(pattern)
        // 重複を含むタスクの日にちのテキストの配列
        const redundantDateTexts = tasks
          .map((task) => {
            return task.date?.toDateText()
          })
          .filter((date): date is NonNullable<typeof date> => {
            return typeof date !== "undefined"
          })
        // 重複を削除したテキストの配列
        const dateTexts = Array.from(new Set(redundantDateTexts))
        // 日付（Date）の配列
        return dateTexts.map((dateText) => {
          return DateTime.fromText(dateText).toDate()
        })
      }
    },
    /**
     * タスクのある日の配列を返す
     */
    filterInactiveTaskDays() {
      return (pattern: FilterPattern): Date[] => {
        const tasks = this.filterTasks(pattern)
        // 重複を含むタスクの日にちのテキストの配列
        const redundantDateTexts = tasks
          .sort((a, b) => {
            return (b.completedAt?.value.getTime() ?? 0) - (a.completedAt?.value.getTime() ?? 0)
          })
          .map((task) => {
            return task.completedAt?.toDateText()
          })
          .filter((date): date is NonNullable<typeof date> => {
            return typeof date !== "undefined"
          })
        // 重複を削除したテキストの配列
        const dateTexts = Array.from(new Set(redundantDateTexts))
        // 日付（Date）の配列
        return dateTexts.map((dateText) => {
          return DateTime.fromText(dateText).toDate()
        })
      }
    },
    /**
     * タスクのある日の配列を返す
     */
    filterInactiveTaskDaysReverse() {
      return (pattern: FilterPattern): Date[] => {
        const tasks = this.filterTasks(pattern)
        // 重複を含むタスクの日にちのテキストの配列
        const redundantDateTexts = tasks
          .sort((a, b) => {
            return (b.completedAt?.value.getTime() ?? 0) - (a.completedAt?.value.getTime() ?? 0)
          })
          .map((task) => {
            return task.completedAt?.toDateText()
          })
          .filter((date): date is NonNullable<typeof date> => {
            return typeof date !== "undefined"
          })
        // 重複を削除したテキストの配列
        const dateTexts = Array.from(new Set(redundantDateTexts))
        // 日付（Date）の配列
        return dateTexts
          .map((dateText) => {
            return DateTime.fromText(dateText).toDate()
          })
          .reverse()
      }
    },
    /**
     * rangeTaskCount: tasks.length,
     * 指定範囲のタスクのカウントを返す（完了も含む）
     */
    countTasks() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        return tasks.length
      }
    },
    /**
     * 指定範囲のタスクの未完了のカウントを返す
     */
    countUntreatedTasks() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        const doneTasksCount = this.countDoneTasks(pattern)
        return tasks.length - doneTasksCount
      }
    },
    /**
     * 完了のタスクの数
     */
    countDoneTasks() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        let count = 0
        for (const task of tasks) {
          if (task.isDone) {
            count += 1
          }
        }
        return count
      }
    },
    /**
     * 指定期間の残り持ち時間（分）
     */
    calcRemainingAllotedTimeMinutes() {
      return (pattern: FilterPattern): number => {
        const allotedTime = this.calcAllotedTimeMinutes(pattern)
        const taskTime = this.calcTotalWorkingTime(pattern)
        return allotedTime - taskTime
      }
    },
    /**
     * 指定期間の残り持ち時間（時）
     */
    calcRemainingAllotedTimeHours() {
      return (pattern: FilterPattern): number => {
        const minutes = this.calcRemainingAllotedTimeMinutes(pattern)
        return Math.floor(minutes / 60)
      }
    },
    /**
     * 指定期間の残り持ち時間（日）
     */
    calcRemainingAllotedTimeDays() {
      const userStore = useUserStore()
      return (pattern: FilterPattern): number => {
        const minutes = this.calcRemainingAllotedTimeMinutes(pattern)
        return Math.floor(minutes / userStore.workingMinutesPerDay)
      }
    },
    /**
     * 期間内のタスクの時間の合計値を返す
     */
    calcTotalWorkingTime() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        let time = 0
        for (const task of tasks) {
          time += task.workingMinutes
        }
        return time
      }
    },
    /**
     * 労働した時間を集計する
     */
    aggregateWorkedDays() {
      const userStore = useUserStore()
      return (pattern: FilterPattern): number => {
        const minutes = this.calcTotalWorkingTime(pattern)
        return Math.ceil(minutes / userStore.workingMinutesPerDay)
      }
    },
    /**
     * 期間内の完了したタスクの時間を集計する
     */
    calcTotalWorkedTime() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        let time = 0
        for (const task of tasks) {
          if (task.isDone) {
            time += task.workingMinutes
          }
        }
        return time
      }
    },
    /**
     * 期間内の完了してないタスクの時間を集計する
     * 指定範囲の未完了タスクの稼働時間合計
     */
    calcTotalRemainingWorkingTime() {
      return (pattern: FilterPattern): number => {
        const tasks = this.filterTasks(pattern)
        let time = 0
        for (const task of tasks) {
          if (task.isNotDone) {
            time += task.workingMinutes
          }
        }
        return time
      }
    },
    countWorkingDays() {
      const user = useUserStore().entity ?? null

      // 週の労働日の配列
      const workingWeekIndexes = user?.workingWeekDayIndexes ?? [0, 1, 2, 3, 4]

      // 週の労働日数
      const workingDayCount = workingWeekIndexes.length

      return (pattern: FilterPattern): number => {
        if (pattern === "AFTER_TOADY") return 0
        if (pattern === "TODAY") return 0
        if (pattern === "TOMORROW") return 0
        if (pattern === "THIS_WEEK") return this.thisWeekWorkingDaysCount(workingDayCount)
        if (pattern === "NEXT_WEEK") return this.nextWeekWorkingDaysCount(workingDayCount)
        if (pattern === "THIS_MONTH") return this.thisMonthWorkingDaysCount(workingWeekIndexes)
        if (pattern === "NEXT_MONTH") return this.nextMonthWorkingDaysCount(workingWeekIndexes)
        if (pattern === "SOMEDAY") return 0
        if (pattern === "COMPLETED") return 0
        return 0
      }
    },
    /**
     * ユーザの持ち時間を集計する
     */
    calcAllotedTimeMinutes() {
      const userStore = useUserStore()
      const allotedTime = userStore.workingMinutesPerDay
      const user = userStore.entity ?? null
      const workingWeekIndexes = user?.workingWeekDayIndexes ?? [0, 1, 2, 3, 4]
      const workingDayCount = workingWeekIndexes.length
      return (pattern: FilterPattern): number => {
        if (pattern === "TODAY") {
          return allotedTime
        }
        if (pattern === "TOMORROW") {
          return allotedTime
        }
        if (pattern === "THIS_WEEK") {
          return allotedTime * this.thisWeekWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "NEXT_WEEK") {
          return allotedTime * this.nextWeekWorkingDaysCountAfterToday(workingDayCount)
        }
        if (pattern === "THIS_MONTH") {
          return allotedTime * this.thisMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "NEXT_MONTH") {
          return allotedTime * this.nextMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "AFTER_TOADY") {
          return allotedTime * this.allotedDaysCount
        }
        if (pattern === "SOMEDAY") {
          return allotedTime * this.allotedDaysCount
        }
        if (pattern === "COMPLETED") {
          return allotedTime * this.allotedDaysCount
        }
        return 0
      }
    },
    /**
     * ユーザの期間の残り稼働日数を集計する
     */
    countRemainingWorkingDays() {
      const user = useUserStore().entity ?? null
      // 週の労働日の配列
      const workingWeekIndexes = user?.workingWeekDayIndexes ?? [0, 1, 2, 3, 4]
      // 週の労働日数
      const workingDayCount = workingWeekIndexes.length
      return (pattern: FilterPattern): number => {
        if (pattern === "AFTER_TOADY") return 0
        if (pattern === "TODAY") return 0
        if (pattern === "TOMORROW") return 0
        if (pattern === "THIS_WEEK") {
          return this.thisWeekWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "NEXT_WEEK") {
          return this.nextWeekWorkingDaysCountAfterToday(workingDayCount)
        }
        if (pattern === "THIS_MONTH") {
          return this.thisMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "NEXT_MONTH") {
          return this.nextMonthWorkingDaysCountAfterToday(workingWeekIndexes)
        }
        if (pattern === "SOMEDAY") return 0
        if (pattern === "COMPLETED") return 0
        return 0
      }
    },
    /**
     * タスク総量（H or D)
     */
    calcTotalTime() {
      const user = useUserStore()
      const time = user.workingMinutesPerDay
      return (pattern: FilterPattern, unit: "h" | "d"): string => {
        const remainingTime = this.calcTotalRemainingWorkingTime(pattern)
        if (unit === "h") {
          const val = Math.ceil(((remainingTime / 60) * 10) / 10)
          return val.toFixed(0)
        }
        if (unit === "d") {
          const val = Math.ceil(((remainingTime / time) * 10) / 10)
          return val.toFixed(0)
        }
        return remainingTime.toFixed(0)
      }
    },
    /**
     * 稼働率の算出
     */
    calcOccupancyRate() {
      const user = useUserStore()
      const time = user.workingMinutesPerDay
      const elapsedWorkingTime = 0
      return (pattern: FilterPattern, unit: "h" | "d"): number => {
        const remainingTime = this.calcTotalWorkingTime(pattern)
        const count = this.countRemainingWorkingDays(pattern)
        if (unit === "h") {
          // 今日の残り稼働時間
          const workingMinutes = time - elapsedWorkingTime
          const val = Math.floor((remainingTime / workingMinutes) * 100) / 100
          return Math.floor(val * 100)
        }
        if (unit === "d") {
          const totalHours = count * time
          if (count <= 0 && remainingTime !== 0) {
            const val = Math.floor((remainingTime / time) * 100)
            return 100 + val
          }
          if (totalHours === 0) {
            return 0
          }
          const val = Math.floor((remainingTime / totalHours) * 100)
          return val // %に変換
        }
        return 0
      }
    },
    /**
     * 選択されているタグを含む全てのタスクを返す
     * 開始日を含んでいるタスクのみ
     */
    selectedTagTasks(state) {
      const tagId = state.selectedTag?.id.value
      if (!tagId) {
        return []
      }
      console.log("selectedTagTasks!!!!!", tagId)
      const allTasks = this.tasks as EventEntity[]
      const u = allTasks.filter((task) => {
        return (
          // task.date !== null &&
          // task.date?.value >= DateTime.now().toDate() &&
          task.tagIds.map((taskTagId) => taskTagId.value).includes(tagId)
        )
      })
      console.log("u", u)
      return u
    },
    /**
     * 選択されているタグを含む全てのタスクを返す
     * 開始日を含んでいないタスクのみ
     */
    selectedNoDateTagTasks(state) {
      const tagId = state.selectedTag?.id.value
      if (!tagId) {
        return []
      }
      const allTasks = state.noDateTasks
      return allTasks.filter((task) => {
        return task.tagIds.map((taskTagId) => taskTagId.value).includes(tagId)
      })
    },
    selectedTagTaskDays(state): Date[] {
      const tagId = state.selectedTag?.id.value
      if (!tagId) {
        return []
      }
      const allTasks = [...state.allTasks, ...state.noDateTasks]
      const tasks = allTasks
        .filter((task) => {
          return (
            // task.date !== null &&
            // task.date?.value >= DateTime.now().toDate() &&
            task.tagIds.map((taskTagId) => taskTagId.value).includes(tagId)
          )
        })
        .filter((task) => {
          return task.isNotDone
        })
      // 重複を含むタスクの日にちの配列
      const redundantDateTexts = tasks
        .map((task) => {
          return task.date?.toDateText()
        })
        .filter((date): date is string => {
          return typeof date !== "undefined"
        })
      // 重複を削除した配列
      const dateTexts = Array.from(new Set(redundantDateTexts))
      return dateTexts.map((dateText) => {
        return DateTime.fromText(dateText).toDate()
      })
    },
    searchTaskDays(state): Date[] {
      const tasks = state.searchTasks
      // 重複を含むタスクの日にちの配列
      const redundantDateTexts = tasks
        .map((task) => {
          return task.date?.toDateText()
        })
        .filter((date): date is string => {
          return typeof date !== "undefined"
        })
      // 重複を削除した配列
      const dateTexts = Array.from(new Set(redundantDateTexts))
      return dateTexts.map((dateText) => {
        return DateTime.fromText(dateText).toDate()
      })
    },
    /**
     * 任意のタグを含む今日以降の全てのタスクを返す
     */
    tagTasks(state) {
      return (tagId: Id) => {
        const allTasks = [...state.allTasks, ...state.noDateTasks]
        return allTasks.filter((task) => {
          return task.tagIds.map((taskTagId) => taskTagId.value).includes(tagId.value)
        })
      }
    },
  },
  actions: {
    //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 今月の初日以降の全てのタスク・休日を取得する(subscribe)
    // ※開始日が設定されていないタスクは取得されない
    //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    async readAllTasks(value: { userId: string }): Promise<void> {
      // 既にサブスク定義済みの場合、実行しない
      if (this._unsubscribeAllTasks !== null) return

      // 今月の最初の日
      const fromDay = DateTime.now().toMonthStart().toDate()
      const ref = collection(getFirestore(), "users", value.userId, "tasks").withConverter(
        TaskConverter,
      )
      const q = query(ref, where("date", ">=", fromDay), orderBy("date"))
      // eslint-disable-next-line
      const self = this
      const unsubscribe = onSnapshot(q, (querySnap) => {
        const events = querySnap.docs.map((docSnap) => docSnap.data())
        const tasks = events.filter((event) => event.type.isTask)
        const holidays = events.filter((event) => event.type.isHoliday)
        self.allTasks = tasks
        self.allHolidays = holidays
      })
      this._unsubscribeAllTasks = unsubscribe
    },
    //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // サブスクの停止
    //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    async unsubscribeAllTasks(): Promise<void> {
      if (this._unsubscribeAllTasks === null) {
        return
      }
      this._unsubscribeAllTasks()
      this._unsubscribeAllTasks = null
    },
    async readNoDateTasks(value: { userId: string }): Promise<void> {
      // 既にサブスクが存在する
      if (this._unsubscribeNoDateTasks !== null) {
        return
      }
      const ref = collection(getFirestore(), "users", value.userId, "tasks").withConverter(
        TaskConverter,
      )
      const q = query(ref, where("date", "==", null))
      // eslint-disable-next-line
      const self = this
      const unsubscribe = onSnapshot(q, (querySnap) => {
        const events = querySnap.docs.map((docSnap) => docSnap.data())
        const tasks = events.filter((event) => event.type.isTask)
        self.noDateTasks = tasks
      })
      this._unsubscribeNoDateTasks = unsubscribe
      return
    },
    setSelectedTask(task: EventEntity | null) {
      this.selectedTask = task
    },
    clearSelectedTask() {
      this.selectedTask = null
    },
    clearSelectedTag() {
      this.selectedTag = null
    },
    setSelectedTaskRange(range: string) {
      this.selectedTaskRange = range as any
    },
    setSelectedTag(tag: TagEntity) {
      this.selectedTag = tag
    },
    setTaskDisplayMode(displayMode: string) {
      this.taskDisplayMode = displayMode as any
    },
    async unsubscribeNoDateTasks(): Promise<void> {
      if (this._unsubscribeNoDateTasks === null) {
        return
      }
      this._unsubscribeNoDateTasks()
      this._unsubscribeNoDateTasks = null
    },
    /**
     * イベントを書き込む
     */
    async writeEvent(event: EventEntity) {
      try {
        const docRef = doc(
          getFirestore(),
          "users",
          event.userId.value,
          "tasks",
          event.id.value,
        ).withConverter(TaskConverter)
        await setDoc(docRef, event)
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * イベントを削除する
     */
    async removeEvent(event: EventEntity) {
      try {
        const docRef = doc(
          getFirestore(),
          "users",
          event.userId.value,
          "tasks",
          event.id.value,
        ).withConverter(TaskConverter)
        await deleteDoc(docRef)
      } catch (error) {
        console.error(error)
      }
    },
    setSearchTasks(event: EventEntity[]) {
      // console.log('ON SET TASKS', event);
      this.searchTasks = event
    },
    async moveTaskToTomorrow(props: { event: EventEntity }) {
      const nextEvent = props.event.moveToTomorrow()

      const docRef = doc(
        getFirestore(),
        "users",
        nextEvent.userId.value,
        "tasks",
        nextEvent.id.value,
      ).withConverter(TaskConverter)

      await setDoc(docRef, nextEvent, { merge: true })
    },
    async moveTaskToNextWeek(props: { event: EventEntity }) {
      const nextEvent = props.event.moveToNextWeek()

      const docRef = doc(
        getFirestore(),
        "users",
        nextEvent.userId.value,
        "tasks",
        nextEvent.id.value,
      ).withConverter(TaskConverter)

      await setDoc(docRef, nextEvent, { merge: true })
    },
    async moveTaskToNextMonth(props: { event: EventEntity }) {
      const nextEvent = props.event.moveToNextMonth()

      const docRef = doc(
        getFirestore(),
        "users",
        nextEvent.userId.value,
        "tasks",
        nextEvent.id.value,
      ).withConverter(TaskConverter)

      await setDoc(docRef, nextEvent, { merge: true })
    },
    async removeDate(props: { event: EventEntity }) {
      const nextEvent = props.event.removeDate()

      const docRef = doc(
        getFirestore(),
        "users",
        nextEvent.userId.value,
        "tasks",
        nextEvent.id.value,
      ).withConverter(TaskConverter)

      await setDoc(docRef, nextEvent, { merge: true })
    },
    /**
     * 死んだタスクを復活させる
     * 以下の条件に一致するタスクを取得する
     * - Dateがnullでない
     * - 日付が前のもの
     * - 状態がDONEではない
     * タスクの日付を今日の日付にする
     */
    async handleDeadTasks(value: { userId: string }) {
      const ref = collection(getFirestore(), "users", value.userId, "tasks").withConverter(
        TaskConverter,
      )

      const q = query(ref, where("status", "!=", "DONE"))

      const tasksSnapQuery = await getDocs(q)

      if (tasksSnapQuery.docs.length === 0) return

      const batch = writeBatch(getFirestore())

      for (const snap of tasksSnapQuery.docs) {
        const date = snap.data().date
        const deadlineDate = snap.data().deadlineDate
        const type = snap.data().type
        if (date === null) continue
        if (type.isHoliday) continue
        console.log("date", date.toDate().getHours())
        console.log("deadlineDate", deadlineDate!.toDate().getHours())
        const newDate = DateTime.now().toDate()
        const newDeaalineDate = DateTime.now().toDate()
        if (deadlineDate !== null) {
          newDate.setHours(date.toDate().getHours())
          newDeaalineDate.setHours(deadlineDate.toDate().getHours())
        }
        if (newDate <= date.toDate()) continue
        batch.update(snap.ref, {
          date: newDate,
          deadlineDate: newDeaalineDate,
          deathCount: increment(1),
        } as any)
      }

      await batch.commit()

      return
    },
  },
})
