import {
  getFirestore,
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore"
import { defineStore } from "pinia"
import EventEntity from "@/domain/entities/event.entity"
import DateTime from "@/domain/valueObjects/dateTime"
import TaskConverter from "@/infrastructure/converters/task.converter"

type State = {
  _unsubscribeHasDateTasks: Unsubscribe | null
  _unsubscribeHolidays: Unsubscribe | null
  hasDateTasks: EventEntity[] // 開始日がnullでないタスク
  holidays: EventEntity[] // 休日
}

export const useTaskStore = defineStore("TaskStore", {
  state: (): State => {
    return {
      _unsubscribeHasDateTasks: null,
      _unsubscribeHolidays: null,
      hasDateTasks: [],
      holidays: [],
    }
  },
  getters: {},
  actions: {
    /**
     * 開始日がNULLでないタスクの購読
     */
    async subscribeHasDateTasks(value: { userId: string }): Promise<void> {
      // 既にサブスク定義済みの場合、実行しない
      if (this._unsubscribeHasDateTasks !== null) return

      // 今月の最初の日
      const thisMonthStartDay = DateTime.now().toMonthStart().toDate()
      const ref = collection(getFirestore(), "users", value.userId, "tasks").withConverter(
        TaskConverter,
      )
      const q = query(ref, where("date", ">=", thisMonthStartDay), orderBy("date"))
      // eslint-disable-next-line
      const self = this
      const unsubscribe = onSnapshot(q, (querySnap) => {
        const events = querySnap.docs.map((docSnap) => docSnap.data())
        const tasks = events.filter((event) => event.type.isTask)
        self.hasDateTasks = tasks
      })
      this._unsubscribeHasDateTasks = unsubscribe
    },
    /**
     * 登録されている休日の購読
     */
    async subscribeHolidays(value: { userId: string }): Promise<void> {
      // 既にサブスク定義済みの場合、実行しない
      if (this._unsubscribeHolidays !== null) return

      // 今月の最初の日
      const thisMonthStartDay = DateTime.now().toMonthStart().toDate()
      const ref = collection(getFirestore(), "users", value.userId, "tasks").withConverter(
        TaskConverter,
      )
      const q = query(ref, where("date", ">=", thisMonthStartDay), orderBy("date"))
      // eslint-disable-next-line
      const self = this
      const unsubscribe = onSnapshot(q, (querySnap) => {
        const events = querySnap.docs.map((docSnap) => docSnap.data())
        const holidays = events.filter((event) => event.type.isHoliday)
        self.holidays = holidays
      })
      this._unsubscribeHolidays = unsubscribe
    },
  },
})
