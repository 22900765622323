
import { defineComponent } from "vue"

export default defineComponent({
  name: "TasksStatusDisplay",
  props: {
    untreatedTasksCount: {
      type: Number,
    },
    isDoneTasksCount: {
      type: Number,
    },
    totalRemainingWorkingTime: {
      type: Number,
    },
    totalWorkedTime: {
      type: Number,
    },
  },
  setup(props) {
    // const hasTask = computed(() => props.untreatedTasksCount || 0 && props.isDoneTasksCount !== 0);
    return {
      // hasTask,
    }
  },
})
