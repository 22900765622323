
import { defineComponent, computed } from "vue"
import { useUserStore } from "@/stores/user"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "TasksStatusDisplay",
  props: {
    isDoneTasksCount: {
      type: Number,
    },
    unit: {
      type: String,
    },
    workedHours: {
      type: Number,
    },
    // 稼働率
    occupancyRate: {
      type: Number,
    },
  },
  setup(props) {
    const addClass = computed(() => {
      if (typeof props.occupancyRate !== "undefined") return `p${props.occupancyRate}`
      return ""
    })
    const userStore = useUserStore()
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      addClass,
      localizationText,
    }
  },
})
