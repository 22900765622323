import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "editemail" }
const _hoisted_2 = { class: "editProfile" }
const _hoisted_3 = { class: "registerList" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "submitList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email1) = $event)),
            placeholder: _ctx.localizationText('new_email_address')
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.email1]
          ])
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email2) = $event)),
            placeholder: _ctx.localizationText('new_email_address_confirm')
          }, null, 8, _hoisted_5), [
            [_vModelText, _ctx.email2]
          ])
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_6, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "reset",
          class: "cancel",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.localizationText("cancel")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "submit",
          class: "submit",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeEmail && _ctx.changeEmail(...args)))
        }, _toDisplayString(_ctx.localizationText("change")), 1)
      ])
    ])
  ]))
}