
import { useUserStore } from "@/stores/user"
import { useProfileModalStore } from "@/stores/profileModal"
import { defineComponent, ref } from "vue"
import { z } from "zod"
import { useLocalizationStore } from "@/stores/localization"

export default defineComponent({
  name: "PasswordChange",
  emits: ["click"],
  setup() {
    const modalStore = useProfileModalStore()
    const closeModal = () => modalStore.close()

    const userStore = useUserStore()

    const email1 = ref<string>("")

    const email2 = ref<string>("")

    // https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const changeEmail = async () => {
      if (email1.value !== email2.value) {
        alert(localizationText("email_not_match"))
        return
      }
      try {
        const zEmail = z.string().email({ message: localizationText("email_invalid") })
        const result = zEmail.safeParse(email1.value)
        if (!result.success) {
          alert(localizationText("email_invalid"))
          return
        }
        await userStore.updateEmail({ email: email1.value })
        alert(localizationText("email_address_changed"))
      } catch (error) {
        alert(localizationText("failed_email_address_changed"))
        return
      }
    }
    const localizationStore = useLocalizationStore()
    const getLocalization = localizationStore.getLocalization
    const localizationText = (key: string) => {
      const language = userStore.entity?.language
      if (language === undefined) {
        return ""
      }
      return getLocalization(language, key)
    }
    return {
      closeModal,
      email1,
      email2,
      changeEmail,
      localizationText,
    }
  },
})
